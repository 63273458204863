//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../helpers/HttpHelper';
import environments from '../../environments/environment';
const http = new httpHelper();

export const FooterActions = {
    FooterLabels: footerLabels,
    GetCookieNPolicies: getCookieNPolicies,
    SaveCandisdateProfile: saveCandisdateProfile
}

function footerLabels(payload) {
    return http.CMSpost(environments.FOOTER_CMS, payload);
}

function getCookieNPolicies(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload)
}

function saveCandisdateProfile(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_PROFILE.replace('{id}', id);
    return http.put(relpaceId, payload);
}
