//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { React, useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import "./IviewStartPage.css";
import { Context } from "../../../common/context/Context";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import { iViewActions } from ".././iViewActions";
import { DashboardActions } from "../../dashBoard/dashboardActions";
import { TrackActions } from "../../../common/utility/LogAction";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function IviewInterviewStartPage() {
  const context = useContext(Context);
  const appInsights = useAppInsightsContext();
  const [apiContent, setApiContent] = useState();
  let { id, externalId } = useParams();
  const [label, setLabels] = useState([]);
  const [pageLoader, setPageLoader] = useState(false)
  const [stat, setStat] = useState();
  const navigate = useNavigate();
  const urlNav = window.location.href;
  const envNav = urlNav.split("#");

  const iviewWelcomePageContent = async () => {
    let payload = {
      PageName: "OnDemandVideoInterview"
    };
    setPageLoader(true);

    let _30SecondCommercialPayload = {
      PageName: "30 Second Commercial"
    }

    let _30SecondCommercial = await DashboardActions.Get_30_secondCommercialCMS(_30SecondCommercialPayload)

    await iViewActions.GetIviewContent(payload).then(
      (res) => {
        if (res.status === 200) {
          let arrResourcesPages = res?.data?.resourcePages || []
          setLabels(res?.data.components);
          let collectionData
          arrResourcesPages.forEach((eleResourecPage) => {
            eleResourecPage.resourcePages.forEach((eleChild) => {
              if (eleChild.id == id) {
                collectionData = eleChild
              }
            })
          })
          if (collectionData === undefined) {
            collectionData = _30SecondCommercial?.data
          }
          if (Object.keys(collectionData).length > 0) {
            let objData = {}
            objData['Title'] = collectionData?.browserTitle || collectionData?.BrowserTitle || ""
            objData['description'] = collectionData?.description || collectionData?.Description || ""
            let arrFocusArea = [];
            collectionData?.focusArea?.map((focus) => {
              arrFocusArea.push(focus.value);
            });
            if (arrFocusArea.length === 0) {
              collectionData?.FocusArea?.map((focus) => {
                arrFocusArea.push(focus.Value);
              });
            }
            objData["FocusArea"] = arrFocusArea
            objData["assessmentType"] = collectionData?.assessmentType?.value
            objData["resourceType"] = collectionData?.resourceType?.value || collectionData?.ResourceType?.value
            objData["subtitle"] = collectionData?.subTitle;
            setApiContent(objData);
            setStat(collectionData?.status)
            ActivityAction.SaveActivity(`${id}/${externalId}`, "Tool", objData?.Title)
            if (externalId == "11165") {
              TrackActions.EventTrack(appInsights, "ViewCard", { "Entity Id": `${id}/${externalId}`, "Entity Name": objData?.Title, "CardType": "Tool" })
              TrackActions.PageAction("ViewCard", { "Entity Id": `${id}/${externalId}`, "Entity Name": objData?.Title, "CardType": "Tool" })
            }
          }
          else {
            setApiContent({});
          }
          setPageLoader(false);
        } else {
          setPageLoader(false);
        }
      }, err => {
        console.log(err)
      });
  }


  useEffect(async () => {
    window.scrollTo(0, 0)
    iviewWelcomePageContent();
  }, [context.language]);

  const fetchLabel = (key) => {
    let labelData = label?.commonComponent?.Labels;
    let labels = "";
    if (labelData) {
      labelData?.map((x) => {
        if (x.Key === key) {
          labels = x.Phrase;
        }
      });
    }
    return labels;
  }

  if (pageLoader) {
    return (
      <div>
        <Spinner spinnerTimer={pageLoader} screenLoader={true} />
      </div>
    );
  }

  return (
    <>
      <Container className="pt-4 pb-4 iView-Start">
        <Row>
          <Col md={12} lg={10} xs={12} className="text-end">
            <Cirrus.Button
              onClick={(e) => {
                window.open(`${envNav[0]}#/iview-welcome`, "_self");
              }}
            >
              {fetchLabel("CommonComponent.CommonExitButton")}
            </Cirrus.Button>
          </Col>
        </Row>
      </Container>
      <div className="cardStart">
        <div className="headerStart">{apiContent?.Title}</div>
        <div className="topicStart mb-lg-5 mb-3">
          <span className="topicBorder">{apiContent?.FocusArea}</span>
          <span className="spanElements">{apiContent?.assessmentType}</span>
          <span className="spanElements">{apiContent?.resourceType}</span>
        </div>
        <div className="body">
          <div className="iview-interview-start-description mb-2">{apiContent?.subtitle}</div>
          <div className="bodyContent mb-5">
            <Cirrus.RichText content={apiContent?.description} className="bodyContent" />

          </div>
          <div className="buttionContainer">
            <Cirrus.Button
              size="Large"
              onClick={() => {
                // ***below is the App Insight code please retain it incase making any changes**//
                TrackActions.EventTrack(appInsights, "StartiviewAssessment", { "Iview Assessment Name": apiContent?.Title })
                TrackActions.PageAction("StartiviewAssessment", { "Iview Assessment Name": apiContent?.Title })
                //**App Insight code ends there**//
                let countryCode = JSON.parse(sessionStorage.getItem("setProgramDetails"))['programLanguageCode']
                const url = window.location.href;
                const env = url.split("#");
                const payload = {
                  LearningResourceId: id,
                  LearningResourceStatus: "notstarted",
                  CallbackURL: `${env[0]
                    }#/iview-interview-done/${externalId}/${id}/${sessionStorage.getItem(
                      "AccessToken"
                    )}`,
                  Languagecode: countryCode,
                };
                iViewActions
                  .GetIviewInterview(payload, externalId)
                  .then((res) => {
                    window.open(res?.data?.interviewUrl, "_self");
                  });
              }}
              className="button"
            >
              {fetchLabel("CommonComponent.CommonStartButton")}
            </Cirrus.Button>
            {/* <Cirrus.Button onClick={()=>navigate('/iview-interview-done/AE7C2DF4-5011-4720-B9B0-634C89782508/11126/12345')}>Done</Cirrus.Button> */}
          </div>
        </div>
      </div>
    </>
    // </Cirrus.Page>
  );
}

export default IviewInterviewStartPage;
