//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();

export const MyProfileActions = {
    GetcandidateProfile: GetcandidateProfile,
    SavecandidateProfile: SavecandidateProfile,
    GetcandidateProgramDetails: GetcandidateProgramDetails,
    GetCMSMyProfileheaderLabels: GetCMSMyProfileheaderLabels,
    GetcandidateContactPreference: GetcandidateContactPreference,
    SavecandidateContactPreference: SavecandidateContactPreference,
    GetCandidateConsentDetails: GetCandidateConsentDetails,
    TKcityList: TKcityList,
    GetVideoURL:GetVideoURL
}

function GetcandidateProfile(id) {
    let candidateProfielReq = environments.GET_CANDIDATE_PROFILE.replace('{id}', id);
    return http.get(candidateProfielReq);
}

function GetcandidateProgramDetails(email) {
    let relpaceEmail = environments.GET_CANDIDATE_PROGRAM_DETAILS.replace('{emailid}', email);
    return http.get(relpaceEmail);
}

function GetcandidateContactPreference(id) {
    let relpaceEmail = environments.GET_CANDIDATE_CONTACT_PREFERENCES.replace('{id}', id);
    return http.get(relpaceEmail);
}

function SavecandidateProfile(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_PROFILE.replace('{id}', id);
    return http.put(relpaceId, payload);
}

function GetCMSMyProfileheaderLabels(payload) {
    return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function GetVideoURL(payload) {
    return http.CMSpost(environments.GET_PROFILE_VIDEO, payload);
}

function SavecandidateContactPreference(id, payload) {
    let relpaceId = environments.SAVE_CANDIDATE_CONTACT_PREFERENCES.replace('{id}', id);
    return http.post(relpaceId, payload);
}

function GetCandidateConsentDetails(id)
{
    let candidateConsentReq = environments.CANDIDATE_CONSENT.replace('{candidateId}', id);
    return http.get(candidateConsentReq);
}

function TKcityList(payload) {
    return http.textCernelPost(environments.LOCATION_AUTOCOMPLETE, payload);
}
