//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

module.exports = {
  CANDIDATE_CONSENT: "candidates/{candidateId}/consent",
  // 'GET_CANDIDATE_PREFERENCES': 'candidates/{id}/candidatepreferences',
  // 'SAVE_CANDIDATE_PREFERENCES': 'candidates/{id}/candidatepreferences',
  GET_CANDIDATE_PREFERENCES: "candidates/{id}/preferences",
  SAVE_CANDIDATE_PREFERENCES: "candidates/{id}/preferences",
  UPDATE_PREFERENCES_COMPLETED: "candidates/{id}/preference",
  GET_CANDIDATE_PROFILE: "candidates/{id}/profile",
  GET_CANDIDATE_PROGRAM_DETAILS: "candidates/{emailid}/programdetail",
  SAVE_CANDIDATE_PROFILE: "candidates/{id}/profile",
  SEARCH_REFERENCE_CODE: "registrations/search",
  UPDATE_CANDIDATE_PREFERENCE_BY_ID: "candidates/{id}/preference",
  CANDIDATE_APPLIED_JOB: "jobs/{candidateId}/appliedjob",
  CANDIDATE_SAVED_JOB: "jobs/{candidateId}/job",
  GET_TEXTKERNEL_JOB: "job/search",
  VALIDATE_REGISTRATION_CODE: "registration/validate",
  GET_HEADER_VALUE: "HeaderService/GetAllHeader",
  SAVE_SEARCH_NAME: "jobs/searchjobs",
  GET_ON_BOADING_CONTENT: "OnboardingService/GetOnboadingContent",
  GET_TEXTKERNEL_JOB_MATCH: "job/match",
  GET_JOBSEARCH_SERVICE: "JobSearchService/GetJobSearchContent",
  GET_JOB_DETAILS_CONTENT: "JobDetailsService/GetJobDetailsContent",
  GET_MATCHES_COUNT: "job/query_extract",
  GET_MYPROFILE_LABELS: "PageService/GetPageContent",
  GET_BOOKMARK_LABELS: "PageService/GetPageContent",
  GET_ABOUTME_LABELS: "PageService/GetPageContent",
  GET_EVENTS_LABELS: "PageService/GetPageContent",
  GET_PROFILE_VIDEO: "SearchService/GetAllProgramClassVideos",

  GET_DASHBOARD_WIDGET: "PageService/GetPageContent",

  GET_MASTER_DATA_CMS: "MasterDataService/GetMasterData",
  GET_ON_BOARDING_STATUS: "candidates/{candidateId}/onboardingstatus",
  LOCATION_AUTOCOMPLETE: "job/autocomplete",
  GET_CANDIDATE_CONTACT_PREFERENCES: "candidates/{id}/notificationpreferences",
  GET_JOB_SAVED_STATUS: "jobs/{CandidateId}/getjob",
  SAVE_CANDIDATE_CONTACT_PREFERENCES: "candidates/{id}/notificationpreferences",
  GET_SAVED_SEARCH_LIST: "jobs/searchjobs/search",
  DELETE_SAVED_SEARCH_BY_ID: "jobs/searchjobs/{id}",
  UPDATE_SAVED_SEARCH_BY_ID: "jobs/searchjobs/{id}",
  GET_SAVED_SEARCH_DETAILS: "jobs/searchjobs/{id}",
  GET_RESUME_UPLOADED_STATUS: "jobs/resume",
  GET_ALL_DOCUMENTS: "documents/search",
  GET_DOCUMENT: "documents/{id}",
  UPLOAD_DOCUMENTS: "documents",
  DOWNLOAD_DOCUMENT: "documents/{id}/download",
  DELETE_DOCUMENT: "documents/{id}",
  UPDATE_DOCUMENT: "documents/{id}",
  GET_RESUME_MATCH: "jobs/match",
  GET_LAST_SESSION: "candidates/login",
  CANDIDATE_ONBOARDING: "candidates/onboarding",
  PARSE_RESUME: "cv/cv_parse",
  FOOTER_CMS: "FooterService/GetAllFooter",
  CVINDEX: "cv/index",
  CVREVERT: "resumes/{resumeId}/revert",
  UPLOAD_RESUME: "resumes",
  UPDATE_RESUME: "resumes/{id}",
  UPDATE_JOB_TITLE: "/jobs/updatejobtitle",
  DELETE_PROFILE: "/resumes/{id}",
  JOBS_ALERT: "jobs/alert",
  SEARCH_LOG: "jobs/search",
  GET_COUNTRY_LIST: "references/countries",
  GET_MY_COACH: "candidates/coach",
  CANDIDATE_JOURNEY_STATUS: "Candidates/{candidateId}/JourneyStatus",
  GET_CALENDER_EVENTS: "calendar/search",
  CoachingSessions: "CoachingEvents/search",
  DeleteCoachMeeting: "CoachingEvents/{meetingid}",
  ASSESSMENTS_CMS: "ResourceService/GetResourceContent",
  IVIEW_ON_DEMAND_VIDEO_INTERVIEW: "learningresources/iview",
  IVIEW_GET_INTERVIEW_URL: "learningresources/iview/{id}/requisition",
  IVIEW_GET_REVIEW_URL: "learningresources/iview/{id}/review",
  IVIEW_RESET: "learningresources/iview/{id}/reset",
  ASSESSMENT_CARD_DATA: "learningresources",
  GET_ALL_ASSESSMENTS: "learningresources/assessments",
  GET_ASSESSMENT_BY_ASSESSMENT_ID: "learningresources/assessments/{id}",
  GET_ASSESSMENT_RESPONSES_BY_ASSESSMENT_ID:
    "learningresources/assessments/{id}/responses",
  GET_ASSESSMENT_AND_RESPONSES_BY_ASSESSMENT_ID:
    "learningresources/assessments/responses/{id}",
  GET_ASSESSMENT_QUESTIONS_BY_QUESTION_ID:
    "learningresources/assessments/{id}/question/{questionid}",
  SAVE_ASSESSMENTS: "learningresources/assessments",
  RETAKE_ASSESSMENT_BY_ID: "learningresources/assessments/{id}/retake",
  SAVE_ASSESSMENT_CARD: "learningresources/{resourcetype}/{id}",
  ConfirmCoachMeeting: "coachingEvents/{id}/status",
  CoachingMeetingUpdate: "CoachingEvents",
  GET_IVEIW_CSM: "SearchService/GetLearningResources",
  SAVE_IVIEW_ASSESMENT_CARD: "learningresources/iview/{id}",
  GETMyCOACHMATCHING: "nocoach/MatchingtoCoach",
  SAVE_IVIEW_COLLECTION: "learningresources/iviewcollection",
  SAVE_NOCOACH_MEETING: "/nocoach/requestmeeting",
  GET_BOOKMARKED_PAGE: "learningresources/bookmarkedpages",
  GET_BOOKMARK_CMS: "SearchService/GetAllLearningResources",
  SAVE_NOCOACH_RESCHUDLE_MEETING:
    "/nocoach/reschedulemeeting/{calendarInviteId}",
  LOOKING_AT_OPTIONS_CAREER_PATH: "assessments/lookingoptions/{id}/careerpath",
  LOOKING_AT_OPTIONS_CAREER_PATH_DELETE:
    "assessments/lookingoptions/{id}/{careerpathid}/careerpath",
  LOOKING_AT_OPTIONS_CAREER_FACTORS: "assessments/lookingoptions/{id}/factors",
  LOOKING_AT_OPTIONS_CAREER_FACTORS_SAVE_UPDATE:
    "assessments/lookingoptions/factor/{id}/reponses",
  LOOKING_AT_OPTIONS_CAREER_WITH_FACTORS:
    "assessments/lookingoptions/{id}/careerpath/factors",
  LOOKING_AT_OPTIONS_HISTORY:
    "assessments/lookingoptions/{id}/previouscareeroptions",
  LOOKING_AT_OPTIONS_RETAKE: "assessments/lookingoptions/{id}/retake",

  // Strengths Analysis
  POST_STRENGTHS_ANALYSIS_ASSESSMENT_ENTRY_IN_DB:
    "assessments/strengthanalysis/{assessmentid}",
  PUT_STRENGTHS_ANALYSIS_LIKE_AND_SAVE:
    "assessments/strengthanalysis/{assessmentId}/saveandlike",
  GET_STRENGTHS_ANALYSIS_ASSESSMENT_STATUS_BY_ID:
    "assessments/strengthanalysis/{assessmentId}",
  DELETE_STRENGTHS_ANALYSIS_RETAKE_ASSESSMENT:
    "assessments/strengthanalysis/subassessment/{id}/retake", // line 6
  PUT_STRENGTHS_ANALYSIS_UPDATE_ASSESSMENT_STATUS:
    "assessments/strengthanalysis/{assessmentId}", // line 7
  PUT_STRENGTHS_ANALYSIS_IDENTIFY_YOUR_INTEREST:
    "assessments/strengthanalysis/{assessmentId}/category", // line 8
  GET_STRENGTHS_ANALYSIS_IDENTIFY_YOUR_INTEREST:
    "assessments/strengthanalysis/{assessmentid}/category", // line 9
  POST_STRENGTHS_ANALYSIS_REFINING_YOUR_INTEREST_SKILLS:
    "assessments/strengthanalysis/{assessmentId}/response", //11
  GET_STRENGTHS_ANALYSIS_RESULT:
    "assessments/strengthanalysis/{assessmentid}/response", //13
  GET_STRENGTHS_ANALYSIS_SKILLS_MATRIX:
    "assessments/strengthanalysis/{assessmentid}/response", //14
  GET_COACH_MEETING_CRONOFY_AVAILABLE_SLOTS: "cronofy/coach/availability",
  GET_EVENTS: "events/allevents",
  GET_ONDEMAND_EVENTS: "SearchService/GetAllLearningResources",
  GET_EVENT_DETAILS: "events/{id}/session",
  ENROLL_SESSION: "events/enroll",
  UNENROLL_SESSION: "events/unenroll",
  UPDATE_EVENT: 'events/External/{id}',
  GET_DOCEBO_LINK: 'events/docebourl ',
  MASTER_CMS_DATA: "ReferenceDataService/GetReferenceData",
  SAVE_EVENT_CALENDER:"doceboevents", 
  GET_APPLIED_JOBS: "assessments/evaluatejob/appliedjobs",
  POST_SAVE_UPDATE_JOB_OFFER: 'assessments/evaluatejob/{assessmentId}/joboffer',
  EVALUATE_JOB_RETAKE: "assessments/evaluatejob/{assessmentId}/retake",
  GET_PROGRAM_VIDEO: "videos",
  UPDATE_PROGRAM_VIDEO: "videos",
  SHARE_RESULT_WITH_COACH: "candidates/assessmentResult",
  JOBROLE: "assessments/prepareinterview/{assessmentId}/jobrole",
  RETAKE_PREPARE_INTERVIEW: "assessments/prepareinterview/{assessmentId}/retake",
  GET_NOTIFICATIONS:"platform/notifications?offset={off}&limit={lim}&locale={locale}",
  READ_NOTIFICATIONS: 'platform/notifications',
  NOTIFY_JOBMATCHES: "notify/jobmatches",
  GET_SYSTEM_MESSAGES:'platform/notifications/systemmessages?locale={locale}',
  NEGOTIATE_URL: '?userid={id}',
  GENERATE_PERSONALITY_ASSESSMENT_URL: 'learningresources/personalityassessmentcarddetails',
  GET_RESUME_BUILDER_URL: 'learningresources/resumebuilder',
  SAVE_RESUME_BUILDER_URL: 'learningresources/resumebuilder/uploadresume',
  CreateCandidateActivities:'CandidateActivities/CreateActivity',
  CandidateActivities:'CandidateActivities?{payload}',
  PERSONALITY_ASSESSMENT_DOCUMENT_DOWNLOAD: 'learningresources/personalityassessmentresultfile',
  TIMEZONE_MASTER_DATA:'references/data/{locale}/{timezone}',
  READ_SYSTEM_MESSAGES: 'platform/notifications/systemmessages',
  HIDING_CALENDER_WIDGET:'calendarmenu',
  ONBOARDING_AUTOCOMPLETE_QUESTIONS:'references/industry/search/{key}/{locale}',
  GET_ONBOARDING_AUTOCOMPLETE_QUESTIONS_LIST:'references/data/search',
  GET_RESUME_UPLOADED: 'resumes/resumebuilder',
  GET_Featuredetails:'learningresources/featuredetails',
  GET_EXTERNAL_TOOLS_REDIRECT_URL: 'learningresources/tool/{toolId}/redirecturl',
  GET_FEATURES_CONFIG: 'candidates/featuresconfig',
  GET_CLIENT_SPECIFIC_VIDEO: "learningresources/programvideo",
  GET_PROGRAM_LANGUAGE: "candidates/{registrationcode}/programlanguage",
  MARK_ATTENDANCE: "events/updateEventAttendance",
};
