//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useEffect } from "react";
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./AuthService";
import * as Cirrus from "manpowergroup-cirrus";
import Header from "../../pages/header/simpleHeader";
import HeaderWithNavigation from "../../pages/header/headerWithNavigation";
import { Context } from "../context/Context";
import Footer from '../components/footer';
import landingBackground from "manpowergroup-cirrus/assets/images/background.png";

const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
];

const PrivateRoutes = () => {
    const { instance, accounts } = useMsal();
    const location = useLocation();
    const context = useContext(Context);
    let pageBackground = "secondary";
    let pageBackgroundShape;
    let pageImageSource;
    let pageHeaderMode;
    let whichHeaderIs = false;
    let pageClassName;
    let accessToken = window.sessionStorage.getItem('AccessToken');

    if (location.key === "default") {
        if(!location.pathname.includes("iview-interview-done")) {
            window.sessionStorage.setItem("RedirectURL", JSON.stringify(location.pathname));
        }
    }
    // if ((window.sessionStorage.getItem("setIdTokenClaims") != null)) {
    //     let accessToken = window.localStorage.getItem('AccessToken');
    //     // console.log('JWT time-', jwt_decode(accessToken).exp)
    //     // console.log('LOCAL time-', Date.now() / 1000)
    //     if (jwt_decode(accessToken).exp < Date.now() / 1000) {
    //         // instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    //         sessionStorage.clear();
    //         sessionStorage.clear();
    //         instance.loginRedirect(loginRequest);
    //     }
    // }
    let auth = {
        'token': sessionStorage.getItem("setIdTokenClaims") ? true : false
    }
    if ((window.sessionStorage.getItem("onBoardingStatus") != null)) {
        let refOnBoardingStatus = JSON.parse(window.sessionStorage.getItem("onBoardingStatus"));
        if (refOnBoardingStatus.isRegistrationCompleted) {
            whichHeaderIs = true;
            sessionStorage.removeItem("RedirectURL");
            if (location?.pathname === "/discover/values-and-drivers/assessment" || location?.pathname === "/discover/values-and-drivers/review" || location?.pathname === "/discover/values-and-drivers/result" || location?.pathname === "/discover/reviewing-accomplishments/review" || location?.pathname === "/iview-interview-start/:id/:externalId") {
                pageBackground = "secondary";
                pageBackgroundShape = "singleDesktop";
            }
        } else {
            if (location?.pathname === "/signup-welcome") {
                pageBackground = "primary";
                pageBackgroundShape = "singleDesktop";
                pageHeaderMode = "minimal";
            } else if (location?.pathname === "/signup-confirm-details") {
                // pageImageSource= landingBackground;
                pageImageSource = JSON.parse(window.sessionStorage.getItem('setCmsOnBoadingContent'))?.Pages?.find(filt => filt.Key === "SignUpConfirmDetails").LargeImage;
                pageBackground = "secondary";
                pageBackgroundShape = "singleDesktop";
                pageHeaderMode = "back";
            } else if (location?.pathname === "/welcome-on-boarding") {
                pageBackground = "secondary";
                pageBackgroundShape = "singleDesktop";
                pageHeaderMode = "minimal";
                pageClassName = 'welcome-on-board_msg'
            } else if (location?.pathname === "/questions" || location?.pathname === "/recap") {
                pageBackground = "secondary";
                pageBackgroundShape = "singleDesktop";
                pageHeaderMode = "back";
                pageClassName = 'onBoarding-questions'
            } else {
                pageBackground = "secondary";
                pageBackgroundShape = "singleDesktop";
                pageHeaderMode = "back";
            }
        }
    }

    let idelTimer5 = 300000;
    let idelTimer10 = 600000;
    let idelTimer20 = 1200000;
    let idelTimer30 = 1800000;
    let idelTimer;
    useEffect(() => {
        if(accessToken !== null && accessToken !== undefined) {
            Object.values(events).forEach((item) => {
                window.addEventListener(item, () => {
                    resetIdelTimer();
                    idelTimerHandler();
                });
            });
        }
    }, []);
    const resetIdelTimer = () => {
        if (idelTimer) clearTimeout(idelTimer);
    };
    const idelTimerHandler = () => {
        idelTimer = setTimeout(() => {
            resetIdelTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetIdelTimer);
            });
            autoLogoutAction();
        }, idelTimer30);
    };
    const autoLogoutAction = () => {
        sessionStorage.clear();
        localStorage.clear();
        instance.logoutRedirect({ postLogoutRedirectUri: "/" });
    };


    let refreshTokenTimer;
    useEffect(() => {
        if(accessToken !== null && accessToken !== undefined) {
            let startTime = jwt_decode(accessToken).exp - 300;
            let endTime = Date.now() / 1000;
            let diff = startTime - endTime;
            let refreshTime = diff * 1000
            refreshTokenHandler(refreshTime)
            }
    }, []);
    const refreshTokenHandler = (refreshTime) => {
        refreshTokenTimer = setTimeout(() => {
            refreshTokenAction();
        }, refreshTime);
    };
    const refreshTokenAction = () => {
        const accessTokenRequest = {
            scopes: ["openid", "profile"],
            account: accounts[0],
        };
        instance.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
            let startTime = jwt_decode(accessTokenResponse.idToken).exp;
            let endTime = Date.now() / 1000;
            let diff = startTime - endTime;
            let refreshTime = diff * 1000

            window.sessionStorage.setItem("AccessToken", accessTokenResponse.idToken);
            refreshTokenHandler(refreshTime)
        })
    };

    const returnHeader = (whichHeader) => {
        if (whichHeader) {
            return (
                <HeaderWithNavigation />
            )
        } else {
            return (
                <Header mode={pageHeaderMode} className="background-none" />
            )
        }
    }

    return (
        auth.token ? <>
            <Cirrus.Page
                background={pageBackground}
                backgroundShape={pageBackgroundShape}
                imageSource={pageImageSource}
                header={returnHeader(whichHeaderIs)}
                removeFooterPadding
                className={pageClassName ? pageClassName : ''}
            >
                <div className="wrapAllComponents">
                    <Outlet />
                </div>
            </Cirrus.Page>
            <Footer isLoggedIn={auth.token} className="login-footer" />
        </> : <Navigate to="/home" />
    )
}

export default PrivateRoutes
