import Moment from "moment";

export const globalTimeFormat = (time) => {
    let returnFormat = '';
    if(time !== ''){
        let refTimeFormat = JSON.parse(sessionStorage.getItem("setProgramDetails"))["timeFormat"]
        if(refTimeFormat == 12) {
            returnFormat = Moment.utc(time).local().format('hh:mm A');
        } else {
            returnFormat = Moment.utc(time).local().format('HH:mm');
        }

        // returnFormat = Moment(time).format('hh:mm A')
    }
    return returnFormat
}

// export const localTimeWithoutAMPM = (time) => {
//     let returnFormat = '';
//     if(time !== ''){
//         returnFormat = Moment(time).format('hh:mm')
//     }
//     return returnFormat
// }

export const UTCtoLocalTimeWithAMPM = (time) => {
    let returnFormat = '';
    if(time !== ''){
        let refTimeFormat = JSON.parse(sessionStorage.getItem("setProgramDetails"))["timeFormat"]
        if(refTimeFormat == 12) {
            returnFormat = Moment.utc(time).local().format('hh:mm A');
        } else {
            returnFormat = Moment.utc(time).local().format('HH:mm');
        }

        // returnFormat = Moment.utc(time).local().format('hh:mm A');
    }
    return returnFormat
}

// export const UTCtoLocalTimeWithoutAMPM = (time) => {
//     let returnFormat = '';
//     if(time !== ''){
//         returnFormat = Moment.utc(time).local().format('hh:mm');
//     }
//     return returnFormat
// }

export const UTCtoLocalDateTimeOnlyForCalendar = (dateTime) => {
    let returnFormat = '';
    if(dateTime !== ''){
        returnFormat = Moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm'); // Do not change this date format
    }
    return returnFormat
}

export const globalDateFormat = (date) => {
    let returnFormat = '';
    if(date !== ''){
        let refDateFormat = JSON.parse(sessionStorage.getItem("setProgramDetails"))["dateFormat"].toUpperCase()
        returnFormat = Moment(date).format(refDateFormat);

        // returnFormat = Moment(date).format("YYYY-MM-DD");
    }
    return returnFormat
}

