//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from "../../common/helpers/HttpHelper";
import environments from "../../common/environments/environment";
const http = new httpHelper();

export const JobActions = {
  ResumeUploadStatus: resumeUploadStatus,
  GetMatchedjobs: getMatchedjobs,
  GetSerchedjobs: getSerchedjobs,
  GetSavedJobs: getSavedJobs,
  PostSaveJob: postSaveJob,
  AppliedJob: appliedJob,
  GetAppliedJobs: getAppliedJobs,
  GetCountryList: getCountryList,
  LocationAutoComplete: locationAutoComplete,
  JobsAlert: jobsAlert,
  GetCMSJobs: getCMSJobs,
  SaveSearchWithName: saveSearchWithName,
  GetSavedSearchList: getSavedSearchList,
  UpdateSavedSearchListById: updateSavedSearchListById,
  DeleteSavedSearchListById: deleteSavedSearchListById,
  GetParsedResume: parseResume,
  UpdateParseResume: updateParseResume,
  SaveParsedResume: saveParsedResume,
  UploadResume: uploadResume,
  UpdateResume: updateResume,
  UpdateJobTile: updateJobTitle,
  DeleteResume: deleteResume,
  GetJobDetails: getJobDetails,
  GetCMSJobLabels: getCMSJobLabels,
  GetCandidateJourneyStatus: getcandidateJourneyStatus,
  PostCandidateJourneyStatus: postCandidateJourneyStatus,
  PutCandidateJourneyStatus: putCandidateJourneyStatus,
  SavecandidateNotificationPreference: SavecandidateNotificationPreference,
  GetcandidateProfile: GetcandidateProfile,
  RevertCV: revertCV,
};

function getcandidateJourneyStatus(id, payload) {
  let repCanID = environments.CANDIDATE_JOURNEY_STATUS.replace(
    "{candidateId}",
    id
  );
  return http.get(repCanID, payload);
}

function putCandidateJourneyStatus(id, payload) {
  let repCanID = environments.CANDIDATE_JOURNEY_STATUS.replace(
    "{candidateId}",
    id
  );
  return http.put(repCanID, payload);
}

function postCandidateJourneyStatus(id, payload) {
  let repCanID = environments.CANDIDATE_JOURNEY_STATUS.replace(
    "{candidateId}",
    id
  );
  return http.post(repCanID, payload);
}

function getCMSJobLabels(payload) {
  return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function appliedJob(id, payload) {
  let repCanID = environments.CANDIDATE_APPLIED_JOB.replace(
    "{candidateId}",
    id
  );
  return http.jobPost(repCanID, payload);
}

function resumeUploadStatus() {
  let url = environments.GET_RESUME_UPLOADED_STATUS;
  return http.jobGet(url);
}

function getMatchedjobs(payload) {
  return http.jobPost(environments.GET_RESUME_MATCH, payload);
}

function getSerchedjobs(payload) {
  return http.jobPost(environments.SEARCH_LOG, payload);
}

function getSavedJobs(id) {
  let url = environments.GET_JOB_SAVED_STATUS.replace("{CandidateId}", id);
  return http.jobGet(url);
}
function getAppliedJobs() {
  return http.jobGet(environments.GET_APPLIED_JOBS);
}
function postSaveJob(id, payload) {
  let url = environments.CANDIDATE_SAVED_JOB.replace("{candidateId}", id);
  return http.jobPost(url, payload);
}

function getCountryList() {
  return http.getCountryList(environments.GET_COUNTRY_LIST);
}

function locationAutoComplete(payload) {
  return http.textCernelPost(environments.LOCATION_AUTOCOMPLETE, payload);
}

function jobsAlert() {
  return http.jobGet(environments.JOBS_ALERT);
}

function getCMSJobs(payload) {
  return http.CMSpost(environments.GET_MYPROFILE_LABELS, payload);
}

function saveSearchWithName(payload) {
  return http.jobPost(environments.SAVE_SEARCH_NAME, payload);
}

function getSavedSearchList(payload) {
  return http.jobPost(environments.GET_SAVED_SEARCH_LIST, payload);
}

function updateSavedSearchListById(id, payload) {
  let url = environments.UPDATE_SAVED_SEARCH_BY_ID.replace("{id}", id);
  return http.jobPut(url, payload);
}

function deleteSavedSearchListById(id) {
  let url = environments.DELETE_SAVED_SEARCH_BY_ID.replace("{id}", id);
  return http.jobDelete(url);
}

function parseResume(payload) {
  return http.textCernelPost(environments.PARSE_RESUME, payload);
}

function updateParseResume(id, payload) {
  let url = environments.UPDATE_PARSE_RESUME.replace("{id}", id);
  return http.textCernelPut(url, payload);
}

function saveParsedResume(payload) {
  return http.jobPost(environments.CVINDEX, payload);
}

function uploadResume(payload) {
  return http.jobPost(environments.UPLOAD_RESUME, payload);
}

function updateResume(id, payload) {
  let url = environments.UPDATE_RESUME.replace("{id}", id);
  return http.jobPut(url, payload);
}

function updateJobTitle(payload) {
  return http.jobPut(environments.UPDATE_JOB_TITLE, payload);
}

function deleteResume(id) {
  let url = environments.DELETE_PROFILE.replace("{id}", id);
  return http.jobDelete(url);
}

function getJobDetails(payload) {
  return http.textCernelPost(environments.GET_TEXTKERNEL_JOB_MATCH, payload);
}

function SavecandidateNotificationPreference(id, payload) {
  let relpaceId = environments.SAVE_CANDIDATE_CONTACT_PREFERENCES.replace(
    "{id}",
    id
  );
  return http.post(relpaceId, payload);
}

function GetcandidateProfile(id) {
  let candidateProfielReq = environments.GET_CANDIDATE_PROFILE.replace(
    "{id}",
    id
  );
  return http.get(candidateProfielReq);
}

function revertCV(id) {
  return http.jobDelete(environments.CVREVERT.replace("{resumeId}", id));
}
