//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import cardVertical from "manpowergroup-cirrus/assets/images/cardVertical.jpg";
import "./index.css";
import HeaderWithNavigation from "../header/headerWithNavigation";
import Footer from "../../common/components/footer";
import { Context } from "../../common/context/Context";
import { MyProfileActions } from "../myProfile/MyProfileActions";
import Spinner from "../../common/components/spinner-loader/Spinner";
import { DashboardActions } from "./dashboardActions";
import womanOnLaptop from "./womanOnLaptop.png";
import { Row, Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import JobMatchSetup from "./jobMatchSetup";
import MyResume from "./myResume";
import MyDocument from "./myDocument";
import MyDocumentsReturnVisit from "./myDocumentsReturnVisit";
import JobGreetings from "./jobGreetings";
import MyCalandercoachingSessions from "./MyCalandercoachingSessions";
import CoachMeetingRequest from "../coach/coachMeetingRequest";
import MeetingConfirmtionDialog from "../coach/MeetingConfirmationDialog";
import MeetingCancellationDailog from "../coach/MeetingCancellationDailog";
import MeetingRescheduleDialog from "../coach/MeetingRescheduleDailog";
import MeetingScheduleDialog from "../coach/MeetingScheduleDialog";
import { coachActions } from "../coach/coachActions";
import CoachingSessions from "../coach/coachingSessions";
import UpcomigEventDetails from "../coach/UpcomingEventdetails";
import Moment from "moment";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { AssessmentActions } from "../assessments/AssessmentActions";
import { useRef } from "react";
import { CandidateCalenderActions } from "../calendar/CandidateCalenderAction";
import htmlParser from "html-react-parser";

function DashBoard() {
  const context = useContext(Context);
  const navigate = useNavigate();
  const pageOrgin = useParams();
  const [noClientSpecificContent, setNoClientSpecificContent] = useState(false);
  const [videoURL, setVideoURL] = useState({
    MediaUrl: "",
    VideoSubtitleFile: "",
    VideoThumbnail: "",
  });
  const [cmsContent, setCmsContent] = useState({
    DashboardIViewTitle: "",
    DashboardIViewHeading: "",
    DashboardIViewSubHeading: "",
    DashboardIViewLables: [],
    DashboardIViewImageURL: "",
    myCalanderTitle: "",
    myCalanderDescription: "",
    myCalanderLabel: [],
    coachingSessions: {},
    jobSeekerPageTemplate: [],
    jobSeekeerPageTemplateImage: "",
  });
  const [cmsReturnContent, setCmsReturnContent] = useState({
    myCalanderTitle: "",
    myCalanderDescription: "",
    myCalanderLabel: [],
    coachingSessions: {},
    cancelMeeting_Title: "",
    cancelMeeting_Description: "",
    cancelMeeting_Labels: [],
    meetingReschedule_Title: "",
    meetingReschedule_Labels: [],
    meetingReschedule_Duration: [],
    meetingSchedule_Title: "",
    meetingSchedule_Labels: [],
    meetingSchedule_Duration: [],
    jobSeekerPageTemplate: [],
    jobSeekeerPageTemplateImage: "",
  });

  const [cmsContentCoach, setCmsContentcoach] = useState({
    labels: [],
    ImageList: [],
    page_Title: "",
    page_Desc: "",
    meetingConfirm_Title: "",
    meetingConfirm_Labels: [],

    meetingReschedule_Title: "",
    meetingReschedule_Labels: [],
    meetingReschedule_Duration: [],

    meetingSchedule_Title: "",
    meetingSchedule_Labels: [],
    meetingSchedule_Duration: [],

    cancelMeeting_Labels: [],
    cancelMeeting_Title: "",
    cancelMeeting_Description: "",

    commonComponents_Labels: [],

    coachingSessions: {},

    noCoachLabels: [],
    noCoachTitle: "",
    noCoachDescription: "",

    awatingCoachTitle: "",
    awatingCoachDescription: "",

    NoCoachRequestMeeting_Title: "",
    NoCoachRequestMeeting_Labels: [],
    // NoCoachRequestMeeting_Duration: [],
    NoCoachRequestMeeting_TimeSlots: [],

    NoCoachRescheduletMeeting_Title: "",
    NoCoachRescheduletMeeting_Labels: [],
    // NoCoachRescheduletMeeting_Duration: [],
    NoCoachRescheduletMeeting_TimeSlots: [],

    ScheduleCoachingSession_Title: "",
    ScheduleCoachingSession_Labels: [],

    SupportedTimeFormat: "",
  });

  const [dashboardSkillsPortalCard, setDashboardSkillsPortalCard] = useState({
    title: "",
    description: "",
    image: "",
    labels: [],
  });
  const [doceboURL, setDoceboURL] = useState("");
  const [showDocebo, setShowDecebo] = useState(true);

  const [firstView, setFirstView] = useState();
  const [screenLoader, setScreenLoader] = useState(false);
  const [assessmentCardData, setAssessmentCardData] = useState();
  const [mycoachData, setMycoachData] = useState("");
  const [myCoachStatusCheck, setMyCoachStatusCheck] = useState("");
  const [extractMatchQuery, setExtractMatchQuery] = useState("");
  const [recommdedAssessmentCardData, setRecommdedAssessmentCardData] =
    useState();
  const [ThoughtLeadershiptData, setCmsThoughtLeadershiptData] = useState();
  const [professionalPersonaCMSData, setProfessionalPersonaCMSData] =
    useState();
  const [independentPersonaCMSData, setIndependentPersonaCMSData] = useState();
  const [executivePersonaCMSData, setExecutivePersonaCMSData] = useState();
  const [personalInfoData, setpersonalInfoData] = useState();
  const [personaCMSData, setPersonaCMSData] = useState();
  const [thoughtLeadershiptData, setThoughtLeadershiptData] = useState();
  const [myEvents, setMyEvents] = useState([]);
  const [commonImage, setCommonImage] = useState();
  const [learningCollectionCMS, setLearningCollectionCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    recommdedTitle: "",
    recommdedDescription: "",
  });
  const [moreCollectionCMS, setMoreCollectionCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
  });
  const [myResumeCMS, setMyResumeCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: {},
    RichTexts: [],
  });
  const [RecommendedForYouCMS, setRecommendedForYouCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: [],
  });
  const [ThoughtLeadershiptCMS, setThoughtLeadershiptCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: [],
  });
  const [dashboardWidgetEnable, setDashboardWidgetEnable] = useState(false);
  const [DashboardWidgetDataCMS, setCmsDashboardWidgetData] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: {},
    ImageURL: "",
    videoList: [],
    ComponentMediaUrl: "",
    templateLabels: [],
    imageUrl: {},
  });
  const [matchCount, setMatchCount] = useState({
    searchCountLabel: "",
  });
  const [jobMatchCMS, setJobMatchCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    jobSetupImageList: [],
  });
  const [documentCMS, setDocumentCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    richText: [],
  });
  const [documentRVCMS, setDocumentRVCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    richText: [],
  });
  const [greetingCMS, setGreetingCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
  });
  const [jobMatchRVCMS, setJobMatchRVCMS] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: {},
  });

  const [excecutiveCMSData, setExcecutiveCMSData] = useState({
    title: "",
    description: "",
    pageContent: [],
    labels: [],
    imageList: [],
    excecutiveVideoTitle: "",
    excecutiveVideoDescription: "",
    excecutiveVideoImageUrl: "",
    excecutiveVideoUrl: "",
    excecutiveLabel: [],
    bannerTitle: "",
    bannerDescription: "",
    myCalanderTitle: "",
    myCalanderDescription: "",
    myCalanderLabel: [],
    coachingSessions: {},
    programVideoLabel: [],
    videoImageList: [],
    executivePageTemplate: [],
    jobSeekeerPageTemplateImage: "",
    jobSetupImageList: [],
    cancelMeeting_Labels: [],
  });
  const [featuredResourceCMS, setFeaturedResourceCMS] = useState({
    title: "",
    description: "",
    labels: [],
    ImageList: [],
    data: [],
  });

  const [isResumeUploaded, setIsResumeUploaded] = useState(false);
  const [cmsSA, setCmsSA] = useState({});
  const [onDemandEventsWithTags, setOnDemandEventsWithTags] = useState([]);
  const [featuredEvents, setFeaturedEvents] = useState([]);
  const [featureImage, setFeatureImage] = useState();
  const [pageImage, setPageImage] = useState();
  const [recommendedEventsCMS, setRecommendedEventsCMS] = useState({});
  const [eventsWithTags, setEventsWithTags] = useState([]);

  /// Below UseState for popup screen Meeting confirmation
  const [coachSessionReload, setcoachSessionReload] = useState(0);

  const [displayMeetingConfirm, setdisplayMeetingConfirm] = useState(false);
  const [displayMeetingReschedule, setdisplayMeetingReschedule] =
    useState(false);
  const [displayMeetingCancel, setdisplayMeetingCancel] = useState(false);
  const [displayMeetingSchedule, setdisplayMeetingSchedule] = useState(false);
  const [displayUpcomingEventDetails, setdisplayUpcomingEventDetails] =
    useState(false);

  const [myConfirmMeetingData, setmyConfirmMeetingData] = useState({
    date: "",
    time: "",
  });
  const [myRescheduleMeetingData, setmyRescheduleMeetingData] = useState({});
  const [myCancelMeetingData, setmyCancelMeetingData] = useState({});
  const [
    closeMeetingRescheduleConfirmation,
    setcloseMeetingRescheduleConfirmation,
  ] = useState(false);
  const [
    reshceduleMeetingDataNoCoachData,
    setreshceduleMeetingDataNoCoachData,
  ] = useState({});
  const [upcomingMeetings, setUpcomingMeetings] = useState([]);
  const [meetingInvites, setMeetingInvites] = useState([]);
  const [bProgramVideo, setProgramVideo] = useState(false);
  const [programVideoRewatch, setProgramVideoRewatch] = useState(false);
  const [recommendedEvents, setRecommendedEvents] = useState([]);
  const [programVideoData, setProgramVideoData] = useState([]);
  const [fetchPersona, setFetchPersona] = useState("");
  const [videoStatus, setVideoStatus] = useState(false);
  const [candidateLastLoginData, setCandidateLastLoginData] = useState("");
  const [upcomingMeetingData, setupcomingMeetingData] = useState({});
  const [coachStatus, setCoachStatus] = useState();
  const [noRecordsMsg, setNoRecordsMsg] = useState("");
  const [cmsMyDocuments, setCmsMyDocuments] = useState({
    title: "",
    pageLabel: [],
    sortOptions: [],
  });
  const [cmsUploadDocuments, setCmsUploadDocuments] = useState({
    title: "",
    description: "",
    pageLabel: [],
    richTexts: [],
  });

  const [documentsLists, setDocumentsLists] = useState([]);
  const [resumeBuilderData, setResumeBuilderData] = useState("");
  const [resumeBuilderToolUrl, setResumeBuilderToolUrl] = useState("");
  const appInsights = useAppInsightsContext();
  let StartTime;

  const fnGetConfirmMeetingData = (objMeeting) => {
    setmyConfirmMeetingData({
      date: objMeeting["Date"],
      time: objMeeting["Time"],
    });
  };

  const fnGetReshceduleMeetingData = (objMeeting) => {
    setmyRescheduleMeetingData(objMeeting);
  };

  const fnGetCancelMeetingData = (objMeeting) => {
    setmyCancelMeetingData(objMeeting);
  };

  const fnGetReshceduleMeetingDataNoCoach = (objMeeting) => {
    setreshceduleMeetingDataNoCoachData(objMeeting);
  };

  const fnCloseRescheduleDailog = (nflag, prescheduleTime, prescheduleDate) => {
    if (prescheduleTime !== "" || prescheduleDate !== "") {
      setcloseMeetingRescheduleConfirmation(true);
    } else {
      setdisplayMeetingReschedule(nflag);
    }
  };

  const fnGetUpcomingMeetingData = (objMeeting) => {
    setupcomingMeetingData(objMeeting);
  };

  const [sStartDate, setsStartDate] = useState("");
  const [noCoachReload, setnoCoachReload] = useState(0);
  const [called, setCalled] = useState(false);
  const [times, setTimes] = useState();
  const showCalender = JSON.parse(sessionStorage.getItem("calendarShowFlag"));

  const fetchCMSContent = async (pageName, persona) => {
    setScreenLoader(true);
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let refMyResumeCMS = JSON.parse(JSON.stringify(myResumeCMS));
    let refRecommendedForYouCMS = JSON.parse(
      JSON.stringify(RecommendedForYouCMS)
    );
    // let refThoughtLeadershiptCMS = JSON.parse(JSON.stringify(ThoughtLeadershiptCMS));

    let refDashboardWidgetDataCMS = JSON.parse(
      JSON.stringify(DashboardWidgetDataCMS)
    );

    let refJobMatchCMS = JSON.parse(JSON.stringify(jobMatchCMS));
    let refJobMatchRVCMS = JSON.parse(JSON.stringify(jobMatchRVCMS));
    let refDocumentCMS = JSON.parse(JSON.stringify(documentCMS));
    let refDocumentRVCMS = JSON.parse(JSON.stringify(documentRVCMS));
    let refGreetingCMS = JSON.parse(JSON.stringify(greetingCMS));
    let refLearningCollectionCMS = JSON.parse(
      JSON.stringify(learningCollectionCMS)
    );
    let refMoreCollectionCMS = JSON.parse(JSON.stringify(moreCollectionCMS));
    let refDashboardSkillsPortalCard = JSON.parse(
      JSON.stringify(dashboardSkillsPortalCard)
    );
    let payload = {
      PageName: pageName,
    };
    await DashboardActions.GetCMSData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refCmsContent.DashboardIViewTitle = x.Title;
              refCmsContent.commonComponent = x.Components["CommonComponent"];
              refCmsContent.DashboardIViewHeading =
                x.Components["IView.DashboardComponent"]?.AssessmentType?.Value;
              refCmsContent.DashboardIViewSubHeading =
                x.Components["IView.DashboardComponent"]?.Description;
              refCmsContent.DashboardIViewLables =
                x.Components["IView.DashboardComponent"]?.Labels;
              refCmsContent.DashboardIViewImageURL =
                x.Components["IView.DashboardComponent"]?.LargeImage?.ImageUrl;
              refCmsContent.myCalanderTitle =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Title;
              refCmsContent.myCalanderDescription =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Description;
              refCmsContent.myCalanderLabel =
                x.Components["DashboardPage.DashboardMyCalendar"];
              refCmsContent.coachingSessions =
                x.Components["DashboardPage.DashboardMyCalendar"];

              refCmsContent.jobSeekerPageTemplate =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;
              refCmsContent.jobSeekeerPageTemplateImage =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;
              refCmsContent.cancelMeeting_Labels =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;
            }
          });
          setCmsContent(refCmsContent);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refMyResumeCMS.pageContent =
                x.Components["DashboardPage.DashboardMyResume"];
              refMyResumeCMS.title =
                x.Components["DashboardPage.DashboardMyResume"]?.Title;
              refMyResumeCMS.description =
                x.Components["DashboardPage.DashboardMyResume"]?.Description;
              refMyResumeCMS.labels =
                x.Components["DashboardPage.DashboardMyResume"]?.Labels;
              refMyResumeCMS.imageList =
                x.Components["DashboardPage.DashboardMyResume"]?.MediaData;
              refMyResumeCMS.richText =
                x.Components["DashboardPage.DashboardMyResume"]?.RichTexts;
            }
          });
          setMyResumeCMS(refMyResumeCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refRecommendedForYouCMS.title =
                x.Components["DashboardPage.DashboardRecommendedForYou"]?.Title;
              refRecommendedForYouCMS.description =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.Description;
              refRecommendedForYouCMS.labels =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.Labels;
              refRecommendedForYouCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.ImageList;
            }
          });
          setRecommendedForYouCMS(refRecommendedForYouCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              if (x.Components["DashboardPage.DashboardGettingStarted"]) {
                setDashboardWidgetEnable(true);
              }
              refDashboardWidgetDataCMS.title =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Title;
              refDashboardWidgetDataCMS.description =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.Description;
              refDashboardWidgetDataCMS.labels =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;
              refDashboardWidgetDataCMS.templateLabels =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;
              refDashboardWidgetDataCMS.imageUrl =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;
              refDashboardWidgetDataCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;
              refDashboardWidgetDataCMS.videoList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.VideoList;
            }
          });
          setCmsDashboardWidgetData(refDashboardWidgetDataCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refJobMatchCMS.pageContent =
                x.Components["DashboardPage.DashboardJobMatchSetup"];
              refJobMatchCMS.title =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Title;
              refJobMatchCMS.description =
                x.Components[
                  "DashboardPage.DashboardJobMatchSetup"
                ]?.Description;
              refJobMatchCMS.labels =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Labels;
              refJobMatchCMS.ImageList =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.MediaData;
            }
          });

          setJobMatchCMS(refJobMatchCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refDocumentCMS.pageContent =
                x.Components["MyDocumentsPage.MyDocuments"];
              refDocumentCMS.title =
                x.Components["MyDocumentsPage.MyDocuments"]?.Title;
              refDocumentCMS.description =
                x.Components["MyDocumentsPage.MyDocuments"]?.Description;
              refDocumentCMS.labels =
                x.Components["MyDocumentsPage.MyDocuments"]?.Labels;
              refDocumentCMS.richText =
                x.Components["MyDocumentsPage.MyDocuments"]?.RichTexts;
            }
          });
          setDocumentCMS(refDocumentCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              let lcList =
                x.Components["DashboardPage.DashboardLearningCollections"];
              refLearningCollectionCMS.title = lcList?.Title;
              refLearningCollectionCMS.description = lcList?.Description;
              refLearningCollectionCMS.pageContent = lcList?.Pages;
            }
          });
          setLearningCollectionCMS(refLearningCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              let mcList =
                x.Components["DashboardPage.DashboardMoreCollections"];
              refMoreCollectionCMS.title = mcList?.Title;
              refMoreCollectionCMS.description = mcList?.Description;
              refMoreCollectionCMS.pageContent = mcList?.Pages;
            }
          });
          setMoreCollectionCMS(refMoreCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refJobMatchRVCMS.pageContent =
                x.Components["DashboardPage.DashboardMyResume"];
              refJobMatchRVCMS.title =
                x.Components["DashboardPage.DashboardMyResume"]?.Title;
              refJobMatchRVCMS.description =
                x.Components["DashboardPage.DashboardMyResume"]?.Description;
              refJobMatchRVCMS.labels =
                x.Components["DashboardPage.DashboardMyResume"]?.Labels;
              refJobMatchRVCMS.imageList =
                x.Components["DashboardPage.DashboardMyResume"]?.MediaData;
            }
          });
          setJobMatchRVCMS(refJobMatchRVCMS);

          resData.Pages.filter((x) => {
            // if (x.Key === "DashboardPageReturnVisit") {
            refDashboardSkillsPortalCard.title =
              x.Components["DashboardPage.DashboardSkillsPortalCard"]?.Title;
            refDashboardSkillsPortalCard.description =
              x.Components[
                "DashboardPage.DashboardSkillsPortalCard"
              ]?.Description;
            refDashboardSkillsPortalCard.image =
              x.Components[
                "DashboardPage.DashboardSkillsPortalCard"
              ]?.MediaData?.LargeImage;
            refDashboardSkillsPortalCard.labels =
              x.Components["DashboardPage.DashboardSkillsPortalCard"]?.Labels;

            // }
          });
          setDashboardSkillsPortalCard(refDashboardSkillsPortalCard);
          fetchRecommndedAssessmentData(refCmsContent.commonComponent);

          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchCMSContentCoach = async () => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));

    let payload = {
      PageName: "MyCoach",
    };
    await coachActions.GetCMSJobLabels(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          resData.Pages.filter((x) => {
            if (x.Key === "MyCoachPage") {
              refCmsContent.labels =
                x.Components["MyCoachPage.MyCoachCoachAssigned"]?.Labels;

              refCmsContent.ImageList =
                x.Components["MyCoachPage.MyCoachCoachAssigned"]?.ImageList;

              refCmsContent.page_Title = x?.Title;
              refCmsContent.page_Desc = x?.Description;

              // CMS for meeting Confirmation in Coaching session
              refCmsContent.meetingConfirm_Title =
                x.Components["MyCoachPage.MyCoachMeetingConfirmation"]?.Title;

              refCmsContent.meetingConfirm_Labels =
                x.Components["MyCoachPage.MyCoachMeetingConfirmation"]?.Labels;

              // CMS for Schedule a meeting
              refCmsContent.meetingSchedule_Title =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Title;

              refCmsContent.meetingSchedule_Labels =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

              // CMS for Re-Schedule a meeting in Coaching session
              refCmsContent.meetingReschedule_Title =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Title;

              refCmsContent.meetingReschedule_Labels =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Labels;

              let arrReScheduleMeetingDuration = [];
              x.Components[
                "MyCoachPage.MyCoachRescheduleMeeting"
              ]?.LOVList.filter((eleLOV) => {
                if (
                  eleLOV.Key === "MyCoachPage.MyCoachRescheduleMeeting.Duration"
                ) {
                  eleLOV?.Values?.map((eleVal) => {
                    arrReScheduleMeetingDuration.push({
                      name: eleVal.Value,
                      value: eleVal.ID,
                    });
                  });
                }
              });
              arrReScheduleMeetingDuration.unshift({
                name: "Select",
                value: "",
              });
              refCmsContent.meetingReschedule_Duration =
                arrReScheduleMeetingDuration;

              // CMS for Common Component
              refCmsContent.commonComponents_Labels =
                x.Components["CommonComponent"]?.Labels;

              refCmsContent.cancelMeeting_Labels =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;

              refCmsContent.cancelMeeting_Title =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Title;

              refCmsContent.cancelMeeting_Description =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Description;

              refCmsContent.coachingSessions =
                x.Components["MyCoachPage.MyCoachCoachingSessions"];

              refCmsContent.noCoachLabels =
                x.Components["MyCoachPage.NoSpecificCoach"]?.Labels;
              refCmsContent.noCoachTitle =
                x.Components["MyCoachPage.NoSpecificCoach"]?.Title;
              refCmsContent.noCoachDescription =
                x.Components["MyCoachPage.NoSpecificCoach"]?.Description;
              refCmsContent.awatingCoachTitle =
                x.Components["MyCoachPage.AwaitingCoachAssignment"]?.Title;
              refCmsContent.awatingCoachDescription =
                x.Components[
                  "MyCoachPage.AwaitingCoachAssignment"
                ]?.Description;

              /// No Coach Request Meeting CMS
              refCmsContent.NoCoachRequestMeeting_Title =
                x.Components[
                  "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"
                ]?.Title;
              refCmsContent.NoCoachRequestMeeting_Labels =
                x.Components[
                  "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"
                ]?.Labels;
              let arrTimeSolts = [];
              x.Components[
                "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"
              ]?.LOVList.filter((eleLOV) => {
                if (
                  eleLOV.Key ===
                  "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned.TimeSlot"
                ) {
                  eleLOV?.Values?.map((eleVal) => {
                    arrTimeSolts.push({
                      name: eleVal.Value,
                      value: eleVal.Value,
                    });
                  });
                }
              });
              refCmsContent.NoCoachRequestMeeting_TimeSlots = arrTimeSolts;
              let arrTimeDur = [];
              x.Components[
                "MyCoachPage.MyCoachRequestMeetingNoCoachAssigned"
              ]?.LOVList.filter((eleLOV) => {
                if (
                  eleLOV.Key === "MyCoachPage.MyCoachRequestMeeting.Duration"
                ) {
                  eleLOV?.Values?.map((eleVal) => {
                    arrTimeDur.push({
                      name: eleVal.Value,
                      value: eleVal.Value,
                    });
                  });
                }
              });

              refCmsContent.NoCoachRequestMeeting_TimeDur = arrTimeDur;

              ///No Coach ReSchdeule Meeting CMS
              refCmsContent.NoCoachRescheduletMeeting_Title =
                x.Components[
                  "MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"
                ]?.Title;
              refCmsContent.NoCoachRescheduletMeeting_Labels =
                x.Components[
                  "MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"
                ]?.Labels;
              let arrReScheduleTimeSolts = [];
              x.Components[
                "MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned"
              ]?.LOVList.filter((eleLOV) => {
                if (
                  eleLOV.Key ===
                  "MyCoachPage.MyCoachReScheduleMeetingNoCoachAssigned.TimeSlot"
                ) {
                  eleLOV?.Values?.map((eleVal) => {
                    arrReScheduleTimeSolts.push({
                      name: eleVal.Value,
                      value: eleVal.Value,
                    });
                  });
                }
              });
              refCmsContent.NoCoachRescheduletMeeting_TimeSlots =
                arrReScheduleTimeSolts;

              /// for user story 5508 Schedule a coaching session component
              refCmsContent.ScheduleCoachingSession_Title =
                x.Components[
                  "MyCoachPage.MyCoachScheduleCoachingSession"
                ]?.Title;
              refCmsContent.ScheduleCoachingSession_Labels =
                x.Components[
                  "MyCoachPage.MyCoachScheduleCoachingSession"
                ]?.Labels;
            }
          });

          resData.MarketDetails.filter((ele) => {
            refCmsContent.SupportedTimeFormat =
              ele.SupportedTimeFormat["Value"];
          });
          setCmsContentcoach(refCmsContent);
          window.sessionStorage.setItem(
            "CoachCMS",
            JSON.stringify(refCmsContent)
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchLRLabels = (key) => {
    let refLRContent = dashboardSkillsPortalCard?.labels;
    let label = "";
    if (refLRContent) {
      refLRContent?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchLabelCoach = (key, section) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContentCoach));
    let label = "";
    if (refCmsContent?.[section].length > 0) {
      refCmsContent[section].map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  // Dashboard default return visit
  const fetchDashboardReturnVisitContent = async (pageName, persona) => {
    setScreenLoader(true);
    let refCmsContent = JSON.parse(JSON.stringify(cmsReturnContent));
    let refMyResumeCMS = JSON.parse(JSON.stringify(myResumeCMS));
    let refLearningCollectionCMS = JSON.parse(
      JSON.stringify(learningCollectionCMS)
    );
    let refMoreCollectionCMS = JSON.parse(JSON.stringify(moreCollectionCMS));
    let refRecommendedForYouCMS = JSON.parse(
      JSON.stringify(RecommendedForYouCMS)
    );

    let refDashboardWidgetDataCMS = JSON.parse(
      JSON.stringify(DashboardWidgetDataCMS)
    );

    let refJobMatchCMS = JSON.parse(JSON.stringify(jobMatchCMS));
    let refJobMatchRVCMS = JSON.parse(JSON.stringify(jobMatchRVCMS));
    let refDocumentCMS = JSON.parse(JSON.stringify(documentCMS));
    let refDocumentRVCMS = JSON.parse(JSON.stringify(documentRVCMS));
    let refGreetingCMS = JSON.parse(JSON.stringify(greetingCMS));
    let refDashboardSkillsPortalCard = JSON.parse(
      JSON.stringify(dashboardSkillsPortalCard)
    );

    let payload = {
      PageName: pageName,
    };
    await DashboardActions.GetCMSData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refCmsContent.cancelMeeting_Title =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Title;

              refCmsContent.cancelMeeting_Description =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Description;

              refCmsContent.cancelMeeting_Labels =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;
              // CMS for Re-Schedule a meeting in Coaching session
              refCmsContent.meetingReschedule_Title =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Title;

              refCmsContent.jobSeekerPageTemplate =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;

              refCmsContent.meetingReschedule_Labels =
                x.Components["MyCoachPage.MyCoachRescheduleMeeting"]?.Labels;

              let arrReScheduleMeetingDuration = [];
              x.Components[
                "MyCoachPage.MyCoachRescheduleMeeting"
              ]?.LOVList.filter((eleLOV) => {
                if (
                  eleLOV.Key === "MyCoachPage.MyCoachRescheduleMeeting.Duration"
                ) {
                  eleLOV?.Values?.map((eleVal) => {
                    arrReScheduleMeetingDuration.push({
                      name: eleVal.Value,
                      value: eleVal.ID,
                    });
                  });
                }
              });
              arrReScheduleMeetingDuration.unshift({
                name: "Select",
                value: "",
              });
              refCmsContent.meetingReschedule_Duration =
                arrReScheduleMeetingDuration;

              // CMS for Schedule a meeting
              refCmsContent.meetingSchedule_Title =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Title;

              refCmsContent.meetingSchedule_Labels =
                x.Components["CalendarPage.ScheduleAMeetingWithCoach"]?.Labels;

              refCmsContent.jobSeekeerPageTemplateImage =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;
            }
          });

          setCmsReturnContent(refCmsContent);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refMyResumeCMS.pageContent =
                x.Components["DashboardPage.DashboardMyResume"];
              refMyResumeCMS.title =
                x.Components["DashboardPage.DashboardMyResume"]?.Title;
              refMyResumeCMS.description =
                x.Components["DashboardPage.DashboardMyResume"]?.Description;
              refMyResumeCMS.labels =
                x.Components["DashboardPage.DashboardMyResume"]?.Labels;
              refMyResumeCMS.imageList =
                x.Components["DashboardPage.DashboardMyResume"]?.MediaData;
              refMyResumeCMS.richText =
                x.Components["DashboardPage.DashboardMyResume"]?.RichTexts;
            }
          });
          setMyResumeCMS(refMyResumeCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refCmsContent.DashboardIViewTitle = x.Title;
              refCmsContent.commonComponent = x.Components["CommonComponent"];
              refCmsContent.DashboardIViewHeading =
                x.Components["IView.DashboardComponent"]?.AssessmentType?.Value;
              refCmsContent.DashboardIViewSubHeading =
                x.Components["IView.DashboardComponent"]?.Description;
              refCmsContent.DashboardIViewLables =
                x.Components["IView.DashboardComponent"]?.Labels;
              refCmsContent.DashboardIViewImageURL =
                x.Components["IView.DashboardComponent"]?.LargeImage?.ImageUrl;
              refCmsContent.myCalanderTitle =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Title;
              refCmsContent.myCalanderDescription =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Description;
              refCmsContent.myCalanderLabel =
                x.Components["DashboardPage.DashboardMyCalendar"];
              refCmsContent.coachingSessions =
                x.Components["MyCoachPage.MyCoachCoachingSessions"];

              refRecommendedForYouCMS.title =
                x.Components["DashboardPage.DashboardRecommendedForYou"]?.Title;
              refRecommendedForYouCMS.description =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.Description;
              refRecommendedForYouCMS.labels =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.Labels;
              refRecommendedForYouCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardRecommendedForYou"
                ]?.ImageList;
            }
          });
          setCmsContent(refCmsContent);
          setRecommendedForYouCMS(refRecommendedForYouCMS);
          fetchRecommndedAssessmentData(refCmsContent.commonComponent);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              if (x.Components["DashboardPage.DashboardGettingStarted"]) {
                setDashboardWidgetEnable(true);
              }
              refDashboardWidgetDataCMS.title =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Title;
              refDashboardWidgetDataCMS.description =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.Description;
              refDashboardWidgetDataCMS.labels =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;
              refDashboardWidgetDataCMS.templateLabels =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;
              refDashboardWidgetDataCMS.imageUrl =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;
              refDashboardWidgetDataCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;
              refDashboardWidgetDataCMS.videoList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.VideoList;
            }
          });
          setCmsDashboardWidgetData(refDashboardWidgetDataCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refDocumentRVCMS.pageContent =
                x.Components["MyDocumentsPage.MyDocuments"];
              refDocumentRVCMS.title =
                x.Components["MyDocumentsPage.MyDocuments"]?.Title;
              refDocumentRVCMS.description =
                x.Components["MyDocumentsPage.MyDocuments"]?.Description;
              refDocumentRVCMS.labels =
                x.Components["MyDocumentsPage.MyDocuments"]?.Labels;
              refDocumentRVCMS.richText =
                x.Components["MyDocumentsPage.MyDocuments"]?.RichTexts;
            }
          });
          setDocumentRVCMS(refDocumentRVCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              let lcList =
                x.Components["DashboardPage.DashboardLearningCollections"];
              refLearningCollectionCMS.title = lcList?.Title;
              refLearningCollectionCMS.description = lcList?.Description;
              refLearningCollectionCMS.pageContent = lcList?.Pages;
            }
          });
          setLearningCollectionCMS(refLearningCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              let mcList =
                x.Components["DashboardPage.DashboardMoreCollections"];
              refMoreCollectionCMS.title = mcList?.Title;
              refMoreCollectionCMS.description = mcList?.Description;
              refMoreCollectionCMS.pageContent = mcList?.Pages;
            }
          });
          setMoreCollectionCMS(refMoreCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refGreetingCMS.pageContent =
                x.Components["JobSearch.JobGreetings"];
              refGreetingCMS.title =
                x.Components["JobSearch.JobGreetings"]?.Title;
              refGreetingCMS.description =
                x.Components["JobSearch.JobGreetings"]?.Description;
              refGreetingCMS.labels =
                x.Components["JobSearch.JobGreetings"]?.Labels;
            }
          });
          setGreetingCMS(refGreetingCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refDashboardSkillsPortalCard.title =
                x.Components["DashboardPage.DashboardSkillsPortalCard"]?.Title;
              refDashboardSkillsPortalCard.description =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ]?.Description;
              refDashboardSkillsPortalCard.image =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ]?.MediaData?.LargeImage;
              refDashboardSkillsPortalCard.labels =
                x.Components["DashboardPage.DashboardSkillsPortalCard"]?.Labels;
            }
          });
          setDashboardSkillsPortalCard(refDashboardSkillsPortalCard);

          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchCount = async () => {
    let payload = {
      learningResourceType: "assessmentsandtasks",
      ResourceCategory: "assessmentsandtasks",
      recommended: "false",
      isAllLearningResources: "true",
    };

    await DashboardActions.GetDiscoverData(payload).then(
      (res) => {
        if (res.status === 200) {
          let countData = res?.data && res?.data?.learningResourceCount;
          window.sessionStorage.setItem(
            "AssessmentsCount",
            countData?.assessments ? countData?.assessments : 0
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchRecommndedAssessmentData = async (refCMS) => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    let profileDetails = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    let recommendedSectionData = [];
    let assessData = [];
    let toolsData = [];
    let resourcesData = [];

    let assessPayload;
    let toolsPayload;
    let resourcesPayload;

    if (profileDetails?.personaName === "Executive") {
      assessPayload = {
        learningResourceType: "assessmentsandtasks",
        recommended: "false",
        isAllLearningResources: "true",
        IsThoughtLeadership: "true",
        ResourceCategory: "assessmentsandtasks",
      };

      toolsPayload = {
        learningResourceType: "tools",
        recommended: "false",
        isAllLearningResources: "true",
        IsThoughtLeadership: "true",
        ResourceCategory: "tools",
      };

      resourcesPayload = {
        learningResourceType: "resources",
        recommended: "false",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        IsThoughtLeadership: "true",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    } else {
      assessPayload = {
        learningResourceType: "assessmentsandtasks",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "assessmentsandtasks",
      };

      toolsPayload = {
        learningResourceType: "tools",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "tools",
      };

      resourcesPayload = {
        learningResourceType: "resources",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    }

    await DashboardActions.GetDiscoverData(assessPayload).then(
      (res) => {
        if (res.status === 200) {
          assessData = res?.data && res?.data?.responsemodel;
        }
      },
      (err) => {
        console.log(err);
      }
    );
    await DashboardActions.GetDiscoverData(toolsPayload).then(
      (res) => {
        if (res.status === 200) {
          toolsData = res?.data && res?.data?.responsemodel;
        }
      },
      (err) => {
        console.log(err);
      }
    );

    await DashboardActions.GetDiscoverData(resourcesPayload).then(
      (res) => {
        if (res.status === 200) {
          resourcesData = res?.data && res?.data?.responsemodel;
        }
      },
      (err) => {
        console.log(err);
      }
    );

    recommendedSectionData = [...assessData, ...toolsData, ...resourcesData];

    let resData = recommendedSectionData && recommendedSectionData;
    // let _30SecondCommercialPayload = {
    //   PageName: "30 Second Commercial"
    // }
    // DashboardActions.Get_30_secondCommercialCMS(_30SecondCommercialPayload).then(response => {
    //   if (response.status === 200) {
    //     let resp = response.data;
    //     resData?.map(recommendedCards => {
    //       Object.keys(recommendedCards).forEach(key => {
    //         if (recommendedCards[key] === resp.ID) {
    //           recommendedCards["externalId"] = resp.ExternalId
    //         }
    //       })
    //     })
    //   }
    // }, err => {
    //   console.log(err)
    // })
    const fetchLikeLabel2 = (id) => {
      let refCmsContent = refCMS;
      let label = "";
      if (refCmsContent?.Labels) {
        refCmsContent?.Labels.map((x) => {
          if (x.Key === id) {
            label = x.Phrase;
          }
        });
      }
      return label;
    };

    resData?.map((item) => {
      let tags = [];
      item?.focusArea?.map((tg) => {
        tags.push(tg.value);
        if (item?.isRecommended == true) {
          let a =
            fetchLikeLabel2("CommonComponent.CommonRecommendedText") ||
            fetchLikeLabel("CommonComponent.CommonRecommendedText");
          tags.push(a === "" ? "Recommended" : a);
        }
      });
      item["tags"] = tags;
      return item;
    });
    setRecommdedAssessmentCardData(resData);
    sortRecommendedData(resData);
  };

  const sortRecommendedData = (data) => {
    let likeSort = data && data.sort((a, b) => b.likeCount - a.likeCount);

    let alphaSort =
      likeSort &&
      likeSort.sort((a, b) =>
        a.title.toUpperCase().localeCompare(b.title.toUpperCase())
      );

    let progressSort =
      alphaSort &&
      alphaSort.filter((x) => x.learningResourceStatus == "inprogress");

    let completedSort =
      alphaSort &&
      alphaSort.filter((x) => x.learningResourceStatus == "completed");

    let notStartedSort =
      alphaSort &&
      alphaSort.filter((x) => x.learningResourceStatus == "notstarted");

    let sortedArray = [];
    sortedArray = [...progressSort, ...notStartedSort, ...completedSort];

    setRecommdedAssessmentCardData(sortedArray);
  };

  const checkResumeUploadStatus = async () => {
    setScreenLoader(true);
    await DashboardActions.ResumeUploadStatus().then(
      (res) => {
        if (res.status === 200 && res?.data?.length > 0) {
          const getProfileData =
            window.sessionStorage.getItem("setProfileDetails");
          setFetchPersona(JSON.parse(getProfileData));
          getProgramVideoData();
          setIsResumeUploaded(true);
          context.setResPresent(true);
          setExtractMatchQuery(res?.data[0]?.extractMatchQuery);
          let parseJSON = JSON.parse(atob(res.data[0].parseResponseFile));
          let city;
          let country;
          parseJSON?.map((parse) => {
            city = parse.contact_details.city;
            country = parse.contact_details.country_code.toUpperCase();
          });
          getAllMatchedjobs(res?.data[0]?.extractMatchQuery, city, country);
          setScreenLoader(false);
          // getProgramVideoData("resumeUploaded");
        } else {
          setScreenLoader(false);
          setIsResumeUploaded(false);
          const getProfileData =
            window.sessionStorage.getItem("setProfileDetails");
          setFetchPersona(JSON.parse(getProfileData));
          getProgramVideoData();
          // getProgramVideoData("resumeNotUploaded");
        }
      },
      (err) => {
        setScreenLoader(false);
        console.log(err);
      }
    );
  };

  const getAllMatchedjobs = async (query, city, country) => {
    let refMatchCount = JSON.parse(JSON.stringify(matchCount));
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    const documentDate = candidateLastLoginData
      .toLocaleString("yyyy-MM-dd")
      .slice(0, 10);
    // let programCountry = JSON.parse(window.localStorage.getItem("setProgramDetails"))
    let payload = {
      Querystring:
        query +
        " " +
        "documentdate:>=" +
        documentDate +
        " " +
        `city:\"${city}\"` +
        " " +
        `jf_source_country:${country}`,
      country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programCountryCode"
      ],
      searchafterscore: "",
      searchafter: "",
      searchafterdocid: "",
      language: "en",
      OwnerId: candidateID,
      isFlagsRequired: true,
    };
    if (documentDate) {
      await DashboardActions.GetMatchedjobs(payload).then(
        (res) => {
          if (res.status === 200) {
            if (res?.data[0].search_info.result_count > 0) {
              refMatchCount.searchCountLabel =
                res?.data[0].search_info?.result_count;
              setMatchCount(refMatchCount);
              context.setJobCount(res?.data[0].search_info?.result_count);
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  const fetchLabel = (key, section) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (section == "coach") {
      refCmsContent?.commonComponent?.Labels.map((x) => {
        if (x.Key == key) {
          label = x.Phrase;
        }
      });
    } else {
      if (
        refCmsContent &&
        refCmsContent?.[section] &&
        refCmsContent?.[section].length > 0
      ) {
        refCmsContent[section].map((x) => {
          if (x.Key === key) {
            label = x.Phrase;
          }
        });
      }
    }
    return label;
  };

  const fetchLikeLabel = (id) => {
    let refCmsContent = cmsContent && cmsContent?.commonComponent;
    let label = "";
    if (refCmsContent?.Labels) {
      refCmsContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExecutiveCommonLabel = (id) => {
    let refCmsContent = excecutiveCMSData && excecutiveCMSData?.commonComponent;
    let label = "";
    if (refCmsContent?.Labels) {
      refCmsContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExecutiveLabel = (id) => {
    let refCmsContent =
      excecutiveCMSData && excecutiveCMSData?.programVideoLabel;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExecutivePageTemplateLabel = (id) => {
    let refCmsContent =
      excecutiveCMSData && excecutiveCMSData?.executivePageTemplate;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchJobSeekerPageTemplateLabel = (id) => {
    let refCmsContent = cmsContent && cmsContent?.jobSeekerPageTemplate;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchJobSeekerReturnPageTemplateLabel = (id) => {
    let refCmsContent =
      cmsReturnContent && cmsReturnContent?.jobSeekerPageTemplate;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchCalanderLabel = (id) => {
    let refCmsContent = cmsContent && cmsContent?.myCalanderLabel;
    let label = "";
    if (refCmsContent?.Labels) {
      refCmsContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchExecutiveCalanderLabel = (id) => {
    let refCmsContent = excecutiveCMSData && excecutiveCMSData?.myCalanderLabel;
    let label = "";
    if (refCmsContent?.Labels) {
      refCmsContent?.Labels.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchDashboardLabel = (id) => {
    let refCmsContent =
      DashboardWidgetDataCMS && DashboardWidgetDataCMS?.labels;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent?.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const fetchDashboardTemplateLabel = (id) => {
    let refCmsContent =
      DashboardWidgetDataCMS && DashboardWidgetDataCMS?.templateLabels;
    let label = "";
    if (refCmsContent && refCmsContent.length > 0) {
      refCmsContent?.map((x) => {
        if (x.Key === id) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const FetchVideo = async () => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";

    let payload = {
      ProgramClassIds: ProgramData?.programClassId
        ? (ProgramData?.programClassId).toString()
        : "19",
    };
    await DashboardActions.GetClientSpecificProgramVideo(payload).then(
      (res) => {
        if (res.status == 200) {
          setVideoURL(res?.data);
        } else if (res.status == 204) {
          setNoClientSpecificContent(true);
        }
      }
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    TrackActions.EventTrack(appInsights, "ViewDashboard", property);
    TrackActions.PageViewRecord(appInsights, "Dashboard");
    TrackActions.PageAction("Dashboard");
    TrackActions.PageAction("ViewDashboard", property);
    context.setPath("dashboard");
    sessionStorage.setItem("setPath", JSON.stringify("dashboard"));
    FetchVideo();
    checkResumeUploadStatus();
  }, [candidateLastLoginData]);

  useEffect(() => {
    if (coachSessionReload != 0) {
      window.location.reload(false);
    }
  }, [coachSessionReload]);

  useEffect(() => {
    FetchVideo();
    fetchCMSContentCoach();
    getMyCoach();
    fetchCount();
    //checkResumeUploadStatus();
    //fetchRecommndedAssessmentData();
    getUpcomingMeetings();
    getMeetingInvites();
    fetchEvents();
    fetchThoughtLeadershiptData();
    fetchFeaturedResourceData();
    getAllDocumentsLists();
    fetchCandidateLastLogin();
    showResumeBuilderData();
    // showResumeBuilderUrl();
    getResumeBuilderUrl();
    getdoceboLink();
  }, [context?.language]);

  useEffect(() => {
    if (fetchPersona && videoStatus) {
      if (
        fetchPersona?.personaName == "Job Seeker" &&
        ((programVideoData && programVideoData?.length > 0) || isResumeUploaded)
      ) {
        fetchDashboardReturnVisitContent("DashboardReturnVisit", "Job Seeker");
      } else if (
        fetchPersona?.personaName == "Job Seeker" &&
        ((programVideoData && programVideoData?.length == 0) ||
          !isResumeUploaded)
      ) {
        fetchCMSContent("DashboardFirstVisit", "Job Seeker");
      } else if (
        fetchPersona?.personaName == "Executive" &&
        programVideoData &&
        programVideoData?.length == 0 &&
        !isResumeUploaded
      ) {
        fetchExcecutiveCmsFirstViewData();
      } else if (
        fetchPersona?.personaName == "Executive" &&
        ((programVideoData && programVideoData?.length > 0) || isResumeUploaded)
      ) {
        fetchExcecutiveCmsReturnViewData();
      } else if (
        fetchPersona?.personaName == "Independent" &&
        programVideoData &&
        programVideoData?.length == 0 &&
        !isResumeUploaded
      ) {
        fetchCMSContent("DashboardFirstVisit", "Independent");
      } else if (
        fetchPersona?.personaName == "Independent" &&
        ((programVideoData && programVideoData?.length > 0) || isResumeUploaded)
      ) {
        fetchDashboardReturnVisitContent("DashboardReturnVisit", "Independent");
      } else if (
        fetchPersona?.personaName == "Professional" &&
        programVideoData &&
        programVideoData?.length == 0 &&
        !isResumeUploaded
      ) {
        fetchCMSContent("DashboardFirstVisit", "Professional");
      } else if (
        fetchPersona?.personaName == "Professional" &&
        ((programVideoData && programVideoData?.length > 0) || isResumeUploaded)
      ) {
        fetchDashboardReturnVisitContent(
          "DashboardReturnVisit",
          "Professional"
        );
      } else if (fetchPersona?.personaName == "Entrepreneur") {
        fetchCMSContent("DashboardFirstVisit", "Entrepreneur");
        fetchRecommndedAssessmentData();
      } else if (fetchPersona?.personaName == "CareerProgram") {
        fetchCMSContent("DashboardFirstVisit", "CareerProgram");
      }
    }
  }, [videoStatus, context.language]);

  const property = {
    "Component Name": "ViewDashboard",
    Language:
      JSON.parse(window.sessionStorage.getItem("Language")) &&
      JSON.parse(window.sessionStorage.getItem("Language"))[
        "selectedLanguageFullName"
      ],
  };

  const convertSize = (bytes) => {
    const sufixes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (
      (!bytes && "0 Bytes") ||
      (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i]
    );
  };

  const dateFormat = (date) => {
    let convDate = Moment(date).format("DD/MM/YYYY");
    return convDate;
  };

  const sortByNewest = (data) => {
    data.sort(function (a, b) {
      return new Date(b.createdTimeStamp) - new Date(a.createdTimeStamp);
    });
    return data;
  };

  const getAllDocumentsLists = async (fromUploadFlag = false) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      ownerId: candidateID,
    };
    await DashboardActions.GetAllDocuments(payload).then(
      (res) => {
        if (res.status === 200) {
          let respData = res.data.map((x) => {
            x["name"] = x.documentName;
            x["size"] = convertSize(x.documentSize);
            x["type"] = x.fileFormat;
            x["uploadDate"] = dateFormat(x.documentUploadedTimeStamp);
            return x;
          });

          setDocumentsLists(sortByNewest(respData));
          if (!fromUploadFlag) {
            setScreenLoader(false);
          }
          setNoRecordsMsg("");
        } else {
          setNoRecordsMsg(
            fetchLabel("MyDocumentsPage.MyDocuments.NoDocumentsMessage")
          );
          if (!fromUploadFlag) {
            setScreenLoader(false);
          }
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const fetchFeaturedResourceData = () => {
    let refFeaturedResourceCMS = JSON.parse(
      JSON.stringify(featuredResourceCMS)
    );
    let payload = {
      IsFeatured: "True",
    };
    DashboardActions.GetFeaturedResourceData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data && res.data.length > 0 && res.data[0]?.Values;
          resData = resData && resData[0];
          refFeaturedResourceCMS.data = resData && resData;
          setFeaturedResourceCMS(refFeaturedResourceCMS);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchThoughtLeadershiptData = async () => {
    let ProgramData =
      JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null
        ? JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        : "";
    let profileDetails = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    setCoachStatus(ProgramData.isCoachingAvailableForProgram);

    let payload;
    if (profileDetails?.persona === "Executive") {
      payload = {
        learningResourceType: "resources",
        recommended: "false",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        IsThoughtLeadership: "true",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    } else {
      payload = {
        learningResourceType: "resources",
        recommended: "false",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    }
    await DashboardActions.GetDiscoverData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res?.data && res?.data?.responsemodel;
          const count = res?.data && res?.data?.learningResourceCount;
          window.sessionStorage.setItem(
            "ResourcesCount",
            count?.resources ? count?.resources : 0
          );
          // resData?.map((item) => {
          //   let tags = [];
          //   item?.focusArea?.map((tg) => {
          //     tags.push(tg.value)
          //     if (item?.isRecommended == true) {
          //       let a = fetchLikeLabel("CommonComponent.CommonRecommendedText")
          //       tags.push(a === "" ? "Recommended" : a)
          //     }
          //   })
          //   item['tags'] = tags;
          //   return item;
          // })

          // setThoughtLeadershiptData(resData);
        }
      },
      (err) => {
        console.log(err);
      }
    );

    let recommendedSectionData = [];
    let assessData = [];
    let toolsData = [];
    let resourcesData = [];

    let assessPayload;
    let toolsPayload;
    let resourcesPayload;

    if (profileDetails?.personaName === "Executive") {
      assessPayload = {
        learningResourceType: "assessmentsandtasks",
        recommended: "false",
        isAllLearningResources: "true",
        IsThoughtLeadership: "true",
        ResourceCategory: "assessmentsandtasks",
      };

      toolsPayload = {
        learningResourceType: "tools",
        recommended: "false",
        isAllLearningResources: "true",
        IsThoughtLeadership: "true",
        ResourceCategory: "tools",
      };

      resourcesPayload = {
        learningResourceType: "resources",
        recommended: "false",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        IsThoughtLeadership: "true",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    } else {
      assessPayload = {
        learningResourceType: "assessmentsandtasks",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "assessmentsandtasks",
      };

      toolsPayload = {
        learningResourceType: "tools",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "tools",
      };

      resourcesPayload = {
        learningResourceType: "resources",
        recommended: "true",
        isAllLearningResources: "true",
        ResourceCategory: "resources",
        ProgramClassIds: ProgramData?.programClassId
          ? [(ProgramData?.programClassId).toString()]
          : "",
      };
    }

    await DashboardActions.GetDiscoverData(assessPayload).then(
      (res) => {
        if (res.status === 200) {
          assessData = res?.data && res?.data?.responsemodel;
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
    await DashboardActions.GetDiscoverData(toolsPayload).then(
      (res) => {
        if (res.status === 200) {
          toolsData = res?.data && res?.data?.responsemodel;
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    await DashboardActions.GetDiscoverData(resourcesPayload).then(
      (res) => {
        if (res.status === 200) {
          resourcesData = res?.data && res?.data?.responsemodel;
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );

    recommendedSectionData = [...assessData, ...toolsData, ...resourcesData];

    let resData = recommendedSectionData && recommendedSectionData;
    // let _30SecondCommercialPayload = {
    //   PageName: "30 Second Commercial"
    // }
    // DashboardActions.GetCMSData(_30SecondCommercialPayload).then(response => {
    //   if (response.status === 200) {
    //     let resp = response.data;
    //     resData?.map(recommendedCards => {
    //       Object.keys(recommendedCards).forEach(key => {
    //         if (recommendedCards[key] === resp.ID) {
    //           recommendedCards["externalId"] = resp.ExternalId
    //         }
    //       })
    //     })
    //   } else {
    //     console.log(response.data)
    //   }
    // }, err => {
    //   console.log(err)
    // })
    const completedArray =
      resData &&
      resData?.filter((x) => x.learningResourceStatus != "completed");
    completedArray?.map((item) => {
      let tags = [];
      item?.focusArea?.map((tg) => {
        tags.push(tg.value);
        if (item?.isRecommendedCMS === true) {
          let a = fetchLikeLabel("CommonComponent.CommonRecommendedText");
          tags.push(a === "" ? "Recommended" : a);
        }
      });
      item["tags"] = tags;
      return item;
    });

    setThoughtLeadershiptData(completedArray);
  };

  // show resume builder widget
  const showResumeBuilderData = async () => {
    let payload = {
      learningResourceType: "tools",
      recommended: "false",
      isAllLearningResources: "true",
      ResourceCategory: "tools",
    };
    await DashboardActions.GetDiscoverData(payload).then(
      (res) => {
        if (res.status === 200) {
          const resData = res?.data && res?.data?.responsemodel;
          const count = res?.data && res?.data?.learningResourceCount;
          window.sessionStorage.setItem(
            "ToolsCount",
            count?.tools ? count?.tools : 0
          );
          const filterResumeBuilderData = resData?.filter(
            (x) => x.key === "Tools.SmartResumeEditor"
          );
          setResumeBuilderData(filterResumeBuilderData);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Get resume builder url
  const showResumeBuilderUrl = async () => {
    await DashboardActions.GetResmeBuilderUrl().then(
      (res) => {
        if (res.status === 200) {
          const resData = res.data;
          setResumeBuilderToolUrl(resData);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Save reusem builder url
  const saveResumeBuilderData = async () => {
    let payload = {
      toolId: resumeBuilderData && resumeBuilderData[0]?.resourceId,
      resourceType: "tools",
      redirectUrl: resumeBuilderToolUrl && resumeBuilderToolUrl,
    };
    await DashboardActions.SaveResmeBuilderUrl(payload).then(
      (res) => {
        if (res.status === 200) {
          const resData = res.data;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Fetch excecutive cms first view data
  const fetchExcecutiveCmsFirstViewData = () => {
    let refexcecutiveCMSData = JSON.parse(JSON.stringify(excecutiveCMSData));
    let userInfo = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    let refThoughtLeadershiptCMS = JSON.parse(
      JSON.stringify(ThoughtLeadershiptCMS)
    );
    let refJobMatchCMS = JSON.parse(JSON.stringify(jobMatchCMS));
    let refJobMatchRVCMS = JSON.parse(JSON.stringify(jobMatchRVCMS));
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let refLearningCollectionCMS = JSON.parse(
      JSON.stringify(learningCollectionCMS)
    );
    let refMoreCollectionCMS = JSON.parse(JSON.stringify(moreCollectionCMS));
    let refDashboardSkillsPortalCard = JSON.parse(
      JSON.stringify(dashboardSkillsPortalCard)
    );

    let payload = {
      PageName: "DashboardFirstVisit",
    };

    DashboardActions.GetCMSWidgetData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          let resLabelData = res.data.Pages[0];
          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refexcecutiveCMSData.commonComponent =
                x.Components["CommonComponent"];
              refexcecutiveCMSData.title =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.Title;
              refexcecutiveCMSData.description =
                x.Components[
                  "DashboardPage.DashboardCoachingBlock"
                ]?.Description;
              refexcecutiveCMSData.labels =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.Labels;
              if (x.Components["DashboardPage.DashboardGettingStarted"]) {
                setDashboardWidgetEnable(true);
              }
              refexcecutiveCMSData.programVideoLabel =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;

              refexcecutiveCMSData.videoImageList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;

              refexcecutiveCMSData.executivePageTemplate =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;

              refexcecutiveCMSData.imageList =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.MediaData;

              refexcecutiveCMSData.excecutiveVideoTitle =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Title;
              refexcecutiveCMSData.excecutiveVideoDescription =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.Description;
              refexcecutiveCMSData.excecutiveVideoImageUrl =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;
              refexcecutiveCMSData.excecutiveVideoUrl =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.VideoList;
              refexcecutiveCMSData.excecutiveLabel =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;

              refexcecutiveCMSData.bannerTitle =
                userInfo?.preferredName !== ""
                  ? x.Title.replace("#NAME", userInfo?.preferredName)
                  : x.Title.replace("#NAME", userInfo?.firstName);

              refexcecutiveCMSData.myCalanderTitle =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Title;
              refexcecutiveCMSData.myCalanderDescription =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Description;
              refexcecutiveCMSData.myCalanderLabel =
                x.Components["DashboardPage.DashboardMyCalendar"];
              refexcecutiveCMSData.coachingSessions =
                x.Components["DashboardPage.DashboardMyCalendar"];

              refexcecutiveCMSData.jobSeekeerPageTemplateImage =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;

              refThoughtLeadershiptCMS.title =
                x.Components["DashboardPage.DashboardThoughtLeadership"]?.Title;
              refThoughtLeadershiptCMS.description =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.Description;
              refThoughtLeadershiptCMS.labels =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.Labels;
              refThoughtLeadershiptCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.ImageList;
            }
          });
          setExcecutiveCMSData(refexcecutiveCMSData);
          setThoughtLeadershiptCMS(refThoughtLeadershiptCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              let lcList =
                x.Components["DashboardPage.DashboardLearningCollections"];
              refLearningCollectionCMS.title = lcList?.Title;
              refLearningCollectionCMS.description = lcList?.Description;
              refLearningCollectionCMS.pageContent = lcList?.Pages;
            }
          });
          setLearningCollectionCMS(refLearningCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              let mcList =
                x.Components["DashboardPage.DashboardMoreCollections"];
              refMoreCollectionCMS.title = mcList?.Title;
              refMoreCollectionCMS.description = mcList?.Description;
              refMoreCollectionCMS.pageContent = mcList?.Pages;
            }
          });
          setMoreCollectionCMS(refMoreCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refJobMatchCMS.pageContent =
                x.Components["DashboardPage.DashboardJobMatchSetup"];
              refJobMatchCMS.title =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Title;
              refJobMatchCMS.description =
                x.Components[
                  "DashboardPage.DashboardJobMatchSetup"
                ]?.Description;
              refJobMatchCMS.labels =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Labels;
              refJobMatchCMS.jobSetupImageList =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.MediaData;
            }
          });
          setJobMatchCMS(refJobMatchCMS);

          let label = [];
          resLabelData?.Components?.["Events.EventsCourseCard"].LOVList.map(
            (Lovs) => {
              Lovs?.Values?.map((x) => {
                let obj = {
                  Key: x.ID,
                  Phrase: x.Value,
                };
                label.push(obj);
              });
            }
          );

          label = [
            ...label,
            ...resLabelData?.Components?.["Events.EventsCourseCard"].Labels,
          ];
          setCommonImage(
            resLabelData?.Components?.["Events.EventsCourseCard"].MediaData
          );
          refCmsSA["masterLabel"] = label;
          setCmsSA(refCmsSA);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refDashboardSkillsPortalCard.title =
                x.Components["DashboardPage.DashboardSkillsPortalCard"].Title;
              refDashboardSkillsPortalCard.description =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ].Description;
              refDashboardSkillsPortalCard.image =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ].MediaData.LargeImage;
              refDashboardSkillsPortalCard.labels =
                x.Components["DashboardPage.DashboardSkillsPortalCard"].Labels;
            }
          });
          setDashboardSkillsPortalCard(refDashboardSkillsPortalCard);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // fetch executive return view data
  const fetchExcecutiveCmsReturnViewData = () => {
    let refexcecutiveCMSData = JSON.parse(JSON.stringify(excecutiveCMSData));
    let userInfo = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    let refThoughtLeadershiptCMS = JSON.parse(
      JSON.stringify(ThoughtLeadershiptCMS)
    );
    let refJobMatchCMS = JSON.parse(JSON.stringify(jobMatchCMS));
    let refJobMatchRVCMS = JSON.parse(JSON.stringify(jobMatchRVCMS));
    let refDocumentCMS = JSON.parse(JSON.stringify(documentCMS));
    let refDocumentRVCMS = JSON.parse(JSON.stringify(documentRVCMS));
    let refGreetingCMS = JSON.parse(JSON.stringify(greetingCMS));
    let refCmsSA = JSON.parse(JSON.stringify(cmsSA));
    let refLearningCollectionCMS = JSON.parse(
      JSON.stringify(learningCollectionCMS)
    );
    let refMoreCollectionCMS = JSON.parse(JSON.stringify(moreCollectionCMS));
    let refDashboardSkillsPortalCard = JSON.parse(
      JSON.stringify(dashboardSkillsPortalCard)
    );

    let payload = {
      PageName: "DashboardReturnVisit",
    };

    DashboardActions.GetCMSWidgetData(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          let resLabelData = res.data.Pages[0];
          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refexcecutiveCMSData.commonComponent =
                x.Components["CommonComponent"];
              refexcecutiveCMSData.title =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.Title;
              refexcecutiveCMSData.description =
                x.Components[
                  "DashboardPage.DashboardCoachingBlock"
                ]?.Description;
              refexcecutiveCMSData.labels =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.Labels;

              refexcecutiveCMSData.programVideoLabel =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;

              refexcecutiveCMSData.videoImageList =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;

              refexcecutiveCMSData.executivePageTemplate =
                x.Components["DashboardPage.DashboardPageTemplate"]?.Labels;

              refexcecutiveCMSData.jobSeekeerPageTemplateImage =
                x.Components["DashboardPage.DashboardPageTemplate"]?.MediaData;

              refexcecutiveCMSData.imageList =
                x.Components["DashboardPage.DashboardCoachingBlock"]?.MediaData;
              if (x.Components["DashboardPage.DashboardGettingStarted"]) {
                setDashboardWidgetEnable(true);
              }
              refexcecutiveCMSData.excecutiveVideoTitle =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Title;
              refexcecutiveCMSData.excecutiveVideoDescription =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.Description;
              refexcecutiveCMSData.excecutiveVideoImageUrl =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.MediaData;
              refexcecutiveCMSData.excecutiveVideoUrl =
                x.Components[
                  "DashboardPage.DashboardGettingStarted"
                ]?.VideoList;
              refexcecutiveCMSData.excecutiveLabel =
                x.Components["DashboardPage.DashboardGettingStarted"]?.Labels;

              refexcecutiveCMSData.bannerTitle =
                refexcecutiveCMSData.bannerTitle =
                  userInfo?.preferredName !== ""
                    ? x.Title.replace("#NAME", userInfo?.preferredName)
                    : x.Title.replace("#NAME", userInfo?.firstName);

              refexcecutiveCMSData.myCalanderTitle =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Title;
              refexcecutiveCMSData.myCalanderDescription =
                x.Components["DashboardPage.DashboardMyCalendar"]?.Description;

              refexcecutiveCMSData.cancelMeeting_Labels =
                x.Components["MyCoachPage.MyCoachCancelMeeting"]?.Labels;

              refexcecutiveCMSData.myCalanderLabel =
                x.Components["DashboardPage.DashboardMyCalendar"];
              refexcecutiveCMSData.coachingSessions =
                x.Components["MyCoachPage.MyCoachCoachingSessions"];

              refThoughtLeadershiptCMS.title =
                x.Components["DashboardPage.DashboardThoughtLeadership"]?.Title;
              refThoughtLeadershiptCMS.description =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.Description;
              refThoughtLeadershiptCMS.labels =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.Labels;
              refThoughtLeadershiptCMS.imageList =
                x.Components[
                  "DashboardPage.DashboardThoughtLeadership"
                ]?.ImageList;
            }
          });
          setExcecutiveCMSData(refexcecutiveCMSData);
          setThoughtLeadershiptCMS(refThoughtLeadershiptCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPage") {
              refJobMatchCMS.pageContent =
                x.Components["DashboardPage.DashboardJobMatchSetup"];
              refJobMatchCMS.title =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Title;
              refJobMatchCMS.description =
                x.Components[
                  "DashboardPage.DashboardJobMatchSetup"
                ]?.Description;
              refJobMatchCMS.labels =
                x.Components["DashboardPage.DashboardJobMatchSetup"]?.Labels;
            }
          });
          setJobMatchCMS(refJobMatchCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refDocumentRVCMS.pageContent =
                x.Components["MyDocumentsPage.MyDocuments"];
              refDocumentRVCMS.title =
                x.Components["MyDocumentsPage.MyDocuments"]?.Title;
              refDocumentRVCMS.description =
                x.Components["MyDocumentsPage.MyDocuments"]?.Description;
              refDocumentRVCMS.labels =
                x.Components["MyDocumentsPage.MyDocuments"]?.Labels;
              refDocumentRVCMS.richText =
                x.Components["MyDocumentsPage.MyDocuments"]?.RichTexts;
            }
          });
          setDocumentRVCMS(refDocumentRVCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refGreetingCMS.pageContent =
                x.Components["JobSearch.JobGreetings"];
              refGreetingCMS.title =
                x.Components["JobSearch.JobGreetings"]?.Title;
              refGreetingCMS.description =
                x.Components["JobSearch.JobGreetings"]?.Description;
              refGreetingCMS.labels =
                x.Components["JobSearch.JobGreetings"]?.Labels;
            }
          });
          setGreetingCMS(refGreetingCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              let lcList =
                x.Components["DashboardPage.DashboardLearningCollections"];
              refLearningCollectionCMS.title = lcList?.Title;
              refLearningCollectionCMS.description = lcList?.Description;
              refLearningCollectionCMS.pageContent = lcList?.Pages;
            }
          });
          setLearningCollectionCMS(refLearningCollectionCMS);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              let mcList =
                x.Components["DashboardPage.DashboardMoreCollections"];
              refMoreCollectionCMS.title = mcList?.Title;
              refMoreCollectionCMS.description = mcList?.Description;
              refMoreCollectionCMS.pageContent = mcList?.Pages;
            }
          });
          setMoreCollectionCMS(refMoreCollectionCMS);

          let label = [];
          resLabelData?.Components?.["Events.EventsCourseCard"]?.LOVList?.map(
            (Lovs) => {
              Lovs?.Values?.map((x) => {
                let obj = {
                  Key: x.ID,
                  Phrase: x.Value,
                };
                label.push(obj);
              });
            }
          );

          label = [
            ...label,
            ...resLabelData?.Components?.["Events.EventsCourseCard"]?.Labels,
          ];
          setCommonImage(
            resLabelData?.Components?.["Events.EventsCourseCard"]?.MediaData
          );
          refCmsSA["masterLabel"] = label;
          setCmsSA(refCmsSA);

          resData.Pages.filter((x) => {
            if (x.Key === "DashboardPageReturnVisit") {
              refDashboardSkillsPortalCard.title =
                x.Components["DashboardPage.DashboardSkillsPortalCard"].Title;
              refDashboardSkillsPortalCard.description =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ].Description;
              refDashboardSkillsPortalCard.image =
                x.Components[
                  "DashboardPage.DashboardSkillsPortalCard"
                ].MediaData.LargeImage;
              refDashboardSkillsPortalCard.labels =
                x.Components["DashboardPage.DashboardSkillsPortalCard"].Labels;
            }
          });
          setDashboardSkillsPortalCard(refDashboardSkillsPortalCard);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchExcecutiveLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsSA));
    let label = "";
    if (Object.keys(refCmsContent).length !== 0) {
      refCmsContent?.masterLabel?.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const saveAssessmentCard = (
    e,
    id,
    savedStatus,
    likedStatus,
    type,
    learningResourceStatus,
    pageNumber,
    resourceType,
    resourceCategoryType
  ) => {
    e.preventDefault();
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      candidateId: candidateID,
      learningResourceId: id,
      lastAnsweredPageId: pageNumber,
      learningResourceType: resourceCategoryType,
      resourceTypeId: resourceType,
      learningResourceStatus: learningResourceStatus,
      isSaved:
        type === "save" ? (savedStatus == true ? false : true) : savedStatus,
      isLiked:
        type === "like" ? (likedStatus == true ? false : true) : likedStatus,
    };
    DashboardActions.SaveAssessmentCard(resourceCategoryType, id, payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          fetchRecommndedAssessmentData();
          fetchThoughtLeadershiptData();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const saveActivity2 = (type) => {
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: type?.learningResourceId,
      value: type?.title,
    };
    type?.isLiked === false
      ? (payload["type"] =
          type?.resourceCategoryType?.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentLike"
            : type?.resourceCategoryType?.id.toLowerCase() === "tools"
            ? "ToolLike"
            : `${type?.resourceType?.id + "Like"}`)
      : (payload["type"] =
          type?.resourceCategoryType?.id.toLowerCase() === "assessmentsandtasks"
            ? "AssessmentUnLike"
            : type?.resourceCategoryType?.id.toLowerCase() === "tools"
            ? "ToolUnLike"
            : `${type?.resourceType?.id + "UnLike"}`);
    AssessmentActions.CreateActivity(payload).then(
      (res) => {},
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchLearningStatus = (id) => {
    let refCmsAssessmentContent =
      assessmentCardData && assessmentCardData[0]?.components?.commonComponent;
    let label = "";
    if (refCmsAssessmentContent?.LOVList[0]?.Values) {
      refCmsAssessmentContent?.LOVList[0]?.Values.map((x) => {
        if (x.ID === id) {
          label = x.Value;
        }
      });
    }
    return label;
  };

  const fetchJobMatchlabels = (key) => {
    let refCmsContent = jobMatchCMS && jobMatchCMS;
    let label = "";
    if (refCmsContent?.labels) {
      refCmsContent?.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  const getMyCoach = async () => {
    await DashboardActions.GetMyCoach()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            setMycoachData(res.data && res.data);
          }
          setMyCoachStatusCheck(res.status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get upcoming meetings
  const getUpcomingMeetings = async () => {
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let endYearDate = new Date().getFullYear() + 1;
    let year = date.toLocaleString("default", { year: "numeric" });
    let month = date.toLocaleString("default", { month: "2-digit" });
    let day = date.toLocaleString("default", { day: "2-digit" });
    // let lastDate = lastDay.toLocaleString("default", { day: "2-digit" });

    let nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);

    let lastDayofNextYear = new Date(
      nextYear.getFullYear(),
      nextYear.getMonth() + 1,
      0
    );
    let lastDateofNextYear = lastDayofNextYear.toLocaleString("default", {
      day: "2-digit",
    });

    let startDate = year + "-" + month + "-" + day;
    // let endDate = endYearDate + "-" + month + "-" + lastDate;
    let endDate =
      nextYear.getFullYear() + "-" + month + "-" + lastDateofNextYear;
    // nextYear.getFullYear()
    let calEventpayload = {
      startDate: startDate?.replace(/[^0-9-]/g, ""),
      endDate: endDate?.replace(/[^0-9-]/g, ""),
    };

    await CandidateCalenderActions.GetCalenderEvents(calEventpayload)
      .then((res) => {
        setUpcomingMeetings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // pending invites
  const getMeetingInvites = async () => {
    let payload = {
      Status: "PendingInvites",
    };
    await DashboardActions.GetCoachingSessions(payload)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.length > 0) {
            setMeetingInvites(res.data);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get event
  const getEventStatus = (eventDetails) => {
    let statusLabel = "";
    if (eventDetails.user_enrolled) {
      if (eventDetails.status === "Completed") {
        statusLabel = fetchLabel("completed");
      } else {
        statusLabel = fetchLabel("enrolled");
      }
    }
    return statusLabel;
  };

  // open progrm video model
  const fnOpenCloseProgramVideo = (nflag) => {
    if (nflag === 0) {
      setProgramVideo(true);
    } else {
      setProgramVideo(false);
      TrackActions.TimeRecord(
        times,
        { EventType: "ViewProgramVideo" },
        appInsights
      );
    }
  };

  const fnOpenCloseProgramVideoRewatch = (nflag) => {
    if (nflag === 0) {
      setProgramVideoRewatch(true);
    } else {
      setProgramVideoRewatch(false);
      TrackActions.TimeRecord(
        times,
        { EventType: "ViewProgramVideo" },
        appInsights
      );
    }
  };

  // Get program video detail
  const getProgramVideoData = async (resumeStatus) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    await DashboardActions.GetProgramVideo()
      .then((res) => {
        if (res?.status === 200) {
          const programFilterData =
            res &&
            res?.data &&
            res?.data?.filter(
              (isdashboard) =>
                isdashboard?.isFromDashboard === true &&
                candidateID === isdashboard?.candidateId
            );
          setProgramVideoData(programFilterData);
          setVideoStatus(true);
          if (fetchPersona) {
            if (
              (programFilterData && programFilterData?.length == 0) ||
              resumeStatus == "resumeNotUploaded"
            ) {
              setFirstView(true);
            } else if (
              (programFilterData && programFilterData?.length > 0) ||
              resumeStatus == "resumeUploaded"
            ) {
              setFirstView(false);
            }
          }
        } else {
          setProgramVideoData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Update program detail
  const programVideoHandler = async () => {
    StartTime = new Date();
    setTimes(StartTime);
    TrackActions.EventTrack(appInsights, "ViewProgramVideo");
    TrackActions.PageAction("ViewProgramVideo", property);
    const getCurrentDateTime = new Date().toISOString();
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let payload = {
      id: programVideoData && programVideoData[0]?.id,
      candidateId: candidateID,
      videoId: "",
      videoThumbnailURL:
        excecutiveCMSData &&
        excecutiveCMSData?.excecutiveVideoUrl &&
        excecutiveCMSData?.excecutiveVideoUrl[0] &&
        excecutiveCMSData?.excecutiveVideoUrl[0].ComponentMediaUrl,
      lastPlayTime: getCurrentDateTime,
      closedCaptionText: "",
      isFromDashboard: true,
    };
    await DashboardActions.UpdateProgramVideo(payload)
      .then((res) => {
        if (res.status === 200) {
          getProgramVideoData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const programVideoHandlerIndependent = async () => {
    StartTime = new Date();
    setTimes(StartTime);
    TrackActions.EventTrack(appInsights, "ViewProgramVideo");
    TrackActions.PageAction("ViewProgramVideo", property);
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    const getCurrentDateTime = new Date().toISOString();
    let payload = {
      id: programVideoData && programVideoData[0]?.id,
      candidateId: candidateID,
      videoId: "",
      videoThumbnailURL:
        DashboardWidgetDataCMS &&
        DashboardWidgetDataCMS?.videoList &&
        DashboardWidgetDataCMS?.videoList[0] &&
        DashboardWidgetDataCMS?.videoList[0].ComponentMediaUrl,
      lastPlayTime: getCurrentDateTime,
      closedCaptionText: "",
      isFromDashboard: true,
    };
    await DashboardActions.UpdateProgramVideo(payload)
      .then((res) => {
        if (res.status === 200) {
          getProgramVideoData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get recommnded events
  const fetchEvents = async () => {
    let payload = {
      cource_type: "classroom",
    };
    await DashboardActions.GetEvents(payload).then(
      (res) => {
        if (res.status === 200) {
          setRecommendedEvents(
            res.data?.filter((allEvents) => allEvents.is_recommended)
          );
          setMyEvents(res.data?.filter((allEvents) => allEvents.user_enrolled));
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  // Update event
  const updateEvents = async (eventDetails) => {
    let id = eventDetails.event_id
      ? eventDetails.event_id
      : eventDetails.Id
      ? eventDetails.Id
      : "";
    let payload = {
      ResourceType: "External",
      EventType: eventDetails.event_type
        ? eventDetails.event_type
        : "classroom",
      IsSaved: !eventDetails.isSaved,
      Status: eventDetails.status,
      EventName: eventDetails?.title || eventDetails?.event_name,
      IsRegistered: eventDetails?.isRegistered,
      RegisteredTimeStamp: eventDetails?.RegisteredTimeStamp,
      EventDate: eventDetails?.eventDate,
      STARTTIME: "00:00",
      ENDTIME: "00:00",
      StartDate: eventDetails?.StartDate,
      EndDate: eventDetails?.EndDate,
      ResourceId: eventDetails?.event_id,
    };

    await DashboardActions.UpdateEvent(payload, id).then(
      (res) => {
        if (res.status === 200) {
          fetchEvents();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Get event tag and desceiption
  const getTags = (event, isOnDemad, isSearch) => {
    let programTags = [];
    let recommended = false;
    let tagNames = [];
    if (isOnDemad) {
      programTags = event.focusArea?.map((tag) => tag.id);
      recommended = event.isRecommended;
    } else {
      programTags = event.program_category?.replace(/ /g, "").match(/.{1,3}/g);
      recommended = event.is_recommended;
    }

    tagNames =
      programTags && programTags.map((PCcode) => fetchExcecutiveLabel(PCcode));
    if (recommended) {
      if (tagNames) {
        tagNames.push(
          fetchExcecutiveLabel("Events.EventsCourseCard.Recommended")
        );
      } else {
        tagNames = [
          fetchExcecutiveLabel("Events.EventsCourseCard.Recommended"),
        ];
      }
    }

    event.tags = tagNames ? tagNames : [];
    eventsWithTags.push(event);
    return tagNames;
  };

  // scroll to the discover
  const redirectToResource = () => {
    context.setFocusToResource(true);
    navigate("/discover");
  };

  const getDescription = (desc) => {
    return <Cirrus.RichText content={desc} />;
  };

  const fetchDBhoverURL = () => {
    let date = new Date();
    let param1 = Math.floor(Math.random() * 10).toString();
    let param2 = (
      date.getDate()?.toString()?.length < 2
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    )
      .substring(1, 2)
      .toString();
    let param3 = (
      (date.getMonth() + 1)?.toString()?.length < 2
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString()
    )
      .substring(0, 1)
      .toString();
    let param4 = (
      date.getFullYear()?.toString()?.length < 2
        ? date.getFullYear().toString()
        : date.getFullYear().toString()
    )
      .substring(3, 4)
      .toString();
    let param5 = Math.floor(Math.random() * 10).toString();
    let param6 = Math.floor(Math.random() * 10).toString();
    let param7 = (
      (date.getMonth() + 1)?.toString()?.length < 2
        ? "0" + (date.getMonth() + 1).toString()
        : (date.getMonth() + 1).toString()
    )
      .substring(1, 2)
      .toString();
    let param8 = (
      date.getDate()?.toString()?.length < 2
        ? "0" + date.getDate().toString()
        : date.getDate().toString()
    )
      .substring(0, 1)
      .toString();
    let param9 = Math.floor(Math.random() * 10).toString();

    let st =
      param1 +
      param2 +
      param3 +
      param4 +
      param5 +
      param6 +
      param7 +
      param8 +
      param9;
    let userInfo = JSON.parse(
      window.sessionStorage.getItem("setProfileDetails")
    );
    let programCountry = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    let URL = `http://signon.oneSource.com?LogonUserID=${userInfo.emailAddress}&ST=${st}&AT=917360db07a6e0cdbbde3ff088b0e70d&Fname=${userInfo.firstName}&Lname=${userInfo.lastName}&Country=${programCountry.programCountryCode}`;
    window.open(URL);
  };

  const fetchLesEchosURL = async (id) => {
    await DashboardActions.ExternalToolsRedirectURL(id).then(
      (res) => {
        if (res.status === 302) {
          window.open(res.data);
        } else {
          console.log("api not succeeded");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const cardRedirectHandler = (item) => {
    if (
      item?.resourceType?.id === "article" ||
      item?.resourceType?.id === "video" ||
      item?.resourceType?.id === "document" ||
      item?.resourceType?.id === "E3"
    ) {
      window.open(`#/article-page/${item?.resourceId}`, "_self");
    } else {
      if (item?.key === "Tools.DnBHover") {
        fetchDBhoverURL();
      } else if (item?.key === "Tools.SmartResumeEditor") {
        window.open(resumeBuilderToolUrl);
      } else if (item?.key === "Tools.OnDemandVideoInterviews") {
        window.open(`#/iview-welcome`, "_self");
      } else if (item?.key === "Tools.30SecondCommercial") {
        window.open(
          `#/iview-interview-start/${item?.resourceId}/${item?.externalId}`,
          "_self"
        );
      } else if (item?.key === "Tools.Cobra") {
        window.open("https://rightmanagement.cobwebinfo.com/");
      } else if (item?.key === "Tools.FirstEco") {
        window.open("https://www.right.firsteco.fr/");
      } else if (
        item?.key === "Tools.LesEchos" ||
        item?.key === "Tools.Diane" ||
        item?.key === "Tools.Corporama" ||
        item?.key === "Tools.Executives"
      ) {
        fetchLesEchosURL(item?.resourceId);
      } else if (item?.key === "Tools.AbintegroCareerCentre") {
        window.open(process.env.REACT_APP_ABINTEGRO_TOOL_URL);
      } else {
        navigate(item?.resourceUrl?.url);
      }
    }
  };

  // Get resume builder url
  const getResumeBuilderUrl = async () => {
    await DashboardActions.GetResmeBuilderUrl().then(
      (res) => {
        if (res.status === 200) {
          const resData = res.data;
          setResumeBuilderToolUrl(resData?.url);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  // Get candidate last login
  const fetchCandidateLastLogin = async () => {
    await DashboardActions.GetLastSession().then(
      (res) => {
        if (res.status === 200) {
          setCandidateLastLoginData(res?.data?.loginDate);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const currentDate = new Date().toLocaleString().slice(0, 8);
  const lastLogin = new Date(candidateLastLoginData)
    .toLocaleString()
    .slice(0, 8);

  const getNumberOfDays = (start, end) => {
    const date1 = new Date(start);
    const date2 = new Date(end);
    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;
    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();
    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays;
  };

  const diffInDays = getNumberOfDays(lastLogin, currentDate);
  const getLastLoginData = diffInDays > 6;

  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  function getFeaturesConfig(featureName) {
    let val;
    featuresConfig?.features?.map((feature) => {
      if (feature.featureName === featureName) {
        val = feature.isActive;
      }
    });
    return val;
  }

  let executivePersonaName = fetchExecutivePageTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  let displayName =
    fetchPersona && fetchPersona.preferredName
      ? fetchPersona.preferredName
      : fetchPersona.firstName;
  if (executivePersonaName) {
    executivePersonaName = executivePersonaName.replace("#NAME", displayName);
  }

  let executivePersonaReturnViewName = fetchExecutivePageTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeBackMessage"
  );
  if (executivePersonaReturnViewName) {
    executivePersonaReturnViewName = executivePersonaReturnViewName.replace(
      "#NAME",
      displayName
    );
  }

  let jobSeekerPersonaReturnViewName = fetchJobSeekerReturnPageTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeBackMessage"
  );
  if (jobSeekerPersonaReturnViewName) {
    jobSeekerPersonaReturnViewName = jobSeekerPersonaReturnViewName.replace(
      "#NAME",
      displayName
    );
  }

  let jobSeekerPersonaViewName = fetchJobSeekerPageTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  if (jobSeekerPersonaViewName) {
    jobSeekerPersonaViewName = jobSeekerPersonaViewName.replace(
      "#NAME",
      displayName
    );
  }

  let independentPersonaName = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  if (independentPersonaName) {
    independentPersonaName = independentPersonaName.replace(
      "#NAME",
      displayName
    );
  }

  let professionalPersonaName = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  if (professionalPersonaName) {
    professionalPersonaName = professionalPersonaName.replace(
      "#NAME",
      displayName
    );
  }

  let independentPersonaNameReturn = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeBackMessage"
  );
  if (independentPersonaNameReturn) {
    independentPersonaNameReturn = independentPersonaNameReturn.replace(
      "#NAME",
      displayName
    );
  }

  let professionalPersonaNameReturn = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeBackMessage"
  );
  if (professionalPersonaNameReturn) {
    professionalPersonaNameReturn = professionalPersonaNameReturn.replace(
      "#NAME",
      displayName
    );
  }

  let candidateWelcomeMessage = fetchExecutivePageTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeBackMessage"
  );
  if (candidateWelcomeMessage) {
    candidateWelcomeMessage = candidateWelcomeMessage.replace(
      "#NAME",
      displayName
    );
  }

  let entrepreneurPersonaName = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  if (entrepreneurPersonaName) {
    entrepreneurPersonaName = entrepreneurPersonaName.replace(
      "#NAME",
      displayName
    );
  }
  let entrepreneurPersonaNameReturn = fetchDashboardTemplateLabel(
    "DashboardPage.DashboardPageTemplate.WelcomeMessage"
  );
  if (entrepreneurPersonaNameReturn) {
    entrepreneurPersonaNameReturn = entrepreneurPersonaNameReturn.replace(
      "#NAME",
      displayName
    );
  }

  // Save activity

  const saveActivity = (id, type, title) => {
    TrackActions.EventTrack(appInsights, "ViewCard", {
      "Entity Id": id,
      "Entity Name": title,
      CardType: type,
    });
    TrackActions.PageAction("ViewCard", {
      "Entity Id": id,
      "Entity Name": title,
      CardType: type,
    });
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: id,
      type: type,
      value: title,
    };
    AssessmentActions.CreateActivity(payload).then(
      (res) => {
        if (res.status === 200) {
          AssessmentActions.GetCreatedActivity().then(
            (res) => {},
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const getdoceboLink = async () => {
    setShowDecebo(getFeaturesConfig("DoceboTile"));
    if (getFeaturesConfig("DoceboTile")) {
      let res = await DashboardActions.GetDoceboLink();
      setDoceboURL(res.data);
    }
  };

  if (screenLoader) {
    return (
      <div>
        <Spinner spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <div className="dashboard_page">
      {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
        <>
          {programVideoData &&
            programVideoData?.length == 0 &&
            !isResumeUploaded && (
              // <Cirrus.JobMatchPageBanner
              //   subheading={getLastLoginData ? candidateWelcomeMessage : jobSeekerPersonaViewName}
              //   heading={cmsContent?.DashboardIViewTitle}
              //   maskImageSource={cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
              //   type='xl'
              // />
              <Cirrus.PageBanner
                subheading={
                  getLastLoginData
                    ? candidateWelcomeMessage
                    : jobSeekerPersonaViewName
                }
                heading={cmsContent?.DashboardIViewTitle}
                maskImageSource={
                  cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl
                }
                type="xl"
                className="dashboardBanner job-seek-1"
              />
            )}
        </>
      )}

      {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
        <>
          {((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            // <Cirrus.JobMatchPageBanner
            //   subheading={getLastLoginData ? candidateWelcomeMessage : jobSeekerPersonaReturnViewName}
            //   heading={cmsContent?.DashboardIViewTitle}
            //   maskImageSource={cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
            //   type="xl"
            // />
            <Cirrus.PageBanner
              subheading={
                getLastLoginData
                  ? candidateWelcomeMessage
                  : jobSeekerPersonaReturnViewName
              }
              heading={cmsContent?.DashboardIViewTitle}
              maskImageSource={
                cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl
              }
              type="xl"
              className="dashboardBanner job-seek-2"
            />
          )}
        </>
      )}

      {fetchPersona && fetchPersona?.personaName == "Executive" && (
        <>
          {programVideoData &&
            programVideoData?.length == 0 &&
            !isResumeUploaded && (
              // <Cirrus.JobMatchPageBanner
              //   subheading={getLastLoginData ? candidateWelcomeMessage : executivePersonaName}
              //   bodyText={excecutiveCMSData?.bannerDescription}
              //   heading={excecutiveCMSData?.bannerTitle}
              //   maskImageSource={excecutiveCMSData?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
              //   type="xl"
              // />
              <Cirrus.PageBanner
                subheading={
                  getLastLoginData
                    ? candidateWelcomeMessage
                    : executivePersonaName
                }
                bodyText={excecutiveCMSData?.bannerDescription}
                heading={excecutiveCMSData?.bannerTitle}
                maskImageSource={
                  excecutiveCMSData?.jobSeekeerPageTemplateImage?.LargeImage
                    ?.ImageUrl
                }
                type="xl"
                className="dashboardBanner exec-1"
              />
            )}
        </>
      )}

      {fetchPersona && fetchPersona?.personaName == "Executive" && (
        <>
          {
            // (programVideoData && programVideoData?.length > 0 || isResumeUploaded) &&
            programVideoData && programVideoData?.length > 0 && (
              // <Cirrus.JobMatchPageBanner
              //   subheading={getLastLoginData ? candidateWelcomeMessage : executivePersonaReturnViewName}
              //   bodyText={excecutiveCMSData?.bannerDescription}
              //   heading={excecutiveCMSData?.bannerTitle}
              //   maskImageSource={excecutiveCMSData.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
              //   type="xl"
              //   button={{
              //     children: fetchExecutiveLabel(
              //       "DashboardPage.DashboardGettingStarted.RewatchProgrammeVideoButton"
              //     ),
              //     onClick: (e) => fnOpenCloseProgramVideo(0),
              //   }}
              // />
              <Cirrus.PageBanner
                subheading={
                  getLastLoginData
                    ? candidateWelcomeMessage
                    : executivePersonaReturnViewName
                }
                bodyText={excecutiveCMSData?.bannerDescription}
                heading={excecutiveCMSData?.bannerTitle}
                maskImageSource={
                  excecutiveCMSData.jobSeekeerPageTemplateImage?.LargeImage
                    ?.ImageUrl
                }
                type="xl"
                button={{
                  children: fetchExecutiveLabel(
                    "DashboardPage.DashboardGettingStarted.RewatchProgrammeVideoButton"
                  ),
                  onClick: (e) => fnOpenCloseProgramVideo(0),
                }}
                className="dashboardBanner exec-2"
              />
            )
          }
        </>
      )}
      {fetchPersona?.personaName == "Independent" &&
        programVideoData &&
        programVideoData?.length == 0 &&
        !isResumeUploaded && (
          // <Cirrus.JobMatchPageBanner
          //   subheading={getLastLoginData ? candidateWelcomeMessage : independentPersonaName}
          //   heading={cmsContent?.DashboardIViewTitle}
          //   maskImageSource={DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl}
          //   type="xl"
          // />
          <Cirrus.PageBanner
            subheading={
              getLastLoginData
                ? candidateWelcomeMessage
                : independentPersonaName
            }
            heading={cmsContent?.DashboardIViewTitle}
            maskImageSource={
              DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl
            }
            type="xl"
            className="dashboardBanner indep-1"
          />
        )}

      {fetchPersona && fetchPersona?.personaName == "Independent" && (
        <>
          {((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            // <Cirrus.JobMatchPageBanner
            //   subheading={getLastLoginData ? candidateWelcomeMessage : jobSeekerPersonaReturnViewName}
            //   heading={cmsContent?.DashboardIViewTitle}
            //   maskImageSource={cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
            //   type="xl"
            // />
            <Cirrus.PageBanner
              subheading={
                getLastLoginData
                  ? candidateWelcomeMessage
                  : jobSeekerPersonaReturnViewName
              }
              heading={cmsContent?.DashboardIViewTitle}
              maskImageSource={
                cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl
              }
              type="xl"
              className="dashboardBanner indep-2"
            />
          )}
        </>
      )}

      {/* rewatch video Independent */}

      {fetchPersona && fetchPersona?.personaName == "Independent" && (
        <>
          {programVideoRewatch && (
            <Cirrus.VideoLightbox
              closeButtonText="Close lightbox"
              controls
              //description={DashboardWidgetDataCMS?.description}
              // mp4Src={
              //   DashboardWidgetDataCMS &&
              //   DashboardWidgetDataCMS?.videoList &&
              //   DashboardWidgetDataCMS?.videoList[0] &&
              //   DashboardWidgetDataCMS?.videoList[0].ComponentMediaUrl
              // }
              mp4Src={videoURL?.MediaUrl}
              onClose={() => {
                fnOpenCloseProgramVideoRewatch(1);
              }}
              onPlay={() => programVideoHandler()}
              playButtonText="Play video"
              // posterImageUrl={DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl}
              posterImageUrl={videoURL?.VideoThumbnail}
              videoTitle="Sample video"
              subtitle={videoURL?.VideoSubtitleFile}
            />
          )}
        </>
      )}
      {/* rewatch video Independent*/}

      {fetchPersona?.personaName == "Professional" &&
        programVideoData &&
        programVideoData?.length == 0 &&
        !isResumeUploaded && (
          // <Cirrus.JobMatchPageBanner
          //   subheading={getLastLoginData ? candidateWelcomeMessage : professionalPersonaName}
          //   heading={cmsContent?.DashboardIViewTitle}
          //   maskImageSource={DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl}
          //   type="xl"
          // />
          <Cirrus.PageBanner
            subheading={
              getLastLoginData
                ? candidateWelcomeMessage
                : professionalPersonaName
            }
            heading={cmsContent?.DashboardIViewTitle}
            maskImageSource={
              DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl
            }
            type="xl"
            className="dashboardBanner prof-1"
          />
        )}

      {fetchPersona && fetchPersona?.personaName == "Professional" && (
        <>
          {((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            // <Cirrus.JobMatchPageBanner
            //   subheading={getLastLoginData ? candidateWelcomeMessage : jobSeekerPersonaReturnViewName}
            //   heading={cmsContent?.DashboardIViewTitle}
            //   maskImageSource={cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl}
            //   type="xl"
            // />
            <Cirrus.PageBanner
              subheading={
                getLastLoginData
                  ? candidateWelcomeMessage
                  : jobSeekerPersonaReturnViewName
              }
              heading={cmsContent?.DashboardIViewTitle}
              maskImageSource={
                cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl
              }
              type="xl"
              className="dashboardBanner prof-2"
            />
          )}
        </>
      )}

      {/* rewatch video Professional*/}

      {fetchPersona && fetchPersona?.personaName == "Professional" && (
        <>
          {programVideoRewatch && (
            <Cirrus.VideoLightbox
              closeButtonText="Close lightbox"
              controls
              //description={DashboardWidgetDataCMS?.description}
              // mp4Src={
              //   DashboardWidgetDataCMS &&
              //   DashboardWidgetDataCMS?.videoList &&
              //   DashboardWidgetDataCMS?.videoList[0] &&
              //   DashboardWidgetDataCMS?.videoList[0].ComponentMediaUrl
              // }
              mp4Src={videoURL?.MediaUrl}
              onClose={() => {
                fnOpenCloseProgramVideoRewatch(1);
              }}
              onPlay={() => programVideoHandler()}
              playButtonText="Play video"
              // posterImageUrl={DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl}
              posterImageUrl={videoURL?.VideoThumbnail}
              videoTitle="Sample video"
              subtitle={videoURL?.VideoSubtitleFile}
            />
          )}
        </>
      )}
      {/* rewatch video Professional*/}

      {fetchPersona && fetchPersona?.personaName == "Executive" && (
        <>
          {bProgramVideo && (
            <Cirrus.VideoLightbox
              closeButtonText="Close lightbox"
              controls
              //description=""
              // mp4Src={
              //   excecutiveCMSData &&
              //   excecutiveCMSData?.excecutiveVideoUrl &&
              //   excecutiveCMSData?.excecutiveVideoUrl[0] &&
              //   excecutiveCMSData?.excecutiveVideoUrl[0].ComponentMediaUrl
              // }
              mp4Src={videoURL?.MediaUrl}
              onClose={() => {
                fnOpenCloseProgramVideo(1);
              }}
              onPlay={() => programVideoHandler()}
              playButtonText="Play video"
              // posterImageUrl={DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl}
              posterImageUrl={videoURL?.VideoThumbnail}
              videoTitle="Sample video"
              subtitle={videoURL?.VideoSubtitleFile}
            />
          )}
        </>
      )}

      {((fetchPersona && fetchPersona?.personaName == "Entrepreneur") ||
        fetchPersona?.personaName == "CareerProgram") && (
        <>
          {programVideoData &&
            programVideoData?.length == 0 &&
            !isResumeUploaded && (
              <Cirrus.PageBanner
                subheading={
                  getLastLoginData
                    ? candidateWelcomeMessage
                    : entrepreneurPersonaName
                }
                heading={cmsContent?.DashboardIViewTitle}
                maskImageSource={
                  DashboardWidgetDataCMS?.imageUrl?.LargeImage?.ImageUrl
                }
                type="xl"
                className="dashboardBanner entrepreneur-1"
              />
            )}
        </>
      )}
      {((fetchPersona && fetchPersona?.personaName == "Entrepreneur") ||
        fetchPersona?.personaName == "CareerProgram") && (
        <>
          {programVideoData &&
            programVideoData?.length > 0 &&
            !isResumeUploaded && (
              <Cirrus.PageBanner
                subheading={
                  getLastLoginData
                    ? candidateWelcomeMessage
                    : entrepreneurPersonaNameReturn
                }
                heading={cmsContent?.DashboardIViewTitle}
                maskImageSource={
                  cmsContent?.jobSeekeerPageTemplateImage?.LargeImage?.ImageUrl
                }
                type="xl"
                className="dashboardBanner indep-2"
              />
            )}
        </>
      )}

      <Cirrus.OneColumnLayout type="wrapped" className="dashbaord-page-layout">
        {/* Job seeker first view*/}

        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Job Seeker" && (
            <>
              {programVideoData &&
                programVideoData?.length == 0 &&
                isResumeUploaded == false && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={DashboardWidgetDataCMS?.description}
                      heading={DashboardWidgetDataCMS?.title}
                      imageProps={{
                        src: DashboardWidgetDataCMS?.imageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchDashboardLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      playButton={true}
                    />
                  </div>
                )}
            </>
          )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {bProgramVideo && (
              <Cirrus.VideoLightbox
                closeButtonText="Close lightbox"
                controls
                //description={DashboardWidgetDataCMS?.description}
                // mp4Src={
                //   DashboardWidgetDataCMS &&
                //   DashboardWidgetDataCMS?.videoList &&
                //   DashboardWidgetDataCMS?.videoList[0] &&
                //   DashboardWidgetDataCMS?.videoList[0].ComponentMediaUrl
                // }
                mp4Src={videoURL?.MediaUrl}
                onClose={() => {
                  fnOpenCloseProgramVideo(1);
                }}
                onPlay={() => programVideoHandler()}
                playButtonText="Play video"
                // posterImageUrl={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
                posterImageUrl={videoURL?.VideoThumbnail}
                videoTitle="Sample video"
                subtitle={videoURL?.VideoSubtitleFile}
              />
            )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <>
                  {featuresConfig?.isJobMatchFeatureAvailable === true ? (
                    <Row className="jobseeker-job-doc-cols m-sm-0">
                      <Col lg={6} md={12} className="p-0">
                        <JobMatchSetup
                          jobMatchCMS={jobMatchCMS && jobMatchCMS}
                        />
                      </Col>
                      <Col lg={6} md={12} className="p-0">
                        {documentsLists.length == 0 && (
                          <MyDocument
                            documentCMS={documentCMS && documentCMS}
                            documentsLists={documentsLists}
                            setDocumentsLists={setDocumentsLists}
                            getAllDocumentsLists={getAllDocumentsLists}
                          />
                        )}
                        {documentsLists.length > 0 && (
                          <MyDocumentsReturnVisit
                            documentRVCMS={documentCMS && documentCMS}
                            documentsLists={documentsLists}
                            setDocumentsLists={setDocumentsLists}
                            getAllDocumentsLists={getAllDocumentsLists}
                          />
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <Row className="jobseeker-job-doc-cols m-sm-0 w-100">
                      <Col lg={12} md={12} className="p-0">
                        {documentsLists.length == 0 && (
                          <MyDocument
                            documentCMS={documentCMS && documentCMS}
                            documentsLists={documentsLists}
                            setDocumentsLists={setDocumentsLists}
                            getAllDocumentsLists={getAllDocumentsLists}
                          />
                        )}
                        {documentsLists.length > 0 && (
                          <MyDocumentsReturnVisit
                            documentRVCMS={documentCMS && documentCMS}
                            documentsLists={documentsLists}
                            setDocumentsLists={setDocumentsLists}
                            getAllDocumentsLists={getAllDocumentsLists}
                          />
                        )}
                      </Col>
                    </Row>
                  )}
                </>
              )}
          </>
        )}

        {/** Recomnded for you */}
        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false &&
              recommdedAssessmentCardData &&
              recommdedAssessmentCardData?.length > 0 && (
                <Container fluid className="self-discovery-recommnded">
                  <div
                    className={`recommended-section ${
                      programVideoData?.length == 0 && isResumeUploaded == false
                        ? "job-seeker-firstvisit"
                        : ""
                    }`}
                  >
                    <div className="carousel discover-carousel-buttons">
                      {recommdedAssessmentCardData && (
                        <Cirrus.CarouselWithButton
                          nextIconButtonProps={{
                            icon: "arrow_forward",
                            onClick: () => {},
                            text: "Next",
                            type: "button",
                          }}
                          button1Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => navigate("/events"),
                          }}
                          button2Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => {
                              redirectToResource();
                            },
                          }}
                          heading={RecommendedForYouCMS?.title}
                          subheading={RecommendedForYouCMS?.description}
                          previousIconButtonProps={{
                            icon: "arrow_back",
                            onClick: function noRefCheck() {},
                            text: "Previous",
                            type: "button",
                          }}
                        >
                          {recommdedAssessmentCardData &&
                            recommdedAssessmentCardData?.map((item, idx) => (
                              <div
                                onClick={() =>
                                  item?.key === "Tools.SmartResumeEditor"
                                    ? saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                    : saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                }
                              >
                                <Cirrus.RecommndedResourcesCard
                                  className="card"
                                  title={item && item.title && item.title}
                                  description={
                                    item &&
                                    item.shortDescription &&
                                    item.shortDescription
                                  }
                                  onCardClick={() => {
                                    cardRedirectHandler(item);
                                  }}
                                  tags={item.tags}
                                  image={{
                                    src: item && item?.largeImage?.imageUrl,
                                  }}
                                  saveButton={{
                                    label: fetchLikeLabel(
                                      "CommonComponent.CommonSaveButton"
                                    ),
                                    secondaryLabel: fetchLikeLabel(
                                      "CommonComponent.CommonSavedButton"
                                    ),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        item &&
                                          item.resourceId &&
                                          item.resourceId,
                                        item && item.isSaved && item.isSaved,
                                        item.isLiked && item.isLiked,
                                        "save",
                                        item &&
                                          item.learningResourceStatus &&
                                          item.learningResourceStatus,
                                        item.lastAnsweredPageId &&
                                          item.lastAnsweredPageId,
                                        item?.resourceType?.id,
                                        item?.resourceCategoryType?.id
                                      );
                                    },
                                  }}
                                  isSaved={item && item.isSaved && item.isSaved}
                                  status={{
                                    name: "new",
                                    label:
                                      item &&
                                      item.resourceType &&
                                      item.resourceType.value
                                        ? item.resourceType.value
                                        : "Assessment",
                                    value:
                                      item &&
                                      item.learningResourceStatus ===
                                        "notstarted"
                                        ? item && item.duration && item.duration
                                        : fetchLearningStatus(
                                            item && item.learningResourceStatus
                                          ),
                                  }}
                                  likeText={
                                    item?.likeCount == 0
                                      ? fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount == 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount > 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikesText"
                                        )
                                      : ""
                                  }
                                  onLikeButtonClick={(e) => {
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item.isSaved && item.isSaved,
                                      item && item.isLiked && item.isLiked,
                                      "like",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    );
                                    saveActivity2(item);
                                  }}
                                  isLiked={item && item.isLiked && item.isLiked}
                                  type="assessment"
                                  layout="horizontal"
                                />
                              </div>
                            ))}
                        </Cirrus.CarouselWithButton>
                      )}
                      {recommdedAssessmentCardData && (
                        <div className="mobile-view_recommended-section">
                          <Cirrus.Button onClick={(e) => navigate("/events")}>
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            )}
                          </Cirrus.Button>
                          <Cirrus.Button
                            onClick={(e) => {
                              redirectToResource();
                            }}
                          >
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            )}
                          </Cirrus.Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <MyResume
                  myResumeCMS={myResumeCMS && myResumeCMS}
                  documentsLists={documentsLists}
                  setDocumentsLists={setDocumentsLists}
                  getAllDocumentsLists={getAllDocumentsLists}
                  resumeBuilderData={resumeBuilderData}
                  resumeBuilderToolUrl={resumeBuilderToolUrl}
                  isResumeUploaded={isResumeUploaded}
                  saveResumeBuilderData={saveResumeBuilderData}
                />
              )}
          </>
        )}
        {/* /// Job Seeker First visit , No Coah assigned, Calender empty */}

        {/* /// Job Seeker First visit , Coach assigned, No Meeting assgined */}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <Container
                  fluid
                  className="background-White learning-collections"
                >
                  <Row>
                    <Cirrus.Header
                      className="heading heading_learning-collections"
                      size="h5"
                    >
                      {learningCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="description description_learning-collections mx-auto">
                      {learningCollectionCMS?.description}
                    </p>
                    <div className="resource-card">
                      {learningCollectionCMS &&
                        learningCollectionCMS.pageContent &&
                        learningCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.LearningCollectionCard
                              title={item && item.Title && item.Title}
                              description={
                                item && item.Description && item.Description
                              }
                              link={{
                                url: `/#${item && item.PageUrl?.Url}`,
                              }}
                              image={{
                                src: item && item?.LargeImage?.ImageUrl,
                              }}
                              status={{
                                name: "new",
                                label:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[0] &&
                                  item.Count[0]?.ResourceCount &&
                                  item.Count[0]?.Resource
                                    ? item.Count[0]?.ResourceCount <= 50
                                      ? item.Count[0]?.ResourceCount +
                                        " " +
                                        item.Count[0]?.Resource
                                      : "50+" + " " + item.Count[0]?.Resource
                                    : "",
                                label2:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[1] &&
                                  item.Count[1]?.ResourceCount &&
                                  item.Count[1]?.Resource
                                    ? item.Count[1]?.ResourceCount <= 50
                                      ? item.Count[1]?.ResourceCount +
                                        " " +
                                        item.Count[1]?.Resource
                                      : "50+" + " " + item.Count[1]?.Resource
                                    : "",
                                label3:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[2] &&
                                  item.Count[2]?.ResourceCount &&
                                  item.Count[2]?.Resource
                                    ? item.Count[2]?.ResourceCount <= 50
                                      ? item.Count[2]?.ResourceCount +
                                        " " +
                                        item.Count[2]?.Resource
                                      : "50+" + " " + item.Count[2]?.Resource
                                    : "",
                              }}
                              type="assessment"
                              layout="horizontal"
                            />
                          </div>
                        ))}
                    </div>
                    <div className="more-collections">
                      <Cirrus.Header size="h5">
                        {moreCollectionCMS?.title}
                      </Cirrus.Header>
                      <p>{moreCollectionCMS?.description}</p>
                      <div className="button-container">
                        {moreCollectionCMS &&
                          moreCollectionCMS.pageContent &&
                          moreCollectionCMS.pageContent.map((item, idx) => (
                            <div>
                              <Cirrus.Button
                                className="more-collection-button"
                                isSecondary
                                onClick={() => {
                                  navigate(`${item && item.PageUrl?.Url}`);
                                }}
                              >
                                {item && item.Title}
                              </Cirrus.Button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Row>
                </Container>
              )}
          </>
        )}

        {/** end job seeker first view */}

        {/** job seeker return view */}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <>
                {isResumeUploaded &&
                featuresConfig?.isJobMatchFeatureAvailable ? (
                  <div className="job-greetings">
                    <JobGreetings greetingCMS={greetingCMS && greetingCMS} />
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}

        {/*  Job seeker (Return view) - Coach assigned, Upcoming & Invitation meeting is available*/}
        {((fetchPersona && fetchPersona?.personaName == "Job Seeker") ||
          (fetchPersona && fetchPersona?.personaName == "Independent") ||
          (fetchPersona && fetchPersona?.personaName == "Professional")) && (
          <>
            {showCalender == true &&
              upcomingMeetings &&
              meetingInvites &&
              (Object.keys(upcomingMeetings)?.length > 0 ||
                Object.keys(meetingInvites)?.length > 0) && (
                <div className="dashboard-coach-container dash-event">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={cmsContentCoach?.coachingSessions}
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "coach"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={cmsContent && cmsContent?.myCalanderTitle}
                    calledFrom={"dashboard"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                  />
                </div>
              )}
          </>
        )}
        {/* <br></br> */}
        {/*  Job seeker (Return view) - Coach assigned, Upcoming meeting is available and Invitation is empty */}

        {((fetchPersona && fetchPersona?.personaName == "Job Seeker") ||
          (fetchPersona && fetchPersona?.personaName == "Independent") ||
          (fetchPersona && fetchPersona?.personaName == "Professional")) && (
          <>
            {showCalender == true &&
              Object.keys(upcomingMeetings)?.length == 0 &&
              Object.keys(meetingInvites)?.length == 0 &&
              myCoachStatusCheck &&
              myCoachStatusCheck == 204 && (
                <div className="dashboard-coach-container job-seeker_cal">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={cmsContentCoach?.myCalanderLabel}
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "commonComponents_Labels"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={cmsContent && cmsContent?.myCalanderTitle}
                    calledFrom={"dashboardNoCoach"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                    myCalanderDescription={
                      cmsContent && cmsContent?.myCalanderDescription
                    }
                    myCalanderLabel={cmsContent && cmsContent?.myCalanderLabel}
                  />
                </div>
              )}
          </>
        )}
        {/* <br></br> */}
        {((fetchPersona && fetchPersona?.personaName == "Job Seeker") ||
          (fetchPersona && fetchPersona?.personaName == "Independent") ||
          (fetchPersona && fetchPersona?.personaName == "Professional")) && (
          <>
            {showCalender == true &&
              myCoachStatusCheck &&
              myCoachStatusCheck !== 204 &&
              // upcomingMeetings && meetingInvites &&
              Object.keys(upcomingMeetings)?.length == 0 &&
              Object.keys(meetingInvites)?.length == 0 && (
                <div className="dashboard-coach-container">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={cmsContent && cmsContent?.myCalanderLabel}
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "commonComponents_Labels"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={cmsContent && cmsContent?.myCalanderTitle}
                    calledFrom={"dashboardNoMeeting"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                  />
                </div>
              )}
          </>
        )}

        {/** Recomnded for you */}
        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              (programVideoData?.length > 0 || isResumeUploaded) &&
              recommdedAssessmentCardData &&
              recommdedAssessmentCardData?.length > 0 && (
                <Container fluid className="self-discovery-recommnded">
                  <div className="recommended-section job-seeker-returnvisit">
                    <div className="carousel discover-carousel-buttons">
                      {recommdedAssessmentCardData && (
                        <Cirrus.CarouselWithButton
                          nextIconButtonProps={{
                            icon: "arrow_forward",
                            onClick: () => {},
                            text: "Next",
                            type: "button",
                          }}
                          button1Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => navigate("/events"),
                          }}
                          button2Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => {
                              redirectToResource();
                            },
                          }}
                          heading={RecommendedForYouCMS?.title}
                          subheading={RecommendedForYouCMS?.description}
                          previousIconButtonProps={{
                            icon: "arrow_back",
                            onClick: function noRefCheck() {},
                            text: "Previous",
                            type: "button",
                          }}
                        >
                          {recommdedAssessmentCardData &&
                            recommdedAssessmentCardData?.map((item, idx) => (
                              <div
                                onClick={() =>
                                  item?.key === "Tools.SmartResumeEditor"
                                    ? saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                    : saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                }
                              >
                                <Cirrus.RecommndedResourcesCard
                                  className="card"
                                  title={item && item.title && item.title}
                                  description={
                                    item &&
                                    item.shortDescription &&
                                    item.shortDescription
                                  }
                                  onCardClick={() => {
                                    cardRedirectHandler(item);
                                  }}
                                  tags={item.tags}
                                  image={{
                                    src: item && item?.largeImage?.imageUrl,
                                  }}
                                  saveButton={{
                                    label: fetchLikeLabel(
                                      "CommonComponent.CommonSaveButton"
                                    ),
                                    secondaryLabel: fetchLikeLabel(
                                      "CommonComponent.CommonSavedButton"
                                    ),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        item &&
                                          item.resourceId &&
                                          item.resourceId,
                                        item && item.isSaved && item.isSaved,
                                        item.isLiked && item.isLiked,
                                        "save",
                                        item &&
                                          item.learningResourceStatus &&
                                          item.learningResourceStatus,
                                        item.lastAnsweredPageId &&
                                          item.lastAnsweredPageId,
                                        item?.resourceType?.id,
                                        item?.resourceCategoryType?.id
                                      );
                                    },
                                  }}
                                  isSaved={item && item.isSaved && item.isSaved}
                                  status={{
                                    name: "new",
                                    label:
                                      item &&
                                      item.resourceType &&
                                      item.resourceType.value
                                        ? item.resourceType.value
                                        : "Assessment",
                                    value:
                                      item &&
                                      item.learningResourceStatus ===
                                        "notstarted"
                                        ? item && item.duration && item.duration
                                        : fetchLearningStatus(
                                            item && item.learningResourceStatus
                                          ),
                                  }}
                                  likeText={
                                    item?.likeCount == 0
                                      ? fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount == 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount > 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikesText"
                                        )
                                      : ""
                                  }
                                  onLikeButtonClick={(e) =>
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item.isSaved && item.isSaved,
                                      item && item.isLiked && item.isLiked,
                                      "like",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    )
                                  }
                                  isLiked={item && item.isLiked && item.isLiked}
                                  type="assessment"
                                  layout="horizontal"
                                />
                              </div>
                            ))}
                        </Cirrus.CarouselWithButton>
                      )}
                      {recommdedAssessmentCardData && (
                        <div className="mobile-view_recommended-section">
                          <Cirrus.Button onClick={(e) => navigate("/events")}>
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            )}
                          </Cirrus.Button>
                          <Cirrus.Button
                            onClick={(e) => {
                              redirectToResource();
                            }}
                          >
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            )}
                          </Cirrus.Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <Container
                fluid
                className="background-White learning-collections"
              >
                <Row>
                  <Cirrus.Header
                    className="heading heading_learning-collections"
                    size="h5"
                  >
                    {learningCollectionCMS?.title}
                  </Cirrus.Header>
                  <p className="description description_learning-collections mx-auto">
                    {learningCollectionCMS?.description}
                  </p>
                  <div className="resource-card">
                    {learningCollectionCMS &&
                      learningCollectionCMS.pageContent &&
                      learningCollectionCMS.pageContent.map((item, idx) => (
                        <div>
                          <Cirrus.LearningCollectionCard
                            title={item && item.Title && item.Title}
                            description={
                              item && item.Description && item.Description
                            }
                            link={{
                              url: `/#${item && item.PageUrl?.Url}`,
                            }}
                            image={{ src: item && item?.LargeImage?.ImageUrl }}
                            status={{
                              name: "new",
                              label:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[0] &&
                                item.Count[0]?.ResourceCount &&
                                item.Count[0]?.Resource
                                  ? item.Count[0]?.ResourceCount <= 50
                                    ? item.Count[0]?.ResourceCount +
                                      " " +
                                      item.Count[0]?.Resource
                                    : "50+" + " " + item.Count[0]?.Resource
                                  : "",
                              label2:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[1] &&
                                item.Count[1]?.ResourceCount &&
                                item.Count[1]?.Resource
                                  ? item.Count[1]?.ResourceCount <= 50
                                    ? item.Count[1]?.ResourceCount +
                                      " " +
                                      item.Count[1]?.Resource
                                    : "50+" + " " + item.Count[1]?.Resource
                                  : "",
                              label3:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[2] &&
                                item.Count[2]?.ResourceCount &&
                                item.Count[2]?.Resource
                                  ? item.Count[2]?.ResourceCount <= 50
                                    ? item.Count[2]?.ResourceCount +
                                      " " +
                                      item.Count[2]?.Resource
                                    : "50+" + " " + item.Count[2]?.Resource
                                  : "",
                            }}
                            type="assessment"
                            layout="horizontal"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="more-collections">
                    <Cirrus.Header size="h5">
                      {moreCollectionCMS?.title}
                    </Cirrus.Header>
                    <p>{moreCollectionCMS?.description}</p>
                    <div className="button-container">
                      {moreCollectionCMS &&
                        moreCollectionCMS.pageContent &&
                        moreCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.Button
                              className="more-collection-button"
                              isSecondary
                              onClick={() => {
                                navigate(`${item && item.PageUrl?.Url}`);
                              }}
                            >
                              {item && item.Title}
                            </Cirrus.Button>
                          </div>
                        ))}
                    </div>
                  </div>
                </Row>
              </Container>
            )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {programVideoData &&
              (programVideoData?.length > 0 || isResumeUploaded) && (
                <MyDocumentsReturnVisit
                  documentRVCMS={documentRVCMS && documentRVCMS}
                  documentsLists={documentsLists}
                  setDocumentsLists={setDocumentsLists}
                  getAllDocumentsLists={getAllDocumentsLists}
                />
              )}
          </>
        )}

        {/* resume start - return visit*/}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <MyResume
                myResumeCMS={myResumeCMS && myResumeCMS}
                documentsLists={documentsLists}
                setDocumentsLists={setDocumentsLists}
                getAllDocumentsLists={getAllDocumentsLists}
                resumeBuilderData={resumeBuilderData}
                resumeBuilderToolUrl={resumeBuilderToolUrl}
                isResumeUploaded={isResumeUploaded}
              />
            )}
          </>
        )}

        {/* resume end - return visit */}

        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Job Seeker" && (
            <>
              {programVideoData &&
                (programVideoData?.length > 0 || isResumeUploaded) && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={DashboardWidgetDataCMS?.description}
                      heading={DashboardWidgetDataCMS?.title}
                      imageProps={{
                        src: DashboardWidgetDataCMS?.imageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchDashboardLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      playButton={true}
                    />
                  </div>
                )}
            </>
          )}

        {fetchPersona && fetchPersona?.personaName == "Job Seeker" && (
          <>
            {bProgramVideo && (
              <Cirrus.VideoLightbox
                closeButtonText="Close lightbox"
                controls
                //description={DashboardWidgetDataCMS?.description}
                // mp4Src={
                //   DashboardWidgetDataCMS &&
                //   DashboardWidgetDataCMS?.videoList &&
                //   DashboardWidgetDataCMS?.videoList[0] &&
                //   DashboardWidgetDataCMS?.videoList[0]?.ComponentMediaUrl
                // }
                mp4Src={videoURL?.MediaUrl}
                onClose={() => {
                  fnOpenCloseProgramVideo(1);
                }}
                onPlay={() => programVideoHandler()}
                playButtonText="Play video"
                // posterImageUrl={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
                posterImageUrl={videoURL?.VideoThumbnail}
                videoTitle="Sample video"
                subtitle={videoURL?.VideoSubtitleFile}
              />
            )}
          </>
        )}

        {/* Featured resources start */}
        {fetchPersona &&
          fetchPersona?.personaName == "Job Seeker" &&
          featuredResourceCMS &&
          featuredResourceCMS.length > 0 &&
          ((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            <>
              <div className="p-1 w-100 rounded-right">
                <Cirrus.FeaturedCard
                  bodyText={featuredResourceCMS?.data?.Description}
                  heading={featuredResourceCMS?.data?.Title}
                  imageProps={{
                    src: featuredResourceCMS?.data?.LargeImage?.ImageUrl,
                  }}
                  isWide
                  primaryButtonProps={{
                    children:
                      featuredResourceCMS?.data?.ResourceType?.ID == "article"
                        ? fetchLikeLabel("CommonComponent.CommonReadArticle")
                        : featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? fetchLikeLabel("CommonComponent.CommonWatchVideo")
                        : featuredResourceCMS?.data?.ResourceType?.ID ==
                          "document"
                        ? fetchLikeLabel("CommonComponent.CommonReadDocument")
                        : "",
                    leadingIcon:
                      featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? "play"
                        : "",
                    href:
                      featuredResourceCMS?.data?.ResourceType?.ID == "article"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID ==
                          "document"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : "",
                  }}
                />
              </div>
            </>
          )}
        {/* Featured resources end */}

        {/** end job seeker return view */}

        {/* Excecutive first view */}

        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Executive" && (
            <>
              {programVideoData &&
                programVideoData?.length == 0 &&
                isResumeUploaded == false && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={excecutiveCMSData?.excecutiveVideoDescription}
                      heading={excecutiveCMSData?.excecutiveVideoTitle}
                      imageProps={{
                        src: excecutiveCMSData?.videoImageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchExecutiveLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideo(0),
                      }}
                      playButton={true}
                    />
                  </div>
                )}
            </>
          )}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {bProgramVideo && (
              <Cirrus.VideoLightbox
                closeButtonText="Close lightbox"
                controls
                //description={excecutiveCMSData?.excecutiveVideoDescription}
                // mp4Src={
                //   excecutiveCMSData &&
                //   excecutiveCMSData?.excecutiveVideoUrl &&
                //   excecutiveCMSData?.excecutiveVideoUrl[0] &&
                //   excecutiveCMSData?.excecutiveVideoUrl[0].ComponentMediaUrl
                // }
                mp4Src={videoURL?.MediaUrl}
                onClose={() => {
                  fnOpenCloseProgramVideo(1);
                }}
                onPlay={() => programVideoHandler()}
                playButtonText="Play video"
                // posterImageUrl={excecutiveCMSData?.videoImageList?.LargeImage?.ImageUrl}
                posterImageUrl={videoURL?.VideoThumbnail}
                videoTitle="Sample video"
                subtitle={videoURL?.VideoSubtitleFile}
              />
            )}
          </>
        )}
        {fetchPersona &&
        fetchPersona?.personaName == "Executive" &&
        coachStatus &&
        coachStatus === true ? (
          <div className="p-1 w-100 rounded-right">
            <Cirrus.DashboardFeaturedCard
              bodyText={excecutiveCMSData?.description}
              heading={excecutiveCMSData?.title}
              imageProps={{
                src: excecutiveCMSData?.imageList?.LargeImage?.ImageUrl,
              }}
              primaryButtonProps={{
                children:
                  excecutiveCMSData &&
                  excecutiveCMSData?.labels &&
                  excecutiveCMSData?.labels[0]?.Phrase,
                onClick: (e) => navigate("/mycoach"),
                isAlt: true,
                size: "Large",
                target: "_blank",
              }}
            />
          </div>
        ) : (
          ""
        )}

        {/* Featured resources start */}
        {/* {fetchPersona && fetchPersona?.personaName == "Executive" && featuredResourceCMS?.data &&
          (programVideoData && programVideoData?.length == 0 &&
            isResumeUploaded == false) &&
          <>
            <div className="p-1 w-100 rounded-right 1">
              <Cirrus.FeaturedCard
                bodyText={featuredResourceCMS?.data?.ShortDescription}
                heading={featuredResourceCMS?.data?.Title}
                imageProps={{
                  src: featuredResourceCMS?.data?.LargeImage?.ImageUrl,
                }}
                isWide
                primaryButtonProps={{
                  children: featuredResourceCMS?.data?.ResourceType?.ID == "article" ? fetchExecutiveCommonLabel("CommonComponent.CommonReadArticle") :
                    featuredResourceCMS?.data?.ResourceType?.ID == "video" ? fetchExecutiveCommonLabel("CommonComponent.CommonWatchVideo") :
                      featuredResourceCMS?.data?.ResourceType?.ID == "document" ? fetchExecutiveCommonLabel("CommonComponent.CommonReadDocument") : '',
                  leadingIcon: featuredResourceCMS?.data?.ResourceType?.ID == "video" ? "play" : '',
                  href: featuredResourceCMS?.data?.ResourceType?.ID == "article" ? "#/article-page/:id".replace(":id", featuredResourceCMS?.data?.Id)
                    : featuredResourceCMS?.data?.ResourceType?.ID == "video" ? "#/article-page/:id".replace(":id", featuredResourceCMS?.data?.Id)
                      : featuredResourceCMS?.data?.ResourceType?.ID == "document" ? "#/article-page/:id".replace(":id", featuredResourceCMS?.data?.Id)
                        : ""
                }}
              />
            </div>
          </>
        } */}
        {/* Featured resources end */}

        {/* /// Executive First visit , Coach assigned, Calender empty  */}

        {/* /// Executive First visit , No Coah assigned, Calendr empty */}

        {/** Thougth leadership for executive perosna */}
        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false &&
              thoughtLeadershiptData &&
              thoughtLeadershiptData?.length > 0 && (
                <Container fluid className="self-discovery-recommnded exe1">
                  <div className="recommended-section">
                    <div className="carousel discover-carousel-buttons">
                      {thoughtLeadershiptData && (
                        <Cirrus.CarouselWithButton
                          nextIconButtonProps={{
                            icon: "arrow_forward",
                            onClick: () => {},
                            text: "Next",
                            type: "button",
                          }}
                          button2Props={{
                            children: fetchExecutiveCommonLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => {
                              redirectToResource();
                            },
                          }}
                          heading={ThoughtLeadershiptCMS?.title}
                          subheading={ThoughtLeadershiptCMS?.description}
                          previousIconButtonProps={{
                            icon: "arrow_back",
                            onClick: function noRefCheck() {},
                            text: "Previous",
                            type: "button",
                          }}
                        >
                          {thoughtLeadershiptData &&
                            thoughtLeadershiptData?.map((item, idx) => (
                              <div
                                onClick={() =>
                                  item?.key === "Tools.SmartResumeEditor"
                                    ? saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                    : saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                }
                              >
                                <Cirrus.RecommndedResourcesCard
                                  className="card"
                                  title={item && item.title && item.title}
                                  description={
                                    item &&
                                    item.shortDescription &&
                                    item.shortDescription
                                  }
                                  onCardClick={() => {
                                    cardRedirectHandler(item);
                                  }}
                                  tags={item.tags}
                                  image={{
                                    src: item && item?.largeImage?.imageUrl,
                                  }}
                                  saveButton={{
                                    label: fetchExecutiveCommonLabel(
                                      "CommonComponent.CommonSaveButton"
                                    ),
                                    secondaryLabel: fetchExecutiveCommonLabel(
                                      "CommonComponent.CommonSavedButton"
                                    ),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        item &&
                                          item.resourceId &&
                                          item.resourceId,
                                        item && item.isSaved && item.isSaved,
                                        item.isLiked && item.isLiked,
                                        "save",
                                        item &&
                                          item.learningResourceStatus &&
                                          item.learningResourceStatus,
                                        item.lastAnsweredPageId &&
                                          item.lastAnsweredPageId,
                                        item?.resourceType?.id,
                                        item?.resourceCategoryType?.id
                                      );
                                    },
                                  }}
                                  isSaved={item && item.isSaved && item.isSaved}
                                  status={{
                                    name: "new",
                                    label:
                                      item &&
                                      item.resourceType &&
                                      item.resourceType.value
                                        ? item.resourceType.value
                                        : "Assessment",
                                    value:
                                      item &&
                                      item.learningResourceStatus ===
                                        "notstarted"
                                        ? item && item.duration && item.duration
                                        : fetchLearningStatus(
                                            item && item.learningResourceStatus
                                          ),
                                  }}
                                  likeText={
                                    item?.likeCount == 0
                                      ? fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount == 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount > 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikesText"
                                        )
                                      : ""
                                  }
                                  onLikeButtonClick={(e) => {
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item.isSaved && item.isSaved,
                                      item && item.isLiked && item.isLiked,
                                      "like",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    );
                                    saveActivity2(item);
                                  }}
                                  isLiked={item && item.isLiked && item.isLiked}
                                  type="assessment"
                                  layout="horizontal"
                                />
                              </div>
                            ))}
                        </Cirrus.CarouselWithButton>
                      )}
                      {thoughtLeadershiptData && (
                        <div className="mobile-view_recommended-section">
                          <Cirrus.Button
                            onClick={(e) => {
                              redirectToResource();
                            }}
                          >
                            {fetchExecutiveCommonLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            )}
                          </Cirrus.Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <Container
                  fluid
                  className="background-White learning-collections"
                >
                  <Row>
                    <Cirrus.Header
                      className="heading heading_learning-collections"
                      size="h5"
                    >
                      {learningCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="description description_learning-collections mx-auto">
                      {learningCollectionCMS?.description}
                    </p>
                    <div className="resource-card">
                      {learningCollectionCMS &&
                        learningCollectionCMS.pageContent &&
                        learningCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.LearningCollectionCard
                              title={item && item.Title && item.Title}
                              description={
                                item && item.Description && item.Description
                              }
                              link={{
                                url: `/#${item && item.PageUrl?.Url}`,
                              }}
                              image={{
                                src: item && item?.LargeImage?.ImageUrl,
                              }}
                              status={{
                                name: "new",
                                label:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[0] &&
                                  item.Count[0]?.ResourceCount &&
                                  item.Count[0]?.Resource
                                    ? item.Count[0]?.ResourceCount <= 50
                                      ? item.Count[0]?.ResourceCount +
                                        " " +
                                        item.Count[0]?.Resource
                                      : "50+" + " " + item.Count[0]?.Resource
                                    : "",
                                label2:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[1] &&
                                  item.Count[1]?.ResourceCount &&
                                  item.Count[1]?.Resource
                                    ? item.Count[1]?.ResourceCount <= 50
                                      ? item.Count[1]?.ResourceCount +
                                        " " +
                                        item.Count[1]?.Resource
                                      : "50+" + " " + item.Count[1]?.Resource
                                    : "",
                                label3:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[2] &&
                                  item.Count[2]?.ResourceCount &&
                                  item.Count[2]?.Resource
                                    ? item.Count[2]?.ResourceCount <= 50
                                      ? item.Count[2]?.ResourceCount +
                                        " " +
                                        item.Count[2]?.Resource
                                      : "50+" + " " + item.Count[2]?.Resource
                                    : "",
                              }}
                              type="assessment"
                              layout="horizontal"
                            />
                          </div>
                        ))}
                    </div>
                    <div className="more-collections">
                      <Cirrus.Header size="h5">
                        {moreCollectionCMS?.title}
                      </Cirrus.Header>
                      <p className="more-collection-desc">
                        {moreCollectionCMS?.description}
                      </p>
                      <div className="button-container">
                        {moreCollectionCMS &&
                          moreCollectionCMS.pageContent &&
                          moreCollectionCMS.pageContent.map((item, idx) => (
                            <div>
                              <Cirrus.Button
                                className="more-collection-button"
                                isSecondary
                                onClick={() => {
                                  navigate(`${item && item.PageUrl?.Url}`);
                                }}
                              >
                                {item && item.Title}
                              </Cirrus.Button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Row>
                </Container>
              )}
          </>
        )}

        {getFeaturesConfig("EventsPage") && (
          <>
            {fetchPersona && fetchPersona?.personaName == "Executive" && (
              <>
                {programVideoData &&
                  programVideoData?.length == 0 &&
                  isResumeUploaded == false &&
                  recommendedEvents &&
                  recommendedEvents?.length > 0 && (
                    <Container fluid className="self-discovery-recommnded exe2">
                      <div className="recommended-section">
                        <div className="carousel executive-market-carousel-buttons">
                          {recommendedEvents &&
                            recommendedEvents.length > 0 && (
                              <Cirrus.CarouselWithButton
                                nextIconButtonProps={{
                                  icon:
                                    recommendedEvents &&
                                    recommendedEvents.length > 3
                                      ? "arrow_forward"
                                      : "",
                                  onClick: () => {},
                                  text: "Next",
                                  type: "button",
                                }}
                                heading={fetchExecutivePageTemplateLabel(
                                  "DashboardPage.DashboardPageTemplate.ExecutiveMarketConnectionEvents"
                                )}
                                button1Props={{
                                  children: fetchExecutiveCommonLabel(
                                    "CommonComponent.SeeAllEventsButton"
                                  ),
                                  isAlt: true,
                                  size: "Large",
                                  onClick: (e) => navigate("/events"),
                                }}
                                previousIconButtonProps={{
                                  icon:
                                    recommendedEvents &&
                                    recommendedEvents.length > 3
                                      ? "arrow_back"
                                      : "",
                                  onClick: function noRefCheck() {},
                                  text: "Previous",
                                  type: "button",
                                }}
                              >
                                {recommendedEvents
                                  .slice(
                                    0,
                                    recommendedEvents.length > 12
                                      ? 12
                                      : recommendedEvents.length
                                  )
                                  .map((allEvents, index) => {
                                    return (
                                      <Cirrus.RelatedResourcesCard
                                        title={allEvents.event_name}
                                        key={index}
                                        className="card"
                                        d
                                        description={getDescription(
                                          allEvents.event_description
                                        )}
                                        onCardClick={() => {
                                          window.open(
                                            `#/eventDetails/${
                                              allEvents?.event_id
                                            }/${
                                              allEvents?.isSaved
                                                ? allEvents?.isSaved
                                                : false
                                            }`,
                                            "_self"
                                          );
                                        }}
                                        tags={getTags(allEvents)}
                                        image={
                                          allEvents.image_thumbnail
                                            ? {
                                                src: allEvents?.image_thumbnail,
                                              }
                                            : {
                                                src: commonImage?.LargeImage
                                                  ?.ImageUrl,
                                              }
                                        }
                                        saveButton={{
                                          label: allEvents?.isSaved
                                            ? "Saved"
                                            : "Save",
                                          secondaryLabel: allEvents?.isSaved
                                            ? "Saved"
                                            : "Save",
                                          onClick: (e) => {
                                            updateEvents(allEvents);
                                          },
                                        }}
                                        type="event"
                                        layout="vertical"
                                        eventType={{
                                          eventDetails: [
                                            fetchLabel(allEvents.event_type),
                                          ],
                                          isSuccess: true,
                                          status: getEventStatus(allEvents),
                                        }}
                                        isSaved={
                                          allEvents?.isSaved ? true : false
                                        }
                                      />
                                    );
                                  })}
                              </Cirrus.CarouselWithButton>
                            )}
                          {recommendedEvents?.length > 0 && (
                            <div className="mobile-view_recommended-section">
                              <Cirrus.Button
                                onClick={(e) => navigate("/events")}
                              >
                                {fetchExecutiveCommonLabel(
                                  "CommonComponent.SeeAllEventsButton"
                                )}
                              </Cirrus.Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Container>
                  )}
              </>
            )}
          </>
        )}

        {fetchPersona &&
          fetchPersona?.personaName == "Executive" &&
          featuresConfig?.isJobMatchFeatureAvailable && (
            <>
              {programVideoData &&
                programVideoData?.length == 0 &&
                isResumeUploaded == false && (
                  <div className="p-1 w-100 rounded-right">
                    <Cirrus.FeaturedCard
                      bodyText={jobMatchCMS?.description}
                      heading={jobMatchCMS?.title}
                      imageProps={{
                        src: jobMatchCMS?.jobSetupImageList?.LargeImage
                          ?.ImageUrl,
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchJobMatchlabels(
                          "DashboardPage.DashboardJobMatchSetup.GetStarted"
                        ),
                        onClick: () => {
                          context.setDashboardResumeFlag(true);
                          navigate("/jobsearchPage");
                        },
                      }}
                    />
                  </div>
                )}
            </>
          )}

        {/* End Excecutive first view */}

        {/* Excecutive return view */}

        {/* /// Executive return visit , Coah assigned, Upcoming and Invitation available */}
        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {showCalender == true &&
              upcomingMeetings &&
              meetingInvites &&
              (Object.keys(upcomingMeetings)?.length > 0 ||
                Object.keys(meetingInvites)?.length > 0) && (
                <div className="dashboard-coach-container dash-event">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={
                      excecutiveCMSData && excecutiveCMSData?.coachingSessions
                    }
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={
                      fetchPersona?.personaName == "Executive"
                        ? fetchExecutiveCommonLabel(
                            "CommonComponent.CommonViewAll",
                            "coach"
                          )
                        : fetchLabel("CommonComponent.CommonViewAll", "coach")
                    }
                    removeCompltedEvent={true}
                    myCalanderTitle={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderTitle
                    }
                    calledFrom={"dashboard"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                  />
                </div>
              )}
          </>
        )}

        {/* Featured resources start */}
        {fetchPersona &&
          fetchPersona?.personaName == "Executive" &&
          featuredResourceCMS?.data &&
          ((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            <>
              <div className="p-1 w-100 rounded-right 2">
                <Cirrus.FeaturedCard
                  bodyText={htmlParser(
                    featuredResourceCMS?.data?.ShortDescription
                      ? featuredResourceCMS?.data?.ShortDescription
                      : ""
                  )}
                  heading={featuredResourceCMS?.data?.Title}
                  imageProps={{
                    src: featuredResourceCMS?.data?.LargeImage?.ImageUrl,
                  }}
                  isWide
                  primaryButtonProps={{
                    children:
                      featuredResourceCMS?.data?.ResourceType?.ID == "article"
                        ? fetchExecutiveCommonLabel(
                            "CommonComponent.CommonReadArticle"
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? fetchExecutiveCommonLabel(
                            "CommonComponent.CommonWatchVideo"
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID ==
                          "document"
                        ? fetchExecutiveCommonLabel(
                            "CommonComponent.CommonReadDocument"
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID == "E3"
                        ? fetchExecutiveCommonLabel(
                            "CommonComponent.CommonWatchVideo"
                          )
                        : "",
                    leadingIcon:
                      featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? "play"
                        : "",
                    href:
                      featuredResourceCMS?.data?.ResourceType?.ID == "article"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID == "video"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID ==
                          "document"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : featuredResourceCMS?.data?.ResourceType?.ID == "E3"
                        ? "#/article-page/:id".replace(
                            ":id",
                            featuredResourceCMS?.data?.Id
                          )
                        : "",
                  }}
                />
              </div>
            </>
          )}
        {/* Featured resources end */}

        {/* /// Executive return visit , Coah assigned, Upcoming  available and Invitation is empty */}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {showCalender == true &&
              Object.keys(upcomingMeetings)?.length == 0 &&
              Object.keys(meetingInvites)?.length == 0 &&
              myCoachStatusCheck &&
              myCoachStatusCheck == 204 && (
                <div className="dashboard-coach-container exe-cal">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderLabel
                    }
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "commonComponents_Labels"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderTitle
                    }
                    calledFrom={"dashboardNoCoach"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                    myCalanderDescription={
                      excecutiveCMSData &&
                      excecutiveCMSData?.myCalanderDescription
                    }
                    myCalanderLabel={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderLabel
                    }
                  />
                </div>
              )}
          </>
        )}

        {/* <br></br> */}
        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {showCalender == true &&
              myCoachStatusCheck &&
              myCoachStatusCheck !== 204 &&
              // upcomingMeetings && meetingInvites &&
              Object.keys(upcomingMeetings)?.length == 0 &&
              Object.keys(meetingInvites)?.length == 0 && (
                <div className="dashboard-coach-container exe-cal">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderLabel
                    }
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "commonComponents_Labels"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={
                      excecutiveCMSData && excecutiveCMSData?.myCalanderTitle
                    }
                    calledFrom={"dashboardNoMeeting"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                  />
                </div>
              )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) &&
              thoughtLeadershiptData &&
              thoughtLeadershiptData?.length > 0 && (
                <Container fluid className="self-discovery-recommnded exe3">
                  <div className="recommended-section">
                    <div className="carousel discover-carousel-buttons">
                      {thoughtLeadershiptData && (
                        <Cirrus.CarouselWithButton
                          nextIconButtonProps={{
                            icon: "arrow_forward",
                            onClick: () => {},
                            text: "Next",
                            type: "button",
                          }}
                          button2Props={{
                            children: fetchExecutiveCommonLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => {
                              redirectToResource();
                            },
                          }}
                          heading={ThoughtLeadershiptCMS?.title}
                          subheading={ThoughtLeadershiptCMS?.description}
                          previousIconButtonProps={{
                            icon: "arrow_back",
                            onClick: function noRefCheck() {},
                            text: "Previous",
                            type: "button",
                          }}
                        >
                          {thoughtLeadershiptData &&
                            thoughtLeadershiptData?.map((item, idx) => (
                              <div
                                onClick={() =>
                                  item?.key === "Tools.SmartResumeEditor"
                                    ? saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                    : saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                }
                              >
                                <Cirrus.RecommndedResourcesCard
                                  className="card"
                                  title={item && item.title && item.title}
                                  description={
                                    item &&
                                    item.shortDescription &&
                                    item.shortDescription
                                  }
                                  onCardClick={() => {
                                    cardRedirectHandler(item);
                                  }}
                                  tags={item.tags}
                                  image={{
                                    src: item && item?.largeImage?.imageUrl,
                                  }}
                                  saveButton={{
                                    label: fetchExecutiveCommonLabel(
                                      "CommonComponent.CommonSaveButton"
                                    ),
                                    secondaryLabel: fetchExecutiveCommonLabel(
                                      "CommonComponent.CommonSavedButton"
                                    ),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        item &&
                                          item.resourceId &&
                                          item.resourceId,
                                        item && item.isSaved && item.isSaved,
                                        item.isLiked && item.isLiked,
                                        "save",
                                        item &&
                                          item.learningResourceStatus &&
                                          item.learningResourceStatus,
                                        item.lastAnsweredPageId &&
                                          item.lastAnsweredPageId,
                                        item?.resourceType?.id,
                                        item?.resourceCategoryType?.id
                                      );
                                    },
                                  }}
                                  isSaved={item && item.isSaved && item.isSaved}
                                  status={{
                                    name: "new",
                                    label:
                                      item &&
                                      item.resourceType &&
                                      item.resourceType.value
                                        ? item.resourceType.value
                                        : "Assessment",
                                    value:
                                      item &&
                                      item.learningResourceStatus ===
                                        "notstarted"
                                        ? item && item.duration && item.duration
                                        : fetchLearningStatus(
                                            item && item.learningResourceStatus
                                          ),
                                  }}
                                  likeText={
                                    item?.likeCount == 0
                                      ? fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount == 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount > 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchExecutiveCommonLabel(
                                          "CommonComponent.CommonLikesText"
                                        )
                                      : ""
                                  }
                                  onLikeButtonClick={(e) => {
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item.isSaved && item.isSaved,
                                      item && item.isLiked && item.isLiked,
                                      "like",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    );
                                    saveActivity2(item);
                                  }}
                                  isLiked={item && item.isLiked && item.isLiked}
                                  type="assessment"
                                  layout="horizontal"
                                />
                              </div>
                            ))}
                        </Cirrus.CarouselWithButton>
                      )}
                      {/* </div> */}
                      {thoughtLeadershiptData && (
                        <div className="mobile-view_recommended-section">
                          <Cirrus.Button
                            onClick={(e) => {
                              redirectToResource();
                            }}
                          >
                            {fetchExecutiveCommonLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            )}
                          </Cirrus.Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <Container
                fluid
                className="background-White learning-collections"
              >
                <Row>
                  <Cirrus.Header
                    className="heading heading_learning-collections"
                    size="h5"
                  >
                    {learningCollectionCMS?.title}
                  </Cirrus.Header>
                  <p className="description description_learning-collections mx-auto">
                    {learningCollectionCMS?.description}
                  </p>
                  <div className="resource-card">
                    {learningCollectionCMS &&
                      learningCollectionCMS.pageContent &&
                      learningCollectionCMS.pageContent.map((item, idx) => (
                        <div>
                          <Cirrus.LearningCollectionCard
                            title={item && item.Title && item.Title}
                            description={
                              item && item.Description && item.Description
                            }
                            link={{
                              url: `/#${item && item.PageUrl?.Url}`,
                            }}
                            image={{ src: item && item?.LargeImage?.ImageUrl }}
                            status={{
                              name: "new",
                              label:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[0] &&
                                item.Count[0]?.ResourceCount &&
                                item.Count[0]?.Resource
                                  ? item.Count[0]?.ResourceCount <= 50
                                    ? item.Count[0]?.ResourceCount +
                                      " " +
                                      item.Count[0]?.Resource
                                    : "50+" + " " + item.Count[0]?.Resource
                                  : "",
                              label2:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[1] &&
                                item.Count[1]?.ResourceCount &&
                                item.Count[1]?.Resource
                                  ? item.Count[1]?.ResourceCount <= 50
                                    ? item.Count[1]?.ResourceCount +
                                      " " +
                                      item.Count[1]?.Resource
                                    : "50+" + " " + item.Count[1]?.Resource
                                  : "",
                              label3:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[2] &&
                                item.Count[2]?.ResourceCount &&
                                item.Count[2]?.Resource
                                  ? item.Count[2]?.ResourceCount <= 50
                                    ? item.Count[2]?.ResourceCount +
                                      " " +
                                      item.Count[2]?.Resource
                                    : "50+" + " " + item.Count[2]?.Resource
                                  : "",
                            }}
                            type="assessment"
                            layout="horizontal"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="more-collections">
                    <Cirrus.Header size="h5">
                      {moreCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="more-collection-desc">
                      {moreCollectionCMS?.description}
                    </p>
                    <div className="button-container">
                      {moreCollectionCMS &&
                        moreCollectionCMS.pageContent &&
                        moreCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.Button
                              className="more-collection-button"
                              isSecondary
                              onClick={() => {
                                navigate(`${item && item.PageUrl?.Url}`);
                              }}
                            >
                              {item && item.Title}
                            </Cirrus.Button>
                          </div>
                        ))}
                    </div>
                  </div>
                </Row>
              </Container>
            )}
          </>
        )}

        {getFeaturesConfig("EventsPage") && (
          <>
            {fetchPersona && fetchPersona?.personaName == "Executive" && (
              <>
                {((programVideoData && programVideoData?.length > 0) ||
                  isResumeUploaded) &&
                  recommendedEvents &&
                  recommendedEvents?.length > 0 && (
                    <Container fluid className="self-discovery-recommnded exe4">
                      <div className="recommended-section executive-market-events">
                        <div className="carousel executive-market-carousel-buttons">
                          {recommendedEvents &&
                            recommendedEvents.length > 0 && (
                              <Cirrus.CarouselWithButton
                                nextIconButtonProps={{
                                  icon:
                                    recommendedEvents &&
                                    recommendedEvents.length > 3
                                      ? "arrow_forward"
                                      : "",
                                  onClick: () => {},
                                  text: "Next",
                                  type: "button",
                                }}
                                heading={fetchExecutivePageTemplateLabel(
                                  "DashboardPage.DashboardPageTemplate.ExecutiveMarketConnectionEvents"
                                )}
                                button1Props={{
                                  children: fetchExecutiveCommonLabel(
                                    "CommonComponent.SeeAllEventsButton"
                                  ),
                                  isAlt: true,
                                  size: "Large",
                                  onClick: (e) => navigate("/events"),
                                }}
                                previousIconButtonProps={{
                                  icon:
                                    recommendedEvents &&
                                    recommendedEvents.length > 3
                                      ? "arrow_back"
                                      : "",
                                  onClick: function noRefCheck() {},
                                  text: "Previous",
                                  type: "button",
                                }}
                              >
                                {recommendedEvents
                                  .slice(
                                    0,
                                    recommendedEvents.length > 12
                                      ? 12
                                      : recommendedEvents.length
                                  )
                                  .map((allEvents, index) => {
                                    return (
                                      <Cirrus.RelatedResourcesCard
                                        title={allEvents.event_name}
                                        key={index}
                                        className="card"
                                        d
                                        description={getDescription(
                                          allEvents.event_description
                                        )}
                                        onCardClick={() => {
                                          window.open(
                                            `#/eventDetails/${
                                              allEvents?.event_id
                                            }/${
                                              allEvents?.isSaved
                                                ? allEvents?.isSaved
                                                : false
                                            }`,
                                            "_self"
                                          );
                                        }}
                                        tags={getTags(allEvents)}
                                        image={
                                          allEvents.image_thumbnail
                                            ? { src: allEvents.image_thumbnail }
                                            : {
                                                src: commonImage?.LargeImage
                                                  ?.ImageUrl,
                                              }
                                        }
                                        saveButton={{
                                          label: allEvents?.isSaved
                                            ? "Saved"
                                            : "Save",
                                          secondaryLabel: allEvents?.isSaved
                                            ? "Saved"
                                            : "Save",
                                          onClick: (e) => {
                                            updateEvents(allEvents);
                                          },
                                        }}
                                        type="event"
                                        layout="vertical"
                                        eventType={{
                                          eventDetails: [
                                            fetchLabel(allEvents.event_type),
                                          ],
                                          isSuccess: true,
                                          status: getEventStatus(allEvents),
                                        }}
                                      />
                                    );
                                  })}
                              </Cirrus.CarouselWithButton>
                            )}
                          {recommendedEvents?.length > 0 && (
                            <div className="mobile-view_recommended-section">
                              <Cirrus.Button
                                onClick={(e) => navigate("/events")}
                              >
                                {fetchExecutiveCommonLabel(
                                  "CommonComponent.SeeAllEventsButton"
                                )}
                              </Cirrus.Button>
                            </div>
                          )}
                        </div>
                      </div>
                    </Container>
                  )}
              </>
            )}
          </>
        )}

        {fetchPersona && fetchPersona?.personaName == "Executive" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) &&
              featuresConfig?.isJobMatchFeatureAvailable && (
                <div className="job-greetings">
                  <JobGreetings greetingCMS={greetingCMS && greetingCMS} />
                </div>
              )}
          </>
        )}

        {/* End Excecutive return view */}

        {/* below for Coaching session */}
        {displayMeetingConfirm && (
          <MeetingConfirmtionDialog
            fetchLabel={fetchLabel}
            cmsContent={cmsContent}
            mycoachData={mycoachData}
            myConfirmMeetingData={myConfirmMeetingData}
            closeDialog={setdisplayMeetingConfirm}
          ></MeetingConfirmtionDialog>
        )}

        {displayMeetingReschedule && (
          <MeetingRescheduleDialog
            fetchLabel={fetchLabel}
            cmsContent={cmsContent}
            mycoachData={mycoachData}
            dialogStatus={setdisplayMeetingReschedule}
            closeDialog={fnCloseRescheduleDailog}
            confirmDialogStatus={closeMeetingRescheduleConfirmation}
            closeconfirmDialog={setcloseMeetingRescheduleConfirmation}
            rescheduleMeetingData={myRescheduleMeetingData}
            ReloadComp={setcoachSessionReload}
            todayButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.TodayButton",
              "meetingSchedule_Labels"
            )}
            nextButtonLabel={fetchLabel(
              "CalendarPage.ScheduleAMeetingWithCoach.NextButton",
              "meetingSchedule_Labels"
            )}
            backButtonLabel={fetchLabel(
              "CommonComponent.CommonBackButton",
              "commonComponents_Labels"
            )}
          ></MeetingRescheduleDialog>
        )}

        {displayMeetingCancel && (
          <MeetingCancellationDailog
            fetchLabel={fetchLabelCoach}
            cmsContent={cmsContentCoach}
            mycoachData={mycoachData}
            closeDialog={setdisplayMeetingCancel}
            cancelMeetingData={myCancelMeetingData}
            ReloadComp={setcoachSessionReload}
            dialogStatus={setdisplayMeetingCancel}
          ></MeetingCancellationDailog>
        )}

        {displayUpcomingEventDetails && (
          <UpcomigEventDetails
            mycoachData={mycoachData}
            cmsCoachContent={cmsContent}
            cmsContentCoach={cmsContentCoach}
            calledFrom={"dashboard"}
            eventData={upcomingMeetingData}
            fetchLabel={fetchLabelCoach}
            fetchLabelCoach={fetchLabelCoach}
            closeDialog={setdisplayUpcomingEventDetails}
            ReloadComp={setcoachSessionReload}
            disableButtons={false}
            typeIsNotEvent={
              upcomingMeetingData?.location?.includes("docebosaas")
                ? false
                : true
            }
          ></UpcomigEventDetails>
        )}

        {/* Independent First view start */}

        {/* Intro-video section start */}
        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Independent" && (
            <>
              {programVideoData &&
                programVideoData?.length == 0 &&
                isResumeUploaded == false && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={DashboardWidgetDataCMS?.description}
                      heading={DashboardWidgetDataCMS?.title}
                      imageProps={{
                        src: DashboardWidgetDataCMS?.imageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchDashboardLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                      playButton={true}
                    />
                  </div>
                )}
            </>
          )}

        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {programVideoRewatch && (
              <Cirrus.VideoLightbox
                closeButtonText="Close lightbox"
                controls
                //description={DashboardWidgetDataCMS?.description}
                // mp4Src={
                //   DashboardWidgetDataCMS &&
                //   DashboardWidgetDataCMS?.videoList &&
                //   DashboardWidgetDataCMS?.videoList[0] &&
                //   DashboardWidgetDataCMS?.videoList[0]?.ComponentMediaUrl
                // }
                mp4Src={videoURL?.MediaUrl}
                onClose={() => {
                  fnOpenCloseProgramVideoRewatch(1);
                }}
                onPlay={() => programVideoHandlerIndependent()}
                playButtonText="Play video"
                // posterImageUrl={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
                posterImageUrl={videoURL?.VideoThumbnail}
                videoTitle="Sample video"
                subtitle={videoURL?.VideoSubtitleFile}
              />
            )}
          </>
        )}
        {/* Intro-video section end */}

        {/** Recomnded for you - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false &&
              recommdedAssessmentCardData &&
              recommdedAssessmentCardData?.length > 0 && (
                <Container fluid className="self-discovery-recommnded">
                  <div className="recommended-section">
                    <div className="carousel discover-carousel-buttons">
                      {recommdedAssessmentCardData && (
                        <Cirrus.CarouselWithButton
                          nextIconButtonProps={{
                            icon: "arrow_forward",
                            onClick: () => {},
                            text: "Next",
                            type: "button",
                          }}
                          button1Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => navigate("/events"),
                          }}
                          button2Props={{
                            children: fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            ),
                            isAlt: true,
                            size: "Large",
                            onClick: (e) => {
                              redirectToResource();
                            },
                          }}
                          heading={RecommendedForYouCMS?.title}
                          subheading={RecommendedForYouCMS?.description}
                          previousIconButtonProps={{
                            icon: "arrow_back",
                            onClick: function noRefCheck() {},
                            text: "Previous",
                            type: "button",
                          }}
                        >
                          {recommdedAssessmentCardData &&
                            recommdedAssessmentCardData?.map((item, idx) => (
                              <div
                                onClick={() =>
                                  item?.key === "Tools.SmartResumeEditor"
                                    ? saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                    : saveActivity(
                                        item?.resourceId,
                                        "Tool",
                                        item?.title
                                      )
                                }
                              >
                                <Cirrus.RecommndedResourcesCard
                                  className="card"
                                  title={item && item.title && item.title}
                                  description={
                                    item &&
                                    item.shortDescription &&
                                    item.shortDescription
                                  }
                                  onCardClick={() => {
                                    cardRedirectHandler(item);
                                  }}
                                  tags={item.tags}
                                  image={{
                                    src: item && item?.largeImage?.imageUrl,
                                  }}
                                  saveButton={{
                                    label: fetchLikeLabel(
                                      "CommonComponent.CommonSaveButton"
                                    ),
                                    secondaryLabel: fetchLikeLabel(
                                      "CommonComponent.CommonSavedButton"
                                    ),
                                    onClick: (e) => {
                                      saveAssessmentCard(
                                        e,
                                        item &&
                                          item.resourceId &&
                                          item.resourceId,
                                        item && item.isSaved && item.isSaved,
                                        item.isLiked && item.isLiked,
                                        "save",
                                        item &&
                                          item.learningResourceStatus &&
                                          item.learningResourceStatus,
                                        item.lastAnsweredPageId &&
                                          item.lastAnsweredPageId,
                                        item?.resourceType?.id,
                                        item?.resourceCategoryType?.id
                                      );
                                    },
                                  }}
                                  isSaved={item && item.isSaved && item.isSaved}
                                  status={{
                                    name: "new",
                                    label:
                                      item &&
                                      item.resourceType &&
                                      item.resourceType.value
                                        ? item.resourceType.value
                                        : "Assessment",
                                    value:
                                      item &&
                                      item.learningResourceStatus ===
                                        "notstarted"
                                        ? item && item.duration && item.duration
                                        : fetchLearningStatus(
                                            item && item.learningResourceStatus
                                          ),
                                  }}
                                  likeText={
                                    item?.likeCount == 0
                                      ? fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount == 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikeText"
                                        )
                                      : item?.likeCount > 1
                                      ? item?.likeCount +
                                        " " +
                                        fetchLikeLabel(
                                          "CommonComponent.CommonLikesText"
                                        )
                                      : ""
                                  }
                                  onLikeButtonClick={(e) => {
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item.isSaved && item.isSaved,
                                      item && item.isLiked && item.isLiked,
                                      "like",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    );
                                    saveActivity2(item);
                                  }}
                                  isLiked={item && item.isLiked && item.isLiked}
                                  type="assessment"
                                  layout="horizontal"
                                />
                              </div>
                            ))}
                        </Cirrus.CarouselWithButton>
                      )}
                      {recommdedAssessmentCardData && (
                        <div className="mobile-view_recommended-section">
                          <Cirrus.Button onClick={(e) => navigate("/events")}>
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllEventsButton"
                            )}
                          </Cirrus.Button>
                          <Cirrus.Button
                            onClick={(e) => {
                              redirectToResource();
                            }}
                          >
                            {fetchLikeLabel(
                              "CommonComponent.SeeAllResourcesButton"
                            )}
                          </Cirrus.Button>
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
              )}
          </>
        )}
        {/** Recomnded for you - end*/}
        {/** Learning resources - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <Container
                  fluid
                  className="background-White learning-collections"
                >
                  <Row>
                    <Cirrus.Header
                      className="heading heading_learning-collections"
                      size="h5"
                    >
                      {learningCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="description description_learning-collections mx-auto">
                      {learningCollectionCMS?.description}
                    </p>
                    <div className="resource-card">
                      {learningCollectionCMS &&
                        learningCollectionCMS.pageContent &&
                        learningCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.LearningCollectionCard
                              title={item && item.Title && item.Title}
                              description={
                                item && item.Description && item.Description
                              }
                              link={{
                                url: `/#${item && item.PageUrl?.Url}`,
                              }}
                              image={{
                                src: item && item?.LargeImage?.ImageUrl,
                              }}
                              status={{
                                name: "new",
                                label:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[0] &&
                                  item.Count[0]?.ResourceCount &&
                                  item.Count[0]?.Resource
                                    ? item.Count[0]?.ResourceCount <= 50
                                      ? item.Count[0]?.ResourceCount +
                                        " " +
                                        item.Count[0]?.Resource
                                      : "50+" + " " + item.Count[0]?.Resource
                                    : "",
                                label2:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[1] &&
                                  item.Count[1]?.ResourceCount &&
                                  item.Count[1]?.Resource
                                    ? item.Count[1]?.ResourceCount <= 50
                                      ? item.Count[1]?.ResourceCount +
                                        " " +
                                        item.Count[1]?.Resource
                                      : "50+" + " " + item.Count[1]?.Resource
                                    : "",
                                label3:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[2] &&
                                  item.Count[2]?.ResourceCount &&
                                  item.Count[2]?.Resource
                                    ? item.Count[2]?.ResourceCount <= 50
                                      ? item.Count[2]?.ResourceCount +
                                        " " +
                                        item.Count[2]?.Resource
                                      : "50+" + " " + item.Count[2]?.Resource
                                    : "",
                              }}
                              type="assessment"
                              layout="horizontal"
                            />
                          </div>
                        ))}
                    </div>
                    <div className="more-collections">
                      <Cirrus.Header size="h5">
                        {moreCollectionCMS?.title}
                      </Cirrus.Header>
                      <p className="more-collection-desc">
                        {moreCollectionCMS?.description}
                      </p>
                      <div className="button-container">
                        {moreCollectionCMS &&
                          moreCollectionCMS.pageContent &&
                          moreCollectionCMS.pageContent.map((item, idx) => (
                            <div>
                              <Cirrus.Button
                                className="more-collection-button"
                                isSecondary
                                onClick={() => {
                                  navigate(`${item && item.PageUrl?.Url}`);
                                }}
                              >
                                {item && item.Title}
                              </Cirrus.Button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Row>
                </Container>
              )}
          </>
        )}
        {/** Learning resources - end*/}
        {/** Resume - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <MyResume
                  myResumeCMS={myResumeCMS && myResumeCMS}
                  documentsLists={documentsLists}
                  setDocumentsLists={setDocumentsLists}
                  getAllDocumentsLists={getAllDocumentsLists}
                  resumeBuilderData={resumeBuilderData}
                  resumeBuilderToolUrl={resumeBuilderToolUrl}
                  isResumeUploaded={isResumeUploaded}
                />
              )}
          </>
        )}
        {/** Resume - end*/}
        {/** Job Matchsetup, Job Greetings, My documents - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <>
                  {isResumeUploaded ? (
                    <MyDocumentsReturnVisit
                      documentRVCMS={documentRVCMS && documentRVCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : documentCMS && documentCMS?.labels?.length > 0 ? (
                    <MyDocument
                      documentCMS={documentCMS && documentCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : (
                    ""
                  )}
                  {featuresConfig?.isJobMatchFeatureAvailable && (
                    <>
                      {isResumeUploaded ? (
                        <div className="job-greetings">
                          <JobGreetings
                            greetingCMS={greetingCMS && greetingCMS}
                          />
                        </div>
                      ) : jobMatchCMS && jobMatchCMS?.labels?.length > 0 ? (
                        <JobMatchSetup
                          jobMatchCMS={jobMatchCMS && jobMatchCMS}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
          </>
        )}
        {/** Job Matchsetup, Job Greetings, My documents - end*/}

        {/* Independent First view end */}

        {/* Independent Return view start */}

        {/** Recomnded for you - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <Container fluid className="self-discovery-recommnded">
                <div className="recommended-section">
                  <div className="carousel discover-carousel-buttons independent-recommended">
                    {recommdedAssessmentCardData && (
                      <Cirrus.CarouselWithButton
                        nextIconButtonProps={{
                          icon: "arrow_forward",
                          onClick: () => {},
                          text: "Next",
                          type: "button",
                        }}
                        button1Props={{
                          children: fetchLikeLabel(
                            "CommonComponent.SeeAllEventsButton"
                          ),
                          isAlt: true,
                          size: "Large",
                          onClick: (e) => navigate("/events"),
                        }}
                        button2Props={{
                          children: fetchLikeLabel(
                            "CommonComponent.SeeAllResourcesButton"
                          ),
                          isAlt: true,
                          size: "Large",
                          onClick: (e) => {
                            redirectToResource();
                          },
                        }}
                        heading={RecommendedForYouCMS?.title}
                        subheading={RecommendedForYouCMS?.description}
                        previousIconButtonProps={{
                          icon: "arrow_back",
                          onClick: function noRefCheck() {},
                          text: "Previous",
                          type: "button",
                        }}
                      >
                        {recommdedAssessmentCardData &&
                          recommdedAssessmentCardData?.map((item, idx) => (
                            <div
                              onClick={() =>
                                item?.key === "Tools.SmartResumeEditor"
                                  ? saveActivity(
                                      item?.resourceId,
                                      "Tool",
                                      item?.title
                                    )
                                  : saveActivity(
                                      item?.resourceId,
                                      "Tool",
                                      item?.title
                                    )
                              }
                            >
                              <Cirrus.RecommndedResourcesCard
                                className="card"
                                title={item && item.title && item.title}
                                description={
                                  item &&
                                  item.shortDescription &&
                                  item.shortDescription
                                }
                                onCardClick={() => {
                                  cardRedirectHandler(item);
                                }}
                                tags={item.tags}
                                image={{
                                  src: item && item?.largeImage?.imageUrl,
                                }}
                                saveButton={{
                                  label: fetchLikeLabel(
                                    "CommonComponent.CommonSaveButton"
                                  ),
                                  secondaryLabel: fetchLikeLabel(
                                    "CommonComponent.CommonSavedButton"
                                  ),
                                  onClick: (e) => {
                                    saveAssessmentCard(
                                      e,
                                      item &&
                                        item.resourceId &&
                                        item.resourceId,
                                      item && item.isSaved && item.isSaved,
                                      item.isLiked && item.isLiked,
                                      "save",
                                      item &&
                                        item.learningResourceStatus &&
                                        item.learningResourceStatus,
                                      item.lastAnsweredPageId &&
                                        item.lastAnsweredPageId,
                                      item?.resourceType?.id,
                                      item?.resourceCategoryType?.id
                                    );
                                  },
                                }}
                                isSaved={item && item.isSaved && item.isSaved}
                                status={{
                                  name: "new",
                                  label:
                                    item &&
                                    item.resourceType &&
                                    item.resourceType.value
                                      ? item.resourceType.value
                                      : "Assessment",
                                  value:
                                    item &&
                                    item.learningResourceStatus === "notstarted"
                                      ? item && item.duration && item.duration
                                      : fetchLearningStatus(
                                          item && item.learningResourceStatus
                                        ),
                                }}
                                likeText={
                                  item?.likeCount == 0
                                    ? fetchLikeLabel(
                                        "CommonComponent.CommonLikeText"
                                      )
                                    : item?.likeCount == 1
                                    ? item?.likeCount +
                                      " " +
                                      fetchLikeLabel(
                                        "CommonComponent.CommonLikeText"
                                      )
                                    : item?.likeCount > 1
                                    ? item?.likeCount +
                                      " " +
                                      fetchLikeLabel(
                                        "CommonComponent.CommonLikesText"
                                      )
                                    : ""
                                }
                                onLikeButtonClick={(e) => {
                                  saveAssessmentCard(
                                    e,
                                    item && item.resourceId && item.resourceId,
                                    item.isSaved && item.isSaved,
                                    item && item.isLiked && item.isLiked,
                                    "like",
                                    item &&
                                      item.learningResourceStatus &&
                                      item.learningResourceStatus,
                                    item.lastAnsweredPageId &&
                                      item.lastAnsweredPageId,
                                    item?.resourceType?.id,
                                    item?.resourceCategoryType?.id
                                  );
                                  saveActivity2(item);
                                }}
                                isLiked={item && item.isLiked && item.isLiked}
                                type="assessment"
                                layout="horizontal"
                              />
                            </div>
                          ))}
                      </Cirrus.CarouselWithButton>
                    )}
                    {/* </div> */}
                    {recommdedAssessmentCardData && (
                      <div className="mobile-view_recommended-section">
                        <Cirrus.Button onClick={(e) => navigate("/events")}>
                          {fetchLikeLabel("CommonComponent.SeeAllEventsButton")}
                        </Cirrus.Button>
                        <Cirrus.Button
                          onClick={(e) => {
                            redirectToResource();
                          }}
                        >
                          {fetchLikeLabel(
                            "CommonComponent.SeeAllResourcesButton"
                          )}
                        </Cirrus.Button>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            )}
          </>
        )}
        {/** Recomnded for you - end*/}
        {/** Learning resources - start*/}
        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <Container
                fluid
                className="background-White learning-collections"
              >
                <Row>
                  <Cirrus.Header
                    className="heading heading_learning-collections"
                    size="h5"
                  >
                    {learningCollectionCMS?.title}
                  </Cirrus.Header>
                  <p className="description description_learning-collections mx-auto">
                    {learningCollectionCMS?.description}
                  </p>
                  <div className="resource-card">
                    {learningCollectionCMS &&
                      learningCollectionCMS.pageContent &&
                      learningCollectionCMS.pageContent.map((item, idx) => (
                        <div>
                          <Cirrus.LearningCollectionCard
                            title={item && item.Title && item.Title}
                            description={
                              item && item.Description && item.Description
                            }
                            link={{
                              url: `/#${item && item.PageUrl?.Url}`,
                            }}
                            image={{ src: item && item?.LargeImage?.ImageUrl }}
                            status={{
                              name: "new",
                              label:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[0] &&
                                item.Count[0]?.ResourceCount &&
                                item.Count[0]?.Resource
                                  ? item.Count[0]?.ResourceCount <= 50
                                    ? item.Count[0]?.ResourceCount +
                                      " " +
                                      item.Count[0]?.Resource
                                    : "50+" + " " + item.Count[0]?.Resource
                                  : "",
                              label2:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[1] &&
                                item.Count[1]?.ResourceCount &&
                                item.Count[1]?.Resource
                                  ? item.Count[1]?.ResourceCount <= 50
                                    ? item.Count[1]?.ResourceCount +
                                      " " +
                                      item.Count[1]?.Resource
                                    : "50+" + " " + item.Count[1]?.Resource
                                  : "",
                              label3:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[2] &&
                                item.Count[2]?.ResourceCount &&
                                item.Count[2]?.Resource
                                  ? item.Count[2]?.ResourceCount <= 50
                                    ? item.Count[2]?.ResourceCount +
                                      " " +
                                      item.Count[2]?.Resource
                                    : "50+" + " " + item.Count[2]?.Resource
                                  : "",
                            }}
                            type="assessment"
                            layout="horizontal"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="more-collections">
                    <Cirrus.Header size="h5">
                      {moreCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="more-collection-desc">
                      {moreCollectionCMS?.description}
                    </p>
                    <div className="button-container">
                      {moreCollectionCMS &&
                        moreCollectionCMS.pageContent &&
                        moreCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.Button
                              className="more-collection-button"
                              isSecondary
                              onClick={() => {
                                navigate(`${item && item.PageUrl?.Url}`);
                              }}
                            >
                              {item && item.Title}
                            </Cirrus.Button>
                          </div>
                        ))}
                    </div>
                  </div>
                </Row>
              </Container>
            )}
          </>
        )}
        {/** Learning resources - end*/}
        {/** My targets - start*/}
        {/** My targets - end*/}
        {/** Resume - start*/}

        {fetchPersona && fetchPersona?.personaName == "Independent" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <MyResume
                myResumeCMS={myResumeCMS && myResumeCMS}
                documentsLists={documentsLists}
                setDocumentsLists={setDocumentsLists}
                getAllDocumentsLists={getAllDocumentsLists}
                resumeBuilderData={resumeBuilderData}
                resumeBuilderToolUrl={resumeBuilderToolUrl}
                isResumeUploaded={isResumeUploaded}
              />
            )}
          </>
        )}
        {/** Resume - end*/}
        {/** Job Matchsetup, Job Greetings, My documents - start*/}
        {fetchPersona &&
          fetchPersona?.personaName == "Independent" &&
          ((programVideoData && programVideoData?.length > 0) ||
            isResumeUploaded) && (
            <>
              {
                <>
                  {isResumeUploaded ? (
                    <MyDocumentsReturnVisit
                      documentRVCMS={documentRVCMS && documentRVCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : documentCMS && documentCMS?.labels?.length > 0 ? (
                    <MyDocument
                      documentCMS={documentCMS && documentCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : (
                    ""
                  )}
                  {featuresConfig?.isJobMatchFeatureAvailable && (
                    <>
                      {isResumeUploaded ? (
                        <div className="job-greetings">
                          <JobGreetings
                            greetingCMS={greetingCMS && greetingCMS}
                          />
                        </div>
                      ) : jobMatchCMS && jobMatchCMS?.labels?.length > 0 ? (
                        <JobMatchSetup
                          jobMatchCMS={jobMatchCMS && jobMatchCMS}
                        />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              }
            </>
          )}
        {/** Job Matchsetup, Job Greetings, My documents - end*/}

        {/* Independent Return view end */}

        {/* Professional First view start */}

        {/* Intro-video section start */}
        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Professional" && (
            <>
              {programVideoData &&
                programVideoData?.length == 0 &&
                isResumeUploaded == false && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={DashboardWidgetDataCMS?.description}
                      heading={DashboardWidgetDataCMS?.title}
                      imageProps={{
                        src: DashboardWidgetDataCMS?.imageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchDashboardLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                    />
                  </div>
                )}
            </>
          )}

        {fetchPersona &&
          fetchPersona?.personaName == "Professional" &&
          programVideoRewatch && (
            <Cirrus.VideoLightbox
              closeButtonText="Close lightbox"
              controls
              //description={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
              // mp4Src={
              //   DashboardWidgetDataCMS &&
              //   DashboardWidgetDataCMS?.videoList &&
              //   DashboardWidgetDataCMS?.videoList[0] &&
              //   DashboardWidgetDataCMS?.videoList[0].ComponentMediaUrl
              // }
              mp4Src={videoURL?.MediaUrl}
              onClose={() => {
                fnOpenCloseProgramVideoRewatch(1);
              }}
              onPlay={() => programVideoHandlerIndependent()}
              playButtonText="Play video"
              // posterImageUrl={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
              posterImageUrl={videoURL?.VideoThumbnail}
              videoTitle="Sample video"
              subtitle={videoURL?.VideoSubtitleFile}
            />
          )}
        {/* Intro-video section end */}

        {/** Job Matchsetup, Job Greetings - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false &&
              featuresConfig?.isJobMatchFeatureAvailable && (
                <>
                  {isResumeUploaded ? (
                    <div className="job-greetings">
                      <JobGreetings greetingCMS={greetingCMS && greetingCMS} />
                    </div>
                  ) : jobMatchCMS && jobMatchCMS?.labels?.length > 0 ? (
                    <JobMatchSetup jobMatchCMS={jobMatchCMS && jobMatchCMS} />
                  ) : (
                    ""
                  )}
                </>
              )}
          </>
        )}
        {/** Job Matchsetup, Job Greetings - end*/}

        {/** Learning resources - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <Container
                  fluid
                  className="background-White learning-collections"
                >
                  <Row>
                    <Cirrus.Header
                      className="heading heading_learning-collections"
                      size="h5"
                    >
                      {learningCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="description description_learning-collections mx-auto">
                      {learningCollectionCMS?.description}
                    </p>
                    <div className="resource-card">
                      {learningCollectionCMS &&
                        learningCollectionCMS.pageContent &&
                        learningCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.LearningCollectionCard
                              title={item && item.Title && item.Title}
                              description={
                                item && item.Description && item.Description
                              }
                              link={{
                                url: `/#${item && item.PageUrl?.Url}`,
                              }}
                              image={{
                                src: item && item?.LargeImage?.ImageUrl,
                              }}
                              status={{
                                name: "new",
                                label:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[0] &&
                                  item.Count[0]?.ResourceCount &&
                                  item.Count[0]?.Resource
                                    ? item.Count[0]?.ResourceCount <= 50
                                      ? item.Count[0]?.ResourceCount +
                                        " " +
                                        item.Count[0]?.Resource
                                      : "50+" + " " + item.Count[0]?.Resource
                                    : "",
                                label2:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[1] &&
                                  item.Count[1]?.ResourceCount &&
                                  item.Count[1]?.Resource
                                    ? item.Count[1]?.ResourceCount <= 50
                                      ? item.Count[1]?.ResourceCount +
                                        " " +
                                        item.Count[1]?.Resource
                                      : "50+" + " " + item.Count[1]?.Resource
                                    : "",
                                label3:
                                  item.Count &&
                                  item.Count.length > 1 &&
                                  item?.Count[2] &&
                                  item.Count[2]?.ResourceCount &&
                                  item.Count[2]?.Resource
                                    ? item.Count[2]?.ResourceCount <= 50
                                      ? item.Count[2]?.ResourceCount +
                                        " " +
                                        item.Count[2]?.Resource
                                      : "50+" + " " + item.Count[2]?.Resource
                                    : "",
                              }}
                              type="assessment"
                              layout="horizontal"
                            />
                          </div>
                        ))}
                    </div>
                    <div className="more-collections">
                      <Cirrus.Header size="h5">
                        {moreCollectionCMS?.title}
                      </Cirrus.Header>
                      <p className="more-collection-desc">
                        {moreCollectionCMS?.description}
                      </p>
                      <div className="button-container">
                        {moreCollectionCMS &&
                          moreCollectionCMS.pageContent &&
                          moreCollectionCMS.pageContent.map((item, idx) => (
                            <div>
                              <Cirrus.Button
                                className="more-collection-button"
                                isSecondary
                                onClick={() => {
                                  navigate(`${item && item.PageUrl?.Url}`);
                                }}
                              >
                                {item && item.Title}
                              </Cirrus.Button>
                            </div>
                          ))}
                      </div>
                    </div>
                  </Row>
                </Container>
              )}
          </>
        )}
        {/** Learning resources - end*/}

        {/** Resume - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <MyResume
                  myResumeCMS={myResumeCMS && myResumeCMS}
                  documentsLists={documentsLists}
                  setDocumentsLists={setDocumentsLists}
                  getAllDocumentsLists={getAllDocumentsLists}
                  resumeBuilderData={resumeBuilderData}
                  resumeBuilderToolUrl={resumeBuilderToolUrl}
                  isResumeUploaded={isResumeUploaded}
                />
              )}
          </>
        )}
        {/** Resume - end*/}

        {/**  My documents - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {programVideoData &&
              programVideoData?.length == 0 &&
              isResumeUploaded == false && (
                <>
                  {isResumeUploaded ? (
                    <MyDocumentsReturnVisit
                      documentRVCMS={documentRVCMS && documentRVCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : documentCMS && documentCMS?.labels?.length > 0 ? (
                    <MyDocument
                      documentCMS={documentCMS && documentCMS}
                      documentsLists={documentsLists}
                      setDocumentsLists={setDocumentsLists}
                      getAllDocumentsLists={getAllDocumentsLists}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
          </>
        )}
        {/** My documents - end*/}

        {/* Professional First view end */}

        {/* Professional Return view start */}

        {/** Job Matchsetup, Job Greetings - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) &&
              featuresConfig?.isJobMatchFeatureAvailable && (
                <>
                  {isResumeUploaded ? (
                    <div className="job-greetings">
                      <JobGreetings greetingCMS={greetingCMS && greetingCMS} />
                    </div>
                  ) : jobMatchCMS && jobMatchCMS?.labels?.length > 0 ? (
                    <JobMatchSetup jobMatchCMS={jobMatchCMS && jobMatchCMS} />
                  ) : (
                    ""
                  )}
                </>
              )}
          </>
        )}
        {/** Job Matchsetup, Job Greetings - end*/}

        {/** Learning resources - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <Container
                fluid
                className="background-White learning-collections"
              >
                <Row>
                  <Cirrus.Header
                    className="heading heading_learning-collections"
                    size="h5"
                  >
                    {learningCollectionCMS?.title}
                  </Cirrus.Header>
                  <p className="description description_learning-collections mx-auto">
                    {learningCollectionCMS?.description}
                  </p>
                  <div className="resource-card">
                    {learningCollectionCMS &&
                      learningCollectionCMS.pageContent &&
                      learningCollectionCMS.pageContent.map((item, idx) => (
                        <div>
                          <Cirrus.LearningCollectionCard
                            title={item && item.Title && item.Title}
                            description={
                              item && item.Description && item.Description
                            }
                            link={{
                              url: `/#${item && item.PageUrl?.Url}`,
                            }}
                            image={{ src: item && item?.LargeImage?.ImageUrl }}
                            status={{
                              name: "new",
                              label:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[0] &&
                                item.Count[0]?.ResourceCount &&
                                item.Count[0]?.Resource
                                  ? item.Count[0]?.ResourceCount <= 50
                                    ? item.Count[0]?.ResourceCount +
                                      " " +
                                      item.Count[0]?.Resource
                                    : "50+" + " " + item.Count[0]?.Resource
                                  : "",
                              label2:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[1] &&
                                item.Count[1]?.ResourceCount &&
                                item.Count[1]?.Resource
                                  ? item.Count[1]?.ResourceCount <= 50
                                    ? item.Count[1]?.ResourceCount +
                                      " " +
                                      item.Count[1]?.Resource
                                    : "50+" + " " + item.Count[1]?.Resource
                                  : "",
                              label3:
                                item.Count &&
                                item.Count.length > 1 &&
                                item?.Count[2] &&
                                item.Count[2]?.ResourceCount &&
                                item.Count[2]?.Resource
                                  ? item.Count[2]?.ResourceCount <= 50
                                    ? item.Count[2]?.ResourceCount +
                                      " " +
                                      item.Count[2]?.Resource
                                    : "50+" + " " + item.Count[2]?.Resource
                                  : "",
                            }}
                            type="assessment"
                            layout="horizontal"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="more-collections">
                    <Cirrus.Header size="h5">
                      {moreCollectionCMS?.title}
                    </Cirrus.Header>
                    <p className="more-collection-desc">
                      {moreCollectionCMS?.description}
                    </p>
                    <div className="button-container">
                      {moreCollectionCMS &&
                        moreCollectionCMS.pageContent &&
                        moreCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.Button
                              className="more-collection-button"
                              isSecondary
                              onClick={() => {
                                navigate(`${item && item.PageUrl?.Url}`);
                              }}
                            >
                              {item && item.Title}
                            </Cirrus.Button>
                          </div>
                        ))}
                    </div>
                  </div>
                </Row>
              </Container>
            )}
          </>
        )}
        {/** Learning resources - end*/}

        {/** Resume - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <MyResume
                myResumeCMS={myResumeCMS && myResumeCMS}
                documentsLists={documentsLists}
                setDocumentsLists={setDocumentsLists}
                getAllDocumentsLists={getAllDocumentsLists}
                resumeBuilderData={resumeBuilderData}
                resumeBuilderToolUrl={resumeBuilderToolUrl}
                isResumeUploaded={isResumeUploaded}
              />
            )}
          </>
        )}
        {/** Resume - end*/}

        {/**  My documents - start*/}
        {fetchPersona && fetchPersona?.personaName == "Professional" && (
          <>
            {((programVideoData && programVideoData?.length > 0) ||
              isResumeUploaded) && (
              <>
                {isResumeUploaded ? (
                  <MyDocumentsReturnVisit
                    documentRVCMS={documentRVCMS && documentRVCMS}
                    documentsLists={documentsLists}
                    setDocumentsLists={setDocumentsLists}
                    getAllDocumentsLists={getAllDocumentsLists}
                  />
                ) : documentCMS && documentCMS?.labels?.length > 0 ? (
                  <MyDocument
                    documentCMS={documentCMS && documentCMS}
                    documentsLists={documentsLists}
                    setDocumentsLists={setDocumentsLists}
                    getAllDocumentsLists={getAllDocumentsLists}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
        {/** My documents - end*/}

        {!noClientSpecificContent &&
          dashboardWidgetEnable &&
          fetchPersona &&
          fetchPersona?.personaName == "Professional" && (
            <>
              {programVideoData &&
                programVideoData?.length > 0 &&
                (featuresConfig?.isJobMatchFeatureAvailable === true
                  ? isResumeUploaded
                  : true) && (
                  <div className="p-1 w-100 rounded-right dashboard_featureVedio">
                    <Cirrus.FeaturedCard
                      bodyText={DashboardWidgetDataCMS?.description}
                      heading={DashboardWidgetDataCMS?.title}
                      imageProps={{
                        src: DashboardWidgetDataCMS?.imageList?.LargeImage
                          ?.ImageUrl,
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                      isWide
                      primaryButtonProps={{
                        children: fetchDashboardLabel(
                          "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                        ),
                        leadingIcon: "play",
                        onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                      }}
                    />
                  </div>
                )}
            </>
          )}

        {/* Professional Return view end */}

        {/* "Career Program" && "Entrepreneur" Persona Starts - Both first view & Return View */}
        {/* "What to Expect" - Card Starts */}
        {((fetchPersona && fetchPersona?.personaName == "CareerProgram") ||
          fetchPersona?.personaName == "Entrepreneur") && (
          <>
            {/* {
            programVideoData && programVideoData?.length == 0 && isResumeUploaded == false && */}
            <div className="p-1 w-100 rounded-right dashboard_featureVedio">
              <Cirrus.FeaturedCard
                bodyText={DashboardWidgetDataCMS?.description}
                heading={DashboardWidgetDataCMS?.title}
                imageProps={{
                  src: DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl,
                  onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                }}
                isWide
                primaryButtonProps={{
                  children: fetchDashboardLabel(
                    "DashboardPage.DashboardGettingStarted.WatchIntroVideo"
                  ),
                  leadingIcon: "play",
                  onClick: (e) => fnOpenCloseProgramVideoRewatch(0),
                }}
                playButton={true}
              />
            </div>
            {/* } */}
          </>
        )}
        {/* Video LightBox popup */}
        {((fetchPersona && fetchPersona?.personaName == "CareerProgram") ||
          fetchPersona?.personaName == "Entrepreneur") &&
          programVideoRewatch && (
            <Cirrus.VideoLightbox
              closeButtonText="Close lightbox"
              controls
              // description={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
              mp4Src={videoURL?.MediaUrl}
              onClose={() => {
                fnOpenCloseProgramVideoRewatch(1);
              }}
              onPlay={() => programVideoHandler()}
              playButtonText="Play video"
              // posterImageUrl={DashboardWidgetDataCMS?.imageList?.LargeImage?.ImageUrl}
              posterImageUrl={videoURL?.VideoThumbnail}
              videoTitle="Sample video"
              subtitle={videoURL?.VideoSubtitleFile}
            />
          )}
        {/* "What to Expect" - Card Ends */}

        {/* Skills portal section Start */}
        {showDocebo && (
          <>
            {((fetchPersona && fetchPersona?.personaName == "CareerProgram") ||
              fetchPersona?.personaName == "Entrepreneur") && (
              <Container fluid>
                <Row>
                  <Col>
                    {/* <h2 className="mb-4">Skills Portal</h2> */}
                    <div className="resource-card skills docebo-skills">
                      <Cirrus.DoceboCard
                        title={dashboardSkillsPortalCard?.title}
                        description={dashboardSkillsPortalCard?.description}
                        image={{
                          src: dashboardSkillsPortalCard?.image?.ImageUrl,
                        }}
                        primaryButtonProps={{
                          children: fetchLRLabels(
                            "DashboardPage.DashboardSkillsPortalCard.VisitSkillsPortal"
                          ),
                          size: "Large",
                          href: doceboURL,
                          isAlt: true,
                          leadingIcon: "open_in_new",
                          target: "_blank",
                        }}
                        tags={[
                          fetchLRLabels(
                            "DashboardPage.DashboardSkillsPortalCard.500PlusResources"
                          ),
                          fetchLRLabels(
                            "DashboardPage.DashboardSkillsPortalCard.ExternalResource"
                          ),
                        ]}
                        isWide={true}
                        className="clsHeading"
                        type="event"
                        layout="horizontal"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            )}
          </>
        )}
        {/* Skills portal section End */}

        {/* Calendar Starts */}
        {fetchPersona && fetchPersona?.personaName == "Entrepreneur" && (
          <>
            {showCalender == true &&
              Object.keys(upcomingMeetings)?.length == 0 &&
              Object.keys(meetingInvites)?.length == 0 &&
              myCoachStatusCheck &&
              myCoachStatusCheck == 204 && (
                <div className="dashboard-coach-container">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    // cmsData={excecutiveCMSData && excecutiveCMSData?.myCalanderLabel}
                    cmsData={cmsContentCoach?.coachingSessions}
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "commonComponents_Labels"
                    )}
                    removeCompltedEvent={true}
                    // myCalanderTitle={excecutiveCMSData && excecutiveCMSData?.myCalanderTitle}
                    myCalanderTitle={cmsContent && cmsContent?.myCalanderTitle}
                    calledFrom={"dashboardNoCoach"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                    myCalanderDescription={
                      cmsContent && cmsContent?.myCalanderDescription
                    }
                    myCalanderLabel={cmsContent && cmsContent?.myCalanderLabel}
                  />
                </div>
              )}
          </>
        )}
        {fetchPersona && fetchPersona?.personaName == "Entrepreneur" && (
          <>
            {showCalender == true &&
              upcomingMeetings &&
              meetingInvites &&
              (Object.keys(upcomingMeetings)?.length > 0 ||
                Object.keys(meetingInvites)?.length > 0) && (
                <div className="dashboard-coach-container dash-event">
                  <CoachingSessions
                    openConfirmation={setdisplayMeetingConfirm}
                    openReschedule={setdisplayMeetingReschedule}
                    cancelMeeting={setdisplayMeetingCancel}
                    cmsData={cmsContentCoach?.coachingSessions}
                    GetConfirmMeetingData={fnGetConfirmMeetingData}
                    GetReshceduleMeetingData={fnGetReshceduleMeetingData}
                    GetCancelMeetingData={fnGetCancelMeetingData}
                    coachSessionReload={coachSessionReload}
                    openUpcomingEventDetailDlg={setdisplayUpcomingEventDetails}
                    GetUpcomingMeetingData={fnGetUpcomingMeetingData}
                    CommomCompViewAllButtonText={fetchLabel(
                      "CommonComponent.CommonViewAll",
                      "coach"
                    )}
                    removeCompltedEvent={true}
                    myCalanderTitle={cmsContent && cmsContent?.myCalanderTitle}
                    calledFrom={"dashboard"}
                    meetingInvite={meetingInvites}
                    upcomingMeeting={upcomingMeetings}
                  />
                </div>
              )}
          </>
        )}
        {/* Calendar Ends */}

        {/** Recomnded for you - start*/}
        {/* {(fetchPersona && fetchPersona?.personaName == "Entrepreneur") && <>
            <Container fluid className="self-discovery-recommnded">
              <div className="recommended-section">
                <div className="carousel discover-carousel-buttons entrepreneur-recommended">
                  {recommdedAssessmentCardData && (
                    <Cirrus.CarouselWithButton
                      nextIconButtonProps={{
                        icon: "arrow_forward",
                        onClick: () => { },
                        text: "Next",
                        type: "button",
                      }}
                      button1Props={{
                        children: fetchLikeLabel(
                          "CommonComponent.SeeAllEventsButton"
                        ),
                        isAlt: true,
                        size: "Large",
                        onClick: (e) => navigate("/events"),
                      }}
                      button2Props={{
                        children: fetchLikeLabel(
                          "CommonComponent.SeeAllResourcesButton"
                        ),
                        isAlt: true,
                        size: "Large",
                        onClick: (e) => { redirectToResource() },
                      }}
                      heading={RecommendedForYouCMS?.title}
                      subheading={RecommendedForYouCMS?.description}
                      previousIconButtonProps={{
                        icon: "arrow_back",
                        onClick: function noRefCheck() { },
                        text: "Previous",
                        type: "button",
                      }}
                    >
                      {recommdedAssessmentCardData &&
                        recommdedAssessmentCardData?.map((item, idx) => (
                          <div
                            onClick={() => item?.key === "Tools.SmartResumeEditor" ? saveActivity(item?.resourceId, "Tool", item?.title) : saveActivity(item?.resourceId, "Tool", item?.title)}
                          >
                            <Cirrus.RecommndedResourcesCard
                              className="card"
                              title={item && item.title && item.title}
                              description={
                                item &&
                                item.shortDescription &&
                                item.shortDescription
                              }
                              onCardClick={()=>{cardRedirectHandler(item)}}
                              tags={item.tags}
                              image={{ src: item && item?.largeImage?.imageUrl }}
                              saveButton={{
                                label: fetchLikeLabel("CommonComponent.CommonSaveButton"),
                                secondaryLabel: fetchLikeLabel("CommonComponent.CommonSavedButton"),
                                onClick: (e) => {
                                  saveAssessmentCard(
                                    e,
                                    item && item.resourceId && item.resourceId,
                                    item && item.isSaved && item.isSaved,
                                    item.isLiked && item.isLiked,
                                    "save",
                                    item &&
                                    item.learningResourceStatus &&
                                    item.learningResourceStatus,
                                    item.lastAnsweredPageId &&
                                    item.lastAnsweredPageId,
                                    item?.resourceType?.id,
                                    item?.resourceCategoryType?.id
                                  );
                                },
                              }}
                              isSaved={item && item.isSaved && item.isSaved}
                              status={{
                                name: "new",
                                label:
                                  item &&
                                    item.resourceType &&
                                    item.resourceType.value
                                    ? item.resourceType.value
                                    : "Assessment",
                                value:
                                  item &&
                                    item.learningResourceStatus === "notstarted"
                                    ? item && item.duration && item.duration
                                    : fetchLearningStatus(
                                      item && item.learningResourceStatus
                                    ),
                              }}
                              likeText={
                                item?.likeCount == 0
                                  ? fetchLikeLabel(
                                    "CommonComponent.CommonLikeText"
                                  )
                                  : item?.likeCount == 1
                                    ? item?.likeCount +
                                    " " +
                                    fetchLikeLabel(
                                      "CommonComponent.CommonLikeText"
                                    )
                                    : item?.likeCount > 1
                                      ? item?.likeCount +
                                      " " +
                                      fetchLikeLabel(
                                        "CommonComponent.CommonLikesText"
                                      )
                                      : ""
                              }
                              onLikeButtonClick={(e) => {
                                saveAssessmentCard(
                                  e,
                                  item && item.resourceId && item.resourceId,
                                  item.isSaved && item.isSaved,
                                  item && item.isLiked && item.isLiked,
                                  "like",
                                  item &&
                                  item.learningResourceStatus &&
                                  item.learningResourceStatus,
                                  item.lastAnsweredPageId &&
                                  item.lastAnsweredPageId,
                                  item?.resourceType?.id,
                                  item?.resourceCategoryType?.id
                                )
                                saveActivity2(item)
                              }
                              }
                              isLiked={item && item.isLiked && item.isLiked}
                              type="assessment"
                              layout="horizontal"
                            />
                          </div>
                        ))}
                    </Cirrus.CarouselWithButton>
                  )}
                  
                  {recommdedAssessmentCardData && (
                    <div className='mobile-view_recommended-section'>
                      <Cirrus.Button
                        onClick={(e) => navigate("/events")}
                      >
                        {fetchLikeLabel(
                          "CommonComponent.SeeAllEventsButton"
                        )}
                      </Cirrus.Button>
                      <Cirrus.Button
                        onClick={(e) => { redirectToResource() }}
                      >
                        {fetchLikeLabel(
                          "CommonComponent.SeeAllResourcesButton"
                        )}
                      </Cirrus.Button>
                    </div>
                  )}
                </div>
              </div>
            </Container>
        </>
        } */}
        {/** Recomnded for you - end*/}

        {/* Learning Collections & More Collections Starts */}
        {((fetchPersona && fetchPersona?.personaName == "CareerProgram") ||
          fetchPersona?.personaName == "Entrepreneur") && (
          <>
            <Container fluid className="background-White learning-collections">
              <Row>
                <Cirrus.Header
                  className="heading heading_learning-collections"
                  size="h5"
                >
                  {learningCollectionCMS?.title}
                </Cirrus.Header>
                <p className="description description_learning-collections mx-auto">
                  {learningCollectionCMS?.description}
                </p>
                <div className="resource-card">
                  {learningCollectionCMS &&
                    learningCollectionCMS.pageContent &&
                    learningCollectionCMS.pageContent.map((item, idx) => (
                      <div>
                        <Cirrus.LearningCollectionCard
                          title={item && item.Title && item.Title}
                          description={
                            item && item.Description && item.Description
                          }
                          link={{
                            url: `/#${item && item.PageUrl?.Url}`,
                          }}
                          image={{ src: item && item?.LargeImage?.ImageUrl }}
                          status={{
                            name: "new",
                            label:
                              item.Count &&
                              item.Count.length > 1 &&
                              item?.Count[0] &&
                              item.Count[0]?.ResourceCount &&
                              item.Count[0]?.Resource
                                ? item.Count[0]?.ResourceCount <= 50
                                  ? item.Count[0]?.ResourceCount +
                                    " " +
                                    item.Count[0]?.Resource
                                  : "50+" + " " + item.Count[0]?.Resource
                                : "",
                            label2:
                              item.Count &&
                              item.Count.length > 1 &&
                              item?.Count[1] &&
                              item.Count[1]?.ResourceCount &&
                              item.Count[1]?.Resource
                                ? item.Count[1]?.ResourceCount <= 50
                                  ? item.Count[1]?.ResourceCount +
                                    " " +
                                    item.Count[1]?.Resource
                                  : "50+" + " " + item.Count[1]?.Resource
                                : "",
                            label3:
                              item.Count &&
                              item.Count.length > 1 &&
                              item?.Count[2] &&
                              item.Count[2]?.ResourceCount &&
                              item.Count[2]?.Resource
                                ? item.Count[2]?.ResourceCount <= 50
                                  ? item.Count[2]?.ResourceCount +
                                    " " +
                                    item.Count[2]?.Resource
                                  : "50+" + " " + item.Count[2]?.Resource
                                : "",
                          }}
                          type="assessment"
                          layout="horizontal"
                        />
                      </div>
                    ))}
                </div>
                {moreCollectionCMS.pageContent && (
                  <div className="more-collections">
                    <Cirrus.Header size="h5">
                      {moreCollectionCMS?.title}
                    </Cirrus.Header>
                    <p>{moreCollectionCMS?.description}</p>
                    <div className="button-container">
                      {moreCollectionCMS &&
                        moreCollectionCMS.pageContent &&
                        moreCollectionCMS.pageContent.map((item, idx) => (
                          <div>
                            <Cirrus.Button
                              className="more-collection-button"
                              isSecondary
                              onClick={() => {
                                navigate(`${item && item.PageUrl?.Url}`);
                              }}
                            >
                              {item && item.Title}
                            </Cirrus.Button>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              </Row>
            </Container>
          </>
        )}
        {/* Learning Collections & More Collections Ends */}

        {/* Documents Start */}
        {((fetchPersona && fetchPersona?.personaName == "CareerProgram") ||
          fetchPersona?.personaName == "Entrepreneur") && (
          <>
            <Row className="jobseeker-job-doc-cols m-sm-0 w-100">
              <Col lg={12} md={12} className="p-0">
                {documentsLists.length == 0 && (
                  <MyDocument
                    documentCMS={documentCMS && documentCMS}
                    documentsLists={documentsLists}
                    setDocumentsLists={setDocumentsLists}
                    getAllDocumentsLists={getAllDocumentsLists}
                  />
                )}
                {documentsLists.length > 0 && (
                  <MyDocumentsReturnVisit
                    documentRVCMS={documentCMS && documentCMS}
                    documentsLists={documentsLists}
                    setDocumentsLists={setDocumentsLists}
                    getAllDocumentsLists={getAllDocumentsLists}
                  />
                )}
              </Col>
            </Row>
          </>
        )}
        {/* Documents End */}

        {/* My Resume Starts */}
        {fetchPersona && fetchPersona?.personaName == "Entrepreneur" && (
          <>
            <MyResume
              myResumeCMS={myResumeCMS && myResumeCMS}
              documentsLists={documentsLists}
              setDocumentsLists={setDocumentsLists}
              getAllDocumentsLists={getAllDocumentsLists}
              resumeBuilderData={resumeBuilderData}
              resumeBuilderToolUrl={resumeBuilderToolUrl}
              isResumeUploaded={isResumeUploaded}
            />
          </>
        )}
        {/* My Resume Ends */}

        {/* "Career Program" && "Entrepreneur" Persona Ends */}
      </Cirrus.OneColumnLayout>
      {/* </Cirrus.Page>
      <Footer isLoggedIn={true} className="login-footer" /> */}
    </div>
  );
}
export default DashBoard;
