//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import httpHelper from '../../common/helpers/HttpHelper';
import environments from '../../common/environments/environment';
const http = new httpHelper();


export const ActivityAction = {
    SaveActivity:saveActivity
}




function createActivity(payload) {
    return http.post(environments.CreateCandidateActivities, payload);
}

function getCreatedActivity() {
    const id = `CandidateId=${JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) && JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"]}`
    // console.log(id)
    return http.get(environments.CandidateActivities.replace("{payload}",id))
}

function saveActivity(id,type,title){
        
    let payload = {
         
         "candidateId": JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
         "itemId": id,
         "type": type,
         "value": title
       
    }
    createActivity(payload).then((res)=>{
     if (res.status === 200) {
     }else{
         console.log("not sucees")
         
     }

    },(err)=>{
         console.log(err)
    }
    
    ) 
 }
