//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState } from 'react';
import * as Cirrus from "manpowergroup-cirrus";
import './Job.css';

function JobCard({ shownJobs, paginationState, layout, saveJobHandler, compareJobHandler, isCompareJobsOpen, JDclickedId, loadMoreJobHandler, selectedCompareJobs, jobCardCMSData }) {
    return (
        <>
            {<>
                <Cirrus.Label id='searchResultCount'>{paginationState.searchCountAndLabel}</Cirrus.Label>
                <br />
                {layout === 'twocolumn' ? <ul className='jobListTwoColumn'>
                    {
                        shownJobs?.map((job, id) => <li key={job.id}>
                            {job.companyName && job.companyName.includes("Manpower") ? <Cirrus.JobCard
                                companyLogo={{
                                    altText: job.companyLogo.altText,
                                    src: job.companyLogo.src
                                }}
                                companyName={job.companyName}
                                contractType={job.contractType}
                                description={job.description}
                                id={job.id}
                                isComparable={isCompareJobsOpen === true ? true : false}
                                isComparing={selectedCompareJobs && selectedCompareJobs.length && selectedCompareJobs.map(compare => compare.id).includes(job.id)}
                                isMatchText={job.matchPercentage ? job.isMatchText : ''}
                                isSaveable={isCompareJobsOpen ? false : true}
                                isSaved={job.isSaved}
                                jobRole={job.jobRole}
                                location={job.location}
                                onClick={() => {
                                    JDclickedId(job.id)
                                }}
                                onComparingButtonClick={() => compareJobHandler(job)}
                                onSaveButtonClick={() => saveJobHandler(job)}
                                posted={job.posted}
                                salary={job.salary}
                                startDate={job.startDate}
                                staticCopy={{
                                    CollapseJobInfo: job.staticCopy.CollapseJobInfo,
                                    CompanyName: job.staticCopy.CompanyName,
                                    Compare: job.staticCopy.Compare,
                                    ExpandJobInfo: job.staticCopy.ExpandJobInfo,
                                    IsFeatured: job.staticCopy.IsFeatured,
                                    IsNotCompared: job.staticCopy.IsNotCompared,
                                    IsSaved: job.staticCopy.IsSaved,
                                    IsUnsaved: job.staticCopy.IsUnsaved,
                                    JobDescription: job.staticCopy.JobDescription,
                                    JobInfo: job.staticCopy.JobInfo,
                                    JobRole: job.staticCopy.JobRole,
                                    JobType: job.staticCopy.JobType,
                                    Location: job.staticCopy.Location,
                                    Salary: job.staticCopy.Salary,
                                    Save: job.staticCopy.Save,
                                    StartDate: job.staticCopy.StartDate
                                }}
                            />
                                :
                                <Cirrus.JobCard
                                    companyName={job.companyName}
                                    contractType={job.contractType}
                                    description={job.description}
                                    id={job.id}
                                    isComparable={isCompareJobsOpen === true ? true : false}
                                    isComparing={selectedCompareJobs && selectedCompareJobs.length && selectedCompareJobs.map(compare => compare.id).includes(job.id)}
                                    isMatchText={job.matchPercentage ? job.isMatchText : ''}
                                    isSaveable={isCompareJobsOpen ? false : true}
                                    isSaved={job.isSaved}
                                    jobRole={job.jobRole}
                                    location={job.location}
                                    onClick={() => {
                                        JDclickedId(job.id)
                                    }}
                                    onComparingButtonClick={() => compareJobHandler(job)}
                                    onSaveButtonClick={() => saveJobHandler(job)}
                                    posted={job.posted}
                                    salary={job.salary}
                                    startDate={job.startDate}
                                    staticCopy={{
                                        CollapseJobInfo: job.staticCopy.CollapseJobInfo,
                                        CompanyName: job.staticCopy.CompanyName,
                                        Compare: job.staticCopy.Compare,
                                        ExpandJobInfo: job.staticCopy.ExpandJobInfo,
                                        IsFeatured: job.staticCopy.IsFeatured,
                                        IsNotCompared: job.staticCopy.IsNotCompared,
                                        IsSaved: job.staticCopy.IsSaved,
                                        IsUnsaved: job.staticCopy.IsUnsaved,
                                        JobDescription: job.staticCopy.JobDescription,
                                        JobInfo: job.staticCopy.JobInfo,
                                        JobRole: job.staticCopy.JobRole,
                                        JobType: job.staticCopy.JobType,
                                        Location: job.staticCopy.Location,
                                        Salary: job.staticCopy.Salary,
                                        Save: job.staticCopy.Save,
                                        StartDate: job.staticCopy.StartDate
                                    }}
                                />}
                        </li>
                        )
                    }
                </ul>
                    : <ul className='jobListOneColumn'>
                        {
                            shownJobs?.map((job, id) => <li key={job.id}>
                                {job.companyName && job.companyName.includes("Manpower") ? <Cirrus.JobCard
                                    companyLogo={{
                                        altText: job.companyLogo.altText,
                                        src: job.companyLogo.src
                                    }}
                                    companyName={job.companyName}
                                    contractType={job.contractType}
                                    description={job.description}
                                    id={job.id}
                                    isComparable={isCompareJobsOpen === true ? true : false}
                                    isComparing={selectedCompareJobs && selectedCompareJobs.length && selectedCompareJobs.map(compare => compare.id).includes(job.id)}
                                    isMatchText={job.isMatchText}
                                    isSaveable={isCompareJobsOpen ? false : true}
                                    isSaved={job.isSaved}
                                    jobRole={job.jobRole}
                                    location={job.location}
                                    onClick={() => {
                                        JDclickedId(job.id)
                                    }}
                                    onComparingButtonClick={() => compareJobHandler(job)}
                                    onSaveButtonClick={() => saveJobHandler(job)}
                                    posted={job.posted}
                                    salary={job.salary}
                                    startDate={job.startDate}
                                    staticCopy={{
                                        CollapseJobInfo: job.staticCopy.CollapseJobInfo,
                                        CompanyName: job.staticCopy.CompanyName,
                                        Compare: job.staticCopy.Compare,
                                        ExpandJobInfo: job.staticCopy.ExpandJobInfo,
                                        IsFeatured: job.staticCopy.IsFeatured,
                                        IsNotCompared: job.staticCopy.IsNotCompared,
                                        IsSaved: job.staticCopy.IsSaved,
                                        IsUnsaved: job.staticCopy.IsUnsaved,
                                        JobDescription: job.staticCopy.JobDescription,
                                        JobInfo: job.staticCopy.JobInfo,
                                        JobRole: job.staticCopy.JobRole,
                                        JobType: job.staticCopy.JobType,
                                        Location: job.staticCopy.Location,
                                        Salary: job.staticCopy.Salary,
                                        Save: job.staticCopy.Save,
                                        StartDate: job.staticCopy.StartDate
                                    }}
                                />
                                    :
                                    <Cirrus.JobCard
                                        companyName={job.companyName}
                                        contractType={job.contractType}
                                        description={job.description}
                                        id={job.id}
                                        isComparable={isCompareJobsOpen === true ? true : false}
                                        isComparing={selectedCompareJobs && selectedCompareJobs.length && selectedCompareJobs.map(compare => compare.id).includes(job.id)}
                                        isMatchText={job.isMatchText}
                                        isSaveable={isCompareJobsOpen ? false : true}
                                        isSaved={job.isSaved}
                                        jobRole={job.jobRole}
                                        location={job.location}
                                        onClick={() => {
                                            JDclickedId(job.id)
                                        }}
                                        onComparingButtonClick={() => compareJobHandler(job)}
                                        onSaveButtonClick={() => saveJobHandler(job)}
                                        posted={job.posted}
                                        salary={job.salary}
                                        startDate={job.startDate}
                                        staticCopy={{
                                            CollapseJobInfo: job.staticCopy.CollapseJobInfo,
                                            CompanyName: job.staticCopy.CompanyName,
                                            Compare: job.staticCopy.Compare,
                                            ExpandJobInfo: job.staticCopy.ExpandJobInfo,
                                            IsFeatured: job.staticCopy.IsFeatured,
                                            IsNotCompared: job.staticCopy.IsNotCompared,
                                            IsSaved: job.staticCopy.IsSaved,
                                            IsUnsaved: job.staticCopy.IsUnsaved,
                                            JobDescription: job.staticCopy.JobDescription,
                                            JobInfo: job.staticCopy.JobInfo,
                                            JobRole: job.staticCopy.JobRole,
                                            JobType: job.staticCopy.JobType,
                                            Location: job.staticCopy.Location,
                                            Salary: job.staticCopy.Salary,
                                            Save: job.staticCopy.Save,
                                            StartDate: job.staticCopy.StartDate
                                        }}
                                    />}
                            </li>
                            )
                        }
                    </ul>}
                {
                    paginationState.totalCounts > 0 ? <>
                        {
                            paginationState.shownCounts >= paginationState.totalCounts ? '' : <div className='jobs_loadmore'>
                                <Cirrus.Button
                                    onClick={loadMoreJobHandler}
                                    disabled={paginationState.desabledLoadMore}
                                >
                                    {
                                        paginationState.desabledLoadMore === true ? <>{jobCardCMSData?.loading}</> : <>{jobCardCMSData?.loadMore}</>
                                    }
                                </Cirrus.Button>
                            </div>
                        }
                    </> : ''
                }
            </>
            }
        </>
    )
}

export default JobCard
