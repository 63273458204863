//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import "./index.css";
import HeaderWithNavigation from "../header/headerWithNavigation";
import Footer from "../../common/components/footer";
import { DiscoverActions } from "./DiscoverActions";
import { Context } from "../../common/context/Context";
import { Row, Col, Container } from "react-bootstrap";
import Spinner from "../../common/components/spinner-loader/Spinner";
import { filter } from "domutils";
import { useNavigate, useParams } from "react-router-dom";
import { AssessmentActions } from "../assessments/AssessmentActions";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { BookMarkActions } from "../../pages/bookmarkPage/bookmarkActions";
import { ActivityAction } from "../../common/utility/ActivityActions";
import { iViewActions } from "../Iview/iViewActions";

Array.prototype.chunk = function (n) {
    if (!this?.length) {
        return [];
    }
    return [this.slice(0, n)].concat(this.slice(n).chunk(n));
};
function Discover() {
    const context = useContext(Context);
    const getProfileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
    const navigate = useNavigate();
    const [cmsContent, setCmsContent] = useState({
        DiscoverIViewTitle: "",
        DiscoverIViewDesc: "",
        DiscoverIViewTags: [],
        DiscoverIViewTool: "",
        DiscoverIViewToolCount: "0",
        DiscoverIViewImgUrl: "",
        DiscoverIViewAssessmentType: "",
        commonComponent: []
    });
    const [screenLoader, setScreenLoader] = useState(false);
    const [assessmentCardData, setAssessmentCardData] = useState();
    const [recommdedAssessmentCardData, setRecommdedAssessmentCardData] =
        useState();
    const [externalToolCard, setExternalToolCard] = useState();
    const [filterStatus, setFilterStatus] = useState(true)
    const [saved, setSaved] = useState(false);
    const [assessmentSecondary, setAssessmentSecondary] = useState(false);
    const [resourcesSecondary, setResourcesSecondary] = useState(true);
    const [toolsSecondary, setToolsSecondary] = useState(true);
    const [sortedData, setSortedData] = useState([]);
    const [chunkedData, setChunkedData] = useState([]);
    const [doceboEvents, setDoceboEvents] = useState([]);
    const [doceboLabels, setDoceboLabels] = useState([]);
    const [enableSearch, setEnableSearch] = useState(true);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [shownCards, setShownCards] = useState(4);
    const [doceboURL, setDoceboURL] = useState("");
    const [discoverCMSData, setDiscoverCMSData] = useState({
        title: '',
        description: ''
    })
    const [learningCollectionCMS, setLearningCollectionCMS] = useState({
        title: "",
        description: "",
        pageContent: [],
        recommdedTitle: "",
        recommdedDescription: ""
    });
    const [tabData, setTabData] = useState([])
    const [moreCollectionCMS, setMoreCollectionCMS] = useState({
        title: "",
        description: "",
        pageContent: [],
    });
    const [allLearningResources, setAllLearningResources] = useState({
        title: "",
        description: "",
        pageContent: [],
        labels: [],
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [sortByArea, setSortByArea] = useState([]);
    const [sortByProgress, setSortByProgress] = useState([]);
    const [filterResultForm, setFilterResultForm] = useState({
        isFilterApplied: false,
        applyButtonStatus: true,
        sortByArea: {
            name: "sortByArea",
            concatValue: "",
            value: [],
        },
        sortByProgress: {
            name: "sortByProgress",
            concatValue: "",
            value: "",
        },
    });
    const [assessmentData, setAssessmentData] = useState([]);
    const [toolsData, setToolsData] = useState([]);
    const [resourcesData, setResourcesData] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [sortByAreaData, setSortByAreaData] = useState("");
    const [sortByProgressData, setSortByProgressData] = useState("");
    const [saveLikeSpinner, setSaveLikeSpinner] = useState(false);
    const [remainingData, setremainingData] = useState([]);
    const [commonResourceData, setCommonResourceData] = useState([]);
    const [resFilterd, setResFilterd] = useState(false);
    const [mobileFilter, setMobileFilter] = useState(false)
    const [disable, setDisable] = useState(true)
    const [showDocebo, setShowDecebo] = useState(true);
    const pageOrgin = useParams();
    const [iviewCount, setIviewCount] = useState(0);


    let featuresConfig = JSON.parse(window.sessionStorage.getItem("featuresConfig"))
    function getFeaturesConfig(featureName) {
        let val = false;
        featuresConfig?.features?.map(feature => {
            if (feature.featureName === featureName) {
                val = feature.isActive
            }
        })
        return val;
    }

    const fetchIviewToolContent = async () => {
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let payload = {
            PageName: "OnDemandVideoInterview"
        };
        await DiscoverActions.GetIviewToolContent(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    refCmsContent.Title = resData?.title;
                    refCmsContent.Description =
                        resData.components["iView.VideoCollections"]?.Description;
                    refCmsContent.iviewId = resData?.id;
                    refCmsContent.ShortDescription = resData?.shortDescription;
                    refCmsContent.PersonalBranding = resData?.focusArea && resData?.focusArea.length > 0 && resData?.focusArea[0]?.value;
                    refCmsContent.ResourceType = resData?.resourceType?.value;
                    refCmsContent.asessmentCount = resData?.asessmentCount;
                    refCmsContent.smallImage = resData?.largeImage?.imageUrl;
                    refCmsContent.commonComponent =
                        resData?.components["commonComponent"];
                    refCmsContent.asessmentType = resData?.assessmentType?.value;
                    // refCmsContent.iviewId = resData.id;
                    refCmsContent.iviewStatus = resData?.collectionSaved;
                    setCmsContent(refCmsContent);
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };


    // ****Track*****
    const appInsights = useAppInsightsContext();

    const property = {
        "Component Name": "ViewDiscover",
        "Language": JSON.parse(window.sessionStorage.getItem("Language")) && JSON.parse(window.sessionStorage.getItem("Language"))['selectedLanguageFullName']
    }

    // const track = useTrackEvent(appInsights,"ViewDashboard",property)



    const getdoceboLink = async () => {
        setShowDecebo(getFeaturesConfig("DoceboTile"))
        if (getFeaturesConfig("DoceboTile")) {
            let res = await DiscoverActions.GetDoceboLink();
            setDoceboURL(res.data);
        }
    };

    const saveIviewAssessmentCard = async (e, id, savedStatus) => {
        let payload = {
            CollectionId: id,
            isSaved: !savedStatus,
        };

        await DiscoverActions.SaveIviewToolCollection(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    fetchIviewToolContent();
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const fetchIviewCount = async () => {

        let payload = {
            PageName: "OnDemandVideoInterview"
        };
        await iViewActions.GetIviewContent(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    let assessmentCount = resData?.asessmentCount;
                    setIviewCount(assessmentCount);
                }
            },
            (err) => {
                console.log(err);
                setScreenLoader(false);
            }
        );
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        // TrackActions.EventTrack(appInsights, "Discover", property);
        TrackActions.PageViewRecord(appInsights, "Discover", property)
        TrackActions.PageAction("Discover")
        TrackActions.PageAction("Discover", property)
        context.setPath("discover")
        sessionStorage.setItem("setPath", JSON.stringify("discover"))
        // setAssessmentSecondary(true)
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
        const handleResize = () => {
            setTimeout(() => {
                setIsMobile(window.innerWidth < Cirrus.devices.LargeTablet);
            }, 250);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchIviewToolContent();
        fetchCMSDiscoverData();
        setAssessmentSecondary(false);
        setToolsSecondary(true);
        setResourcesSecondary(true);
        // if (!context.focusToResource) {
        //     fetchAssessmentData("assessments");
        // }
        if (assessmentCardData) fetchIviewToolContent();
        fetchIviewCount()
        //fetchRecommndedAssessmentData();
        getdoceboLink();
    }, [context.language]);

    useEffect(() => {
        triggerFunc()
    }, [sortByProgressData, sortByAreaData])

    useEffect(() => {
        window.scrollTo(0, 0)
        if (Object.keys(learningCollectionCMS)?.length !== 0) {
            let sortByArea = [];
            let sorbyProgress = [];
            learningCollectionCMS?.LOVList?.filter((x) => {
                if (x.Key === "Discover.SortByArea") {
                    x.Values.map((ct) => {
                        let obj = {
                            label: ct.Value,
                            value: ct.Key,
                        };
                        sortByArea.push(obj);
                    });
                    setSortByArea(sortByArea);
                }
                if (x.Key === "Discover.SortbyProgress") {
                    x.Values.map((ct) => {
                        let obj = {
                            label: ct.Value,
                            value: ct.Key,
                        };
                        sorbyProgress.push(obj);
                    });
                    setSortByProgress(sorbyProgress);
                }
            });
        }
    }, [learningCollectionCMS]);

    useEffect(() => {
        if (context?.focusToResource) {
            setResourcesSecondary(false);
            fetchAssessmentData("resources");
            setToolsSecondary(true);
            setAssessmentSecondary(true);
        }
    }, [context.focusToResource])

    const scrollToSection = () => {
        let scroll_to_bottom = document.getElementById('resourcePage');
        scroll_to_bottom.scrollIntoView();
    };

    const fetchCMSDiscoverData = async () => {
        let refLearningCollectionCMS = JSON.parse(
            JSON.stringify(learningCollectionCMS)
        );
        let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
        let refCommonResourceContent = JSON.parse(JSON.stringify(commonResourceData));
        let refDiscoverCMSData = JSON.parse(JSON.stringify(discoverCMSData))
        let refMoreCollectionCMS = JSON.parse(JSON.stringify(moreCollectionCMS));
        let refAllLearningResources = JSON.parse(
            JSON.stringify(allLearningResources)
        );
        let payload = {
            PageName: "Discover"
        };

        await DiscoverActions.GetDiscoverCMSData(payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data.Pages && res.data.Pages.length > 0 && res.data?.Pages[0];
                    refDiscoverCMSData.title = resData && resData?.Title;
                    refDiscoverCMSData.description = resData && resData?.Description;
                    setDiscoverCMSData(refDiscoverCMSData);

                    let lcList = resData && resData.Components["Learning Collections"];

                    refCmsContent.commonComponent = resData?.Components["CommonComponent"];
                    setCmsContent(refCmsContent);

                    refCommonResourceContent.CommonResourceComponent = resData?.Components["CommonResourceComponent"];
                    setCommonResourceData(refCommonResourceContent);

                    let recommendLabel =
                        resData &&
                        resData.Components["Discover.DiscoveryPage.RecommendedForYou"];

                    let resourceFilter = resData && resData.Components["Discover.DiscoveryPage.AllLearningResourceFilter"];
                    refLearningCollectionCMS = resourceFilter;
                    refLearningCollectionCMS.recommdedTitle = recommendLabel?.Title;
                    refLearningCollectionCMS.recommdedDescription = recommendLabel?.Description;
                    refLearningCollectionCMS.title = lcList?.Title;
                    refLearningCollectionCMS.description = lcList?.Description;
                    refLearningCollectionCMS.pageContent = lcList?.Pages;
                    setLearningCollectionCMS(refLearningCollectionCMS);

                    let j = lcList?.Pages && lcList?.Pages.length;
                    let pageData = [];
                    for (let i = 0; i < j; i++) {
                        const b = {
                            tabName: lcList?.Pages[i]?.Metadata,
                            tabUrl: lcList?.Pages[i]?.PageUrl?.Url,
                        };
                        pageData.push(b);
                    }
                    setTabData(pageData && pageData);

                    let mcList = resData && resData.Components["MoreCollection"];
                    refMoreCollectionCMS.title = mcList?.Title;
                    refMoreCollectionCMS.description = mcList?.Description;
                    refMoreCollectionCMS.pageContent = mcList?.Pages;
                    setMoreCollectionCMS(refMoreCollectionCMS);

                    let allRList =
                        resData && resData.Components["Discover.DiscoveryPage.AllLearningResourceFilter"];
                    setDoceboEvents(
                        resData && resData.Components["Events.EventsDiscoverDoceboCard"]
                    );
                    setDoceboLabels(
                        resData?.Components["Events.EventsDiscoverDoceboCard"]?.Labels
                    );

                    refAllLearningResources.title = allRList?.Title;
                    refAllLearningResources.description = allRList?.Description;
                    refAllLearningResources.pageContent = allRList?.Pages;
                    refAllLearningResources.labels = allRList?.Labels;
                    setAllLearningResources(refAllLearningResources);
                    fetchRecommndedAssessmentData(refCmsContent.commonComponent);


                    if (!context.focusToResource) {
                        fetchAssessmentData("assessments","",refCmsContent);
                    }
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const fetchLRLabels = (key) => {
        let refLRContent = allLearningResources?.labels;
        refLRContent = [...refLRContent, ...doceboLabels];
        let label = "";
        if (refLRContent) {
            refLRContent?.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchAssessmentData = async (type, Origin, refCmsContent) => {
        if (saveLikeSpinner === true) {
            setScreenLoader(false);
        } else {
            setScreenLoader(true);
        }
        let refShownCards = JSON.parse(JSON.stringify(shownCards));
        setShowLoadMore(true);
        setSearchKeyword("");
        setSortByAreaData(false);
        let resourceType =
            type === "assessments"
                ? "assessmentsandtasks"
                : type === "tools"
                    ? "tools"
                    : type === "resources"
                        ? "resources"
                        : "";

        let resourceCategory = type === "assessments"
            ? "assessmentsandtasks"
            : type === "tools"
                ? "tools"
                : type === "resources"
                    ? "resources"
                    : "";
        let ProgramData = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : ""

        let payload = {
            learningResourceType: resourceType,
            recommended: "false",
            isAllLearningResources: "true",
            ResourceCategory: resourceCategory,
            ProgramClassIds: resourceCategory == "resources" ? ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : "" : null
        };

        await DiscoverActions.GetDiscoverData(payload).then(
            async (res) => {
                if (res.status === 200) {
                    let resData = res?.data && res?.data?.responsemodel;

                    if (type === "assessments") {
                        setFilterStatus(true)
                        setAssessmentData(resData && resData)
                    } else if (type === "tools") {
                        setFilterStatus(false)
                        setToolsData(resData && resData)
                    } else if (type === "resources") {
                        setFilterStatus(false)
                        setResourcesData(resData && resData)
                    }

                    refShownCards = 4;
                    setShownCards(refShownCards);

                    let _30SecondCommercialPayload = {
                        PageName: "30 Second Commercial"
                    }
                    await DiscoverActions.Get_30_secondCommercialCMS(_30SecondCommercialPayload).then(response => {
                        if (response.status === 200) {
                            // console.log(response.data, "30 second commercial")
                            let resp = response.data;
                            resData?.map(recommendedCards => {
                                Object.keys(recommendedCards).forEach(key => {
                                    if (recommendedCards[key] === resp.ID) {
                                        recommendedCards["externalId"] = resp.ExternalId
                                    }
                                })
                            })
                        } else {
                            console.log(response.data)
                            refShownCards = 4;
                            setShownCards(refShownCards);
                        }
                    }, err => {
                        refShownCards = 4;
                        setShownCards(refShownCards);
                        console.log(err)
                    })


                    const filteredAssessments = resData;
                    setAssessmentCardData(type === 'assessments' ? filteredAssessments : resData);
                    fetchSortedData(type === 'assessments' ? filteredAssessments : resData, "", "", Origin,refCmsContent);
                    setSaveLikeSpinner(true);
                    setScreenLoader(false);
                    if (context.focusToResource) {
                        scrollToSection()
                        context.setFocusToResource(false)
                    }
                } else {
                    console.log("api not succeeded");
                    setScreenLoader(false);
                }
            },
            (err) => {
                console.log(err);
            }
        );
        context.setFocusToResource(false);
    };

    const fetchRecommndedAssessmentData = async (refCMS) => {
        let ProgramData = JSON.parse(window.sessionStorage.getItem("setIdTokenClaims")) !== null ? JSON.parse(window.sessionStorage.getItem("setProgramDetails")) : "";
        let profileDetails = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
        let recommendedSectionData = [];
        let assessData = [];
        let toolsData = [];
        let resourcesData = [];
        let assessPayload;
        let toolsPayload;
        let resourcesPayload;

        if (profileDetails.personaName === "Executive") {

            assessPayload = {
                learningResourceType: "assessmentsandtasks",
                recommended: "false",
                isAllLearningResources: "true",
                IsThoughtLeadership: "true",
                ResourceCategory: "assessmentsandtasks",
            };
            toolsPayload = {
                learningResourceType: "tools",
                recommended: "false",
                isAllLearningResources: "true",
                IsThoughtLeadership: "true",
                ResourceCategory: "tools",
            };
            resourcesPayload = {
                learningResourceType: "resources",
                recommended: "false",
                isAllLearningResources: "true",
                IsThoughtLeadership: "true",
                ResourceCategory: "resources",
                ProgramClassIds: ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : ""
            };
        } else {
            assessPayload = {
                learningResourceType: "assessmentsandtasks",
                recommended: "true",
                isAllLearningResources: "true",
                ResourceCategory: "assessmentsandtasks",
            };

            toolsPayload = {
                learningResourceType: "tools",
                recommended: "true",
                isAllLearningResources: "true",
                ResourceCategory: "tools",
            };

            resourcesPayload = {
                learningResourceType: "resources",
                recommended: "false",
                isAllLearningResources: "true",
                ResourceCategory: "resources",
                ProgramClassIds: ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : ""
            };
        }

        let resourcesCountPayload = {
            learningResourceType: "resources",
            recommended: "false",
            isAllLearningResources: "true",
            ResourceCategory: "resources",
            ProgramClassIds: ProgramData?.programClassId ? [(ProgramData?.programClassId).toString()] : ""
        };

        await DiscoverActions.GetDiscoverData(resourcesCountPayload).then(
            (res) => {
                if (res.status === 200) {
                    const count = res?.data && res?.data?.learningResourceCount;
                    window.sessionStorage.setItem("ResourcesCount", count?.resources ? count?.resources : 0)
                } else {
                    console.log("api not succeeded");
                }
            }, err => {
                console.log(err);
            })

        await DiscoverActions.GetDiscoverData(assessPayload).then(
            (res) => {
                if (res.status === 200) {
                    assessData = res?.data && res?.data?.responsemodel;
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
        await DiscoverActions.GetDiscoverData(toolsPayload).then(
            (res) => {
                if (res.status === 200) {
                    toolsData = res?.data && res?.data?.responsemodel;
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );

        await DiscoverActions.GetDiscoverData(resourcesPayload).then(
            (res) => {
                if (res.status === 200) {
                    resourcesData = res?.data && res?.data?.responsemodel;
                    // const count = res?.data && res?.data?.learningResourceCount;
                    // window.sessionStorage.setItem("ResourcesCount", count?.resources ? count?.resources : 0)
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );

        recommendedSectionData = [...assessData, ...toolsData, ...resourcesData];

        let resData = recommendedSectionData && recommendedSectionData;
        let _30SecondCommercialPayload = {
            PageName: "30 Second Commercial"
        }
        await DiscoverActions.Get_30_secondCommercialCMS(_30SecondCommercialPayload).then(response => {
            if (response.status === 200) {
                let resp = response.data;
                resData?.map(recommendedCards => {
                    Object.keys(recommendedCards).forEach(key => {
                        if (recommendedCards[key] === resp.ID) {
                            recommendedCards["externalId"] = resp.ExternalId
                        }
                    })
                })
            } else {
                console.log(response.data)
            }
        }, err => {
            console.log(err)
        })

        const fetchRecommendLabel = (id) => {
            let refCmsContent = refCMS;
            let label = "";
            if (refCmsContent?.Labels) {
                refCmsContent?.Labels?.map((x) => {
                    if (x.Key === id) {
                        label = x.Phrase;
                    }
                });
            }
            return label;
        };

        const completedArray =
            resData &&
            resData?.filter((x) => x.learningResourceStatus != "completed");
        completedArray?.map((item) => {
            let tags = [];
            item?.focusArea?.map((tg) => {
                tags.push(tg.value)
                if (item?.isRecommendedCMS === true) {
                    let a = fetchRecommendLabel("CommonComponent.CommonRecommendedText") || fetchLikeLabel("CommonComponent.CommonRecommendedText")
                    tags.push(a === "" ? "Recommended" : a)
                }
            })
            item['tags'] = tags;
            return item;
        })
        setRecommdedAssessmentCardData(completedArray);
    };

    const fetchSortedData = (data, sortByArea, sortByProgress, origin, refCmsContent) => {
        //dont remove
        let current_count = chunkedData?.length;
        //

        const fetchRecommendedText = (id) => {
            let refCmsContents = refCmsContent && refCmsContent?.commonComponent;
            let label = "";
            if (refCmsContents?.Labels) {
                refCmsContents?.Labels?.map((x) => {
                    if (x.Key === id) {
                        label = x.Phrase;
                    }
                });
            }
            return label;
        };
        data?.map((item) => {
            let tags = [];
            item?.focusArea?.map((tg) => {
                tags.push(tg.value)
                if (item?.isRecommendedCMS == true) {
                    let a = fetchRecommendedText("CommonComponent.CommonRecommendedText") || fetchLikeLabel("CommonComponent.CommonRecommendedText")
                    tags.push(a === "" ? "Recommended" : a)
                }
            })
            item['tags'] = tags;
            return item;
        })

        const notStartedArray =
            data && data.filter((x) => x.learningResourceStatus.trim() === "notstarted");
        const NSSortedArray = notStartedArray.sort(
            (a, b) => b.likeCount - a.likeCount
        );

        const inProgressArray =
            data && data.filter((x) => x.learningResourceStatus.trim() === "inprogress");

        const completedArray =
            data && data.filter((x) => x.learningResourceStatus.trim() === "completed");
        const sortedArray = completedArray.sort(
            (a, b) => b.likeCount - a.likeCount
        );

        let refsortedData = JSON.parse(JSON.stringify(sortedData));
        refsortedData = [...NSSortedArray, ...inProgressArray, ...sortedArray];
        setSortedData(refsortedData);

        let filterResult = [];

        if (sortByArea) {
            let filterData = refsortedData && refsortedData.filter((x) => x.focusArea.length > 0);
            let filterDataByArea =
                filterData &&
                filterData.filter(
                    x => x?.focusArea?.some(
                        y => y?.value == sortByArea)
                )
            let restFilter = sortByProgress !== false ? sortByProgress !== undefined ? sortByProgress?.length > 0 || sortByProgress !== "" ? filterDataByArea.filter((x) => sortByProgress.includes(x.learningResourceStatus)) : filterDataByArea : filterDataByArea : filterDataByArea;
            filterResult = [...restFilter];
            current_count = filterResult?.length
            if (filterResult?.length <= 4) {
                setShowLoadMore(false);
                displayLoadMore(false);
            } else {
                setShowLoadMore(true);
                displayLoadMore(true);
            }
        }
        if (sortByProgress) {
            let sortyProgressData = sortByProgress;
            let filterDataByProgress =
                refsortedData &&
                refsortedData.filter(
                    (x) => sortyProgressData.includes(x.learningResourceStatus)
                );
            let restFilter = sortByArea !== false ? sortByArea !== undefined ? sortByArea?.length > 0 || sortByArea !== "" ? filterDataByProgress.filter((x) => sortByArea.includes(x?.focusArea[0]?.value)) : filterDataByProgress : filterDataByProgress : filterDataByProgress;
            filterResult = [...restFilter];
            if (filterResult?.length <= 4) {
                setShowLoadMore(false);
                displayLoadMore(false);
            } else {
                setShowLoadMore(true);
                displayLoadMore(true);
            }
        }

        let refchunkedData = JSON.parse(JSON.stringify(chunkedData));
        let refremainingData = JSON.parse(JSON.stringify(remainingData));
        if (sortByArea || sortByProgress) {
            refchunkedData = filterResult && filterResult.slice(0, 4);
            refremainingData = filterResult
        } else {
            //do not remove
            if (origin === "SaveLike") {
                let filterData = refchunkedData.map((xat) => (
                    refsortedData.filter((yat) => (
                        yat.key === xat.key
                    ))
                )).map((x) => x[0]);
                refchunkedData = isFilterOpen ? filterData : refsortedData && refsortedData.slice(0, current_count);
                refremainingData = isFilterOpen ? remainingData : refsortedData;
                // if(refchunkedData?.length === refremainingData?.length){
                //   displayLoadMore(false);
                // }
            } else {
                refchunkedData = refsortedData && refsortedData.slice(0, 4);
                refremainingData = refsortedData
            }
        }

        if (searchKeyword && searchKeyword) {
            let searchData;
            let filteredData;

            let searchTitleData =
                refsortedData &&
                refsortedData.filter((x) =>
                    x?.title?.toLowerCase().includes(searchKeyword.toLowerCase())
                );

            let searchDescriptionData =
                refsortedData &&
                refsortedData.filter((x) =>
                    x?.shortDescription?.toLowerCase().includes(searchKeyword.toLowerCase())
                );

            let searchFocusAreaData =
                refsortedData &&
                refsortedData.filter(
                    x => x?.focusArea?.some(
                        y => y?.value?.toLowerCase().includes(searchKeyword.toLowerCase()))
                )

            let subjectCategoryData =
                refsortedData &&
                refsortedData.filter(
                    x => x?.subjectCategory?.some(
                        y => y?.value?.toLowerCase().includes(searchKeyword.toLowerCase()))
                )

            filteredData = [...searchTitleData, ...searchDescriptionData, ...searchFocusAreaData, ...subjectCategoryData];

            searchData = filteredData.reduce((unique, o) => {
                if (!unique.some(obj => obj.resourceId === o.resourceId)) {
                    unique.push(o);
                }
                return unique;
            }, []);

            if (searchData?.length < 4) {
                setShowLoadMore(false);
                displayLoadMore(false);
            }
            refchunkedData = searchData && searchData.slice(0, 4);
            refremainingData = searchData
        }

        setChunkedData(refchunkedData);
        setremainingData(refremainingData);
    };

    const displayLoadMore = (load) => {
        return (
            assessmentCardData &&
            assessmentCardData?.length > 4 &&
            remainingData?.length !== chunkedData?.length &&
            load && (
                <Cirrus.Button
                    onClick={() => {
                        loadMore();
                    }}
                >
                    {fetchLikeLabel("CommonComponent.CommonLoadMore")}
                </Cirrus.Button>
            )
        );
    };

    const loadMore = () => {
        let refchunkedData = JSON.parse(JSON.stringify(chunkedData));
        let refshownCards = JSON.parse(JSON.stringify(shownCards));
        refshownCards = shownCards + 4;
        setShownCards(refshownCards);
        refchunkedData = remainingData && remainingData.slice(0, refshownCards);
        setChunkedData(refchunkedData);
        if (remainingData?.length <= refshownCards || remainingData?.length == undefined) {
            setShowLoadMore(false);
            displayLoadMore(false);
        }
    };

    const saveAssessmentCard = async (
        e,
        id,
        savedStatus,
        likedStatus,
        type,
        learningResourceStatus,
        pageNumber,
        resourceSubType,
        resourceCatType,
        origin
    ) => {
        e.preventDefault();
        let resourceType = resourceCatType;
        let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
        let payload = {
            candidateId: candidateID,
            learningResourceId: id,
            lastAnsweredPageId: pageNumber,
            learningResourceType: resourceCatType,
            resourceTypeId: resourceSubType,
            learningResourceStatus: learningResourceStatus,
            isSaved:
                type === "save" ? (savedStatus == true ? false : true) : savedStatus,
            isLiked:
                type === "like" ? (likedStatus == true ? false : true) : likedStatus,
        };

        await DiscoverActions.SaveAssessmentCard(resourceType, id, payload).then(
            (res) => {
                if (res.status === 200) {
                    let resData = res.data;
                    setSaveLikeSpinner(true);
                    if (origin === "recommended") {
                        fetchAssessmentData("assessments", origin);
                    } else {
                        fetchAssessmentData(
                            resourceType === "assessmentsandtasks"
                                ? "assessments"
                                : resourceType === "tools"
                                    ? "tools"
                                    : resourceType === "resources"
                                        ? "resources"
                                        : "", "SaveLike"
                        );
                    }
                    fetchRecommndedAssessmentData();
                    updateStatus(resourceType, id, payload);
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const saveActivity2 = (type) => {
        let payload = {
            candidateId: (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"],
            itemId: type.learningResourceId,
            value: type.title,
        }
        type.isLiked === false ? payload["type"] = type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks" ? 'AssessmentLike' : type.resourceCategoryType.id.toLowerCase() === "tools" ? "ToolLike" : `${type.resourceType.id + 'Like'}` : payload["type"] = type.resourceCategoryType.id.toLowerCase() === "assessmentsandtasks" ? 'AssessmentUnLike' : type.resourceCategoryType.id.toLowerCase() === "tools" ? "ToolUnLike" : `${type.resourceType.id + 'UnLike'}`;
        AssessmentActions.CreateActivity(payload).then((res) => {
        }, (err) => {
            console.log(err)
        }
        )
    }
    const updateStatus = async (resourceType, id, payload) => {
        await DiscoverActions.UpdateStatus(resourceType, id, payload).then(
            (res) => {
                if (res.status === 200) {
                    setSaveLikeSpinner(true);
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const fetchLikeLabel = (id) => {
        let refCmsContent = cmsContent && cmsContent?.commonComponent;
        let label = "";
        if (refCmsContent?.Labels) {
            refCmsContent?.Labels?.map((x) => {
                if (x.Key === id) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };

    const fetchResourceLabel = (id) => {
        let refCommonResourceContent = commonResourceData && commonResourceData?.CommonResourceComponent;
        let label = "";
        if (refCommonResourceContent?.Labels) {
            refCommonResourceContent?.Labels?.map((x) => {
                if (x.Key === id) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    };
    const fetchDBhoverURL = () => {
        let date = new Date();
        let param1 = Math.floor(Math.random() * 10).toString();
        let param2 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(1, 2).toString();
        let param3 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(0, 1).toString();
        let param4 = (date.getFullYear()?.toString()?.length < 2 ? date.getFullYear().toString() : date.getFullYear().toString()).substring(3, 4).toString();
        let param5 = Math.floor(Math.random() * 10).toString();
        let param6 = Math.floor(Math.random() * 10).toString();
        let param7 = ((date.getMonth() + 1)?.toString()?.length < 2 ? "0" + (date.getMonth() + 1).toString() : (date.getMonth() + 1).toString()).substring(1, 2).toString();
        let param8 = (date.getDate()?.toString()?.length < 2 ? "0" + date.getDate().toString() : date.getDate().toString()).substring(0, 1).toString();
        let param9 = Math.floor(Math.random() * 10).toString();

        let st = param1 + param2 + param3 + param4 + param5 + param6 + param7 + param8 + param9;
        let userInfo = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
        let programCountry = JSON.parse(window.sessionStorage.getItem("setProgramDetails"))
        let URL = `http://signon.oneSource.com?LogonUserID=${userInfo.emailAddress}&ST=${st}&AT=917360db07a6e0cdbbde3ff088b0e70d&Fname=${userInfo.firstName}&Lname=${userInfo.lastName}&Country=${programCountry.programCountryCode}`
        window.open(URL);
    };

    const fetchLesEchosURL = async (id) => {
        await DiscoverActions.ExternalToolsRedirectURL(id).then((res) => {
            if (res.status === 302) {
                window.open(res.data);
            } else {
                console.log("api not succeeded");
            }
        },
            (err) => {
                console.log(err);
            }
        );
    }

    const cardRedirectHandler = (item) => {
        if (item?.resourceType?.id === "article" || item?.resourceType?.id === "video" || item?.resourceType?.id === "document" || item?.resourceType?.id === "E3") {
            window.open(`#/article-page/${item?.resourceId}`, "_self");
        } else {
            if (item?.key === "Tools.DnBHover") {
                fetchDBhoverURL()
            } else if (item?.key === "Tools.SmartResumeEditor") {
                getResumeBuilderUrl();
            } else if (item?.key === "Tools.OnDemandVideoInterviews") {
                window.open(`#/iview-welcome`, "_self");
            } else if (item?.key === "Tools.30SecondCommercial") {
                window.open(`#/iview-interview-start/${item?.resourceId}/${item?.externalId}`, "_self");
            } else if (item?.key === "Tools.Cobra") {
                window.open(process.env.REACT_APP_COBRA_TOOL_URL);
            }else if (item?.key === "Tools.ProQuest") {
                window.open(process.env.REACT_APP_PROQUEST_TOOL_URL);
            } else if (item?.key === "Tools.FirstEco") {
                window.open(process.env.REACT_APP_FIRSTECO_TOOL_URL);
            } else if (item?.key === "Tools.LesEchos" || item?.key === "Tools.Diane" || item?.key === "Tools.Corporama" || item?.key === "Tools.Executives") {
                fetchLesEchosURL(item?.resourceId)
            } else if (item?.key === "Tools.AbintegroCareerCentre") {
                window.open(process.env.REACT_APP_ABINTEGRO_TOOL_URL);
            } else if (item?.resourceType?.id === "assessment"){
                navigate(item?.resourceUrl?.url);
            }
            else {
                window.open(item?.resourceUrl?.url);
            }
        }
    }

    const fetchLearningStatus = (id) => {
        let refCmsAssessmentContent = cmsContent && cmsContent?.commonComponent;
        let label = "";
        if (Object.keys(refCmsAssessmentContent).length !== 0) {
            if (refCmsAssessmentContent?.LOVList[0]?.Values) {
                refCmsAssessmentContent?.LOVList[0]?.Values.map((x) => {
                    if (x.ID === id) {
                        label = x.Value;
                    }
                });
            }
        }
        return label;
    };

    // Get resume builder url
    const getResumeBuilderUrl = async () => {
        await DiscoverActions.GetResmeBuilderUrl().then(
            (res) => {
                if (res.status === 200) {
                    window.open(res?.data && res?.data?.url);
                } else {
                    console.log("api not succeeded");
                }
            },
            (err) => {
                console.log(err);
            }
        );
    }

    if (screenLoader) {
        return (
            <div>
                <Spinner spinnerTimer={screenLoader} />
            </div>
        );
    }


    const filterFormHandler = (event, sortby) => {
        let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
        if (sortby === "sortByArea") {
            setDisable(false);
            let eventArea = event.length > 1 ? event.map((eve) => (eve.label)) : event[0]?.label;
            refFilterResultForm.sortByArea.value = event;
            setFilterResultForm(refFilterResultForm);
            setSortByAreaData(sortby === "sortByArea" && eventArea);
            if (filterStatus == false) {
                setSortByProgressData(sortby === "sortByProgress" && [])
            }
        }

        if (sortby === "sortByProgress") {
            setDisable(false)
            let evenData = event.length > 1 ? event.map((eve) => (eve.label.replace(/\s+/g, "").toLowerCase())) : event[0]?.label.replace(/\s+/g, "").toLowerCase();
            refFilterResultForm.sortByProgress.value = event;
            setFilterResultForm(refFilterResultForm);
            setSortByProgressData(sortby === "sortByProgress" && evenData);
        }
    };

    function triggerFunc() {
        if ((sortByProgressData == undefined || sortByProgressData == "") && (sortByAreaData == undefined || sortByAreaData == "")) {
            setDisable(true)
        }
        if (filterStatus == false && sortByAreaData == undefined) {
            setDisable(true)
        }
    }

    const applyFilterHandler = (event) => {
        setResFilterd(!resFilterd)
        setMobileFilter(false)
        let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
        refFilterResultForm.isFilterApplied = true;
        fetchSortedData(assessmentCardData, sortByAreaData, sortByProgressData);
    };

    const searchCard = (keyword) => {
        setSearchKeyword(keyword);
        if (keyword?.length >= 2) {
            setEnableSearch(false);
        } else if (keyword?.length > 0 || keyword?.length == undefined) {
            setSearchKeyword("");
            fetchSortedData(assessmentCardData, null, null, "");
            if (assessmentCardData?.length > 4) {
                setShowLoadMore(true);
                displayLoadMore(true);
            }
            setEnableSearch(true);
        }
    };

    const searchHandler = (event) => {
        setEnableSearch(true);
        fetchSortedData(assessmentCardData);
    };

    const clearAllFilterHandler = () => {
        setDisable(true)
        let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
        refFilterResultForm.sortByArea.value = [];
        refFilterResultForm.sortByProgress.value = [];
        setShowLoadMore(true);
        displayLoadMore(true);
        setFilterResultForm(refFilterResultForm);
        fetchSortedData(assessmentCardData);
        setSortByProgressData(false)
        setSortByAreaData(false)
    };

    const searchFilterFormProps = {
        salarySelectProps: {
            id: filterResultForm?.sortByArea?.name,
            label: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.FilterByArea"
            ),
            name: filterResultForm?.sortByArea?.name,
            placeholder: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.AllAreas"
            ),
            options: sortByArea,
            onChangeHandler: (event) =>
                filterFormHandler(event, filterResultForm?.sortByArea?.name),
            selectedValue: filterResultForm?.sortByArea?.value,
            disableSearch: true,
            hasSelectAll: false,
        },
        filterName: isMobile ? "Filters" : "",
        contractSelectProps: {
            id: filterResultForm?.sortByProgress?.name,
            label: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.FilterByProgress"
            ),
            name: filterResultForm?.sortByProgress?.name,
            placeholder: fetchLikeLabel("CommonComponent.CommonSelectPlaceHolder"),
            options: sortByProgress,
            onChangeHandler: (event) =>
                filterFormHandler(event, filterResultForm?.sortByProgress?.name),
            selectedValue: filterResultForm?.sortByProgress?.value,
            disableSearch: true,
            hasSelectAll: false,
        },
        applyFiltersButtonProps: {
            children: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.ApplyButton"
            ),
            size: "Large",
            type: "button",
            onClick: (event) =>
                applyFilterHandler(
                    event,
                    filterResultForm?.sortByArea?.value != ""
                        ? filterResultForm?.sortByArea?.name
                        : "",
                    filterResultForm?.sortByProgress?.value != ""
                        ? filterResultForm?.sortByProgress?.name
                        : ""
                ),
            disabled: disable,
        },
        title: "Filters",
        resetFiltersLinkButtonProps: {
            children: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.ClearAllFilter"
            ),
            type: "reset",
            onClick: (event) => clearAllFilterHandler(),
        },
        onSubmit: (event) => {
            alert("form submitted");
        },
    };

    const searchPropsForToolsResources = {
        salarySelectProps: {
            id: filterResultForm?.sortByArea.name,
            label: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.FilterByArea"
            ),
            title: "Filters",
            name: filterResultForm?.sortByArea.name,
            placeholder: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.AllAreas"
            ),
            options: sortByArea,
            onChangeHandler: (event) =>
                filterFormHandler(event, filterResultForm?.sortByArea.name),
            selectedValue: filterResultForm?.sortByArea.value,
            disableSearch: true,
            hasSelectAll: false,
        },
        filterName: isMobile ? "Filters" : "",
        applyFiltersButtonProps: {
            children: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.ApplyButton"
            ),
            size: "Large",
            type: "button",
            onClick: (event) =>
                applyFilterHandler(
                    event,
                    filterResultForm?.sortByArea.value != ""
                        ? filterResultForm?.sortByArea.name
                        : "",
                    filterResultForm?.sortByProgress.value != ""
                        ? filterResultForm?.sortByProgress.name
                        : ""
                ),
            disabled: disable,
        },
        resetFiltersLinkButtonProps: {
            children: fetchLRLabels(
                "DiscoveryPage.AllLearningResourcesFilter.ClearAllFilter"
            ),
            type: "reset",
            onClick: (event) => clearAllFilterHandler(),
        },
        onSubmit: (event) => {
            alert("form submitted");
        }
    };

    const input = document && document.getElementById("search") && document.getElementById("search");
    input && input.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
            searchHandler()
        }
    });

    function getLabel(key) {
        let labelList = learningCollectionCMS.LOVList && learningCollectionCMS?.LOVList[0]?.Values;
        let val = labelList?.filter((x) => (x.Key === key))
        return (val && val[0].Value)
    }

    return (
        <>

            <div className="discover-welcome">
                <Cirrus.PageBanner
                    bodyText={discoverCMSData?.description}
                    heading={discoverCMSData?.title}
                />
                <Cirrus.OneColumnLayout type="wrapped">
                    {
                        (getProfileDetails?.personaName !== "CareerProgram" && getProfileDetails?.personaName !== "Entrepreneur") && <Container fluid className="self-discovery-recommnded">
                            <div className="recommended-section">
                                <div className="carousel discover-carousel-buttons">
                                    {recommdedAssessmentCardData && (
                                        <Cirrus.Carousel
                                            nextIconButtonProps={{
                                                icon: "arrow_forward",
                                                onClick: () => { },
                                                text: "Next",
                                                type: "button",
                                            }}
                                            heading={learningCollectionCMS?.recommdedTitle}
                                            subheading={learningCollectionCMS?.recommdedDescription}
                                            previousIconButtonProps={{
                                                icon: "arrow_back",
                                                onClick: function noRefCheck() { },
                                                text: "Previous",
                                                type: "button",
                                            }}
                                        >
                                            {recommdedAssessmentCardData?.slice(0, recommdedAssessmentCardData.length > 12 ? 12 : recommdedAssessmentCardData.length).map((item, idx) => {
                                                if (item?.resourceType?.id == "E3")
                                                    context.resourceDetails = item
                                                return <Cirrus.RecommndedResourcesCard
                                                    className="card"
                                                    title={item && item.title && item.title}
                                                    description={
                                                        item &&
                                                        item.shortDescription &&
                                                        item.shortDescription
                                                    }
                                                    onCardClick={() => { cardRedirectHandler(item) }}
                                                    tags={item.tags}
                                                    image={{ src: item && item?.largeImage?.imageUrl }}
                                                    saveButton={{
                                                        label: fetchLikeLabel("CommonComponent.CommonSaveButton"),
                                                        secondaryLabel: fetchLikeLabel("CommonComponent.CommonSavedButton"),
                                                        onClick: (e) => {
                                                            saveAssessmentCard(
                                                                e,
                                                                item && item.resourceId && item.resourceId,
                                                                item && item.isSaved && item.isSaved,
                                                                item.isLiked && item.isLiked,
                                                                "save",
                                                                item &&
                                                                item.learningResourceStatus &&
                                                                item.learningResourceStatus,
                                                                item.lastAnsweredPageId &&
                                                                item.lastAnsweredPageId,
                                                                item?.resourceType?.id,
                                                                item?.resourceCategoryType?.id,
                                                                "recommended"
                                                            );
                                                        },
                                                    }}
                                                    isSaved={item && item.isSaved && item.isSaved}
                                                    status={{
                                                        name: "new",
                                                        label:
                                                            item &&
                                                                item.resourceType &&
                                                                item.resourceType.value
                                                                ? item.resourceType.value
                                                                : "Assessment",
                                                        value:
                                                            item &&
                                                                item.learningResourceStatus === "notstarted"
                                                                ? item && item.duration && item.duration
                                                                : fetchLearningStatus(
                                                                    item && item.learningResourceStatus
                                                                ),
                                                    }}
                                                    likeText={
                                                        item?.likeCount == 0
                                                            ? fetchLikeLabel("CommonComponent.CommonLikeText")
                                                            : item?.likeCount == 1
                                                                ? item?.likeCount +
                                                                " " +
                                                                fetchLikeLabel("CommonComponent.CommonLikeText")
                                                                : item?.likeCount > 1
                                                                    ? item?.likeCount +
                                                                    " " +
                                                                    fetchLikeLabel(
                                                                        "CommonComponent.CommonLikesText"
                                                                    )
                                                                    : ""
                                                    }
                                                    onLikeButtonClick={(e) => {
                                                        saveAssessmentCard(
                                                            e,
                                                            item && item.resourceId && item.resourceId,
                                                            item.isSaved && item.isSaved,
                                                            item && item.isLiked && item.isLiked,
                                                            "like",
                                                            item &&
                                                            item.learningResourceStatus &&
                                                            item.learningResourceStatus,
                                                            item.lastAnsweredPageId &&
                                                            item.lastAnsweredPageId,
                                                            item?.resourceType?.id,
                                                            item?.resourceCategoryType?.id,
                                                            "recommended"
                                                        )
                                                        saveActivity2(item)
                                                    }
                                                    }
                                                    isLiked={item && item.isLiked && item.isLiked}
                                                    type="assessment"
                                                    layout="horizontal"
                                                />
                                            }
                                            )}
                                        </Cirrus.Carousel>
                                    )}
                                </div>
                            </div>
                        </Container>
                    }

                    <Container
                        fluid
                        className="background-White learning-collections"
                    >
                        <Row>
                            <Cirrus.Header className="heading heading_learning-collections" size="h5">
                                {/* {console.log(learningCollectionCMS)} */}
                                {learningCollectionCMS?.title}
                            </Cirrus.Header>
                            <p className="description description_learning-collections">
                                {learningCollectionCMS?.description}
                            </p>
                            <div className="resource-card">
                                {learningCollectionCMS &&
                                    learningCollectionCMS.pageContent &&
                                    learningCollectionCMS.pageContent.map((item, idx) => (
                                        <div>
                                            <Cirrus.LearningCollectionCard
                                                // title={item && item.PageUrl && item.PageUrl.Text}
                                                title={item && item.Title}
                                                description={
                                                    item && item.Description && item.Description
                                                }
                                                link={{
                                                    url: `/#${item && item.PageUrl?.Url
                                                        }`,
                                                }}
                                                image={{ src: item && item?.LargeImage?.ImageUrl }}
                                                status={{
                                                    name: "new",
                                                    label:
                                                        item?.Count &&
                                                            item?.Count.length > 1 &&
                                                            item?.Count[0] &&
                                                            item.Count[0]?.ResourceCount &&
                                                            item.Count[0]?.Resource
                                                            ? item.Count[0]?.ResourceCount <= 50
                                                                ? item.Count[0]?.ResourceCount +
                                                                " " +
                                                                item.Count[0]?.Resource
                                                                : "50+" + " " + item.Count[0]?.Resource
                                                            : "",
                                                    label2:
                                                        item.Count &&
                                                            item?.Count.length > 1 &&
                                                            item?.Count[1] &&
                                                            item.Count[1]?.ResourceCount &&
                                                            item.Count[1]?.Resource
                                                            ? item.Count[1]?.ResourceCount <= 50
                                                                ? item.Count[1]?.ResourceCount +
                                                                " " +
                                                                item.Count[1]?.Resource
                                                                : "50+" + " " + item.Count[1]?.Resource
                                                            : "",
                                                    label3:
                                                        item.Count &&
                                                            item?.Count.length > 1 &&
                                                            item?.Count[2] &&
                                                            item.Count[2]?.ResourceCount &&
                                                            item.Count[2]?.Resource
                                                            ? item.Count[2]?.ResourceCount <= 50
                                                                ? item.Count[2]?.ResourceCount +
                                                                " " +
                                                                item.Count[2]?.Resource
                                                                : "50+" + " " + item.Count[2]?.Resource
                                                            : "",
                                                }}
                                                type="assessment"
                                                layout="horizontal"
                                            />
                                        </div>
                                    ))}
                            </div>
                            {
                                moreCollectionCMS.pageContent && <div className="more-collections">
                                <Cirrus.Header size="h5">
                                    {moreCollectionCMS?.title}
                                </Cirrus.Header>
                                <p className="description">{moreCollectionCMS?.description}</p>
                                <div className="button-container">
                                    {moreCollectionCMS &&
                                        moreCollectionCMS.pageContent &&
                                        moreCollectionCMS.pageContent.map((item, idx) => (
                                            <div>
                                                <Cirrus.Button
                                                    className="more-collection-button"
                                                    isSecondary
                                                    onClick={() => {
                                                        navigate(
                                                            `${item && item.PageUrl?.Url}`,
                                                            { state: { item: item } }
                                                        );
                                                    }}
                                                >
                                                    {item && item.Title}
                                                </Cirrus.Button>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            }
                            
                        </Row>
                    </Container>

                    <Container fluid className="learning-collections all-learning-resource" id={'resourcePage'}>
                        <Row>
                            <Cirrus.Header className="heading" size="h5">
                                {allLearningResources?.title}
                            </Cirrus.Header>
                            <p className="description">{allLearningResources?.description}</p>
                            <div className="nav-section">
                                <div className="button-container">
                                    <div>
                                        <Cirrus.Button
                                            isSecondary={assessmentSecondary}
                                            onClick={() => {
                                                setAssessmentSecondary(false);
                                                assessmentData && assessmentData.length > 0 && setAssessmentCardData(assessmentData && assessmentData);
                                                assessmentData && assessmentData.length <= 0 ? fetchAssessmentData("assessments") : fetchSortedData(assessmentData && assessmentData);
                                                setFilterStatus(true)
                                                setToolsSecondary(true);
                                                setResourcesSecondary(true);
                                            }}
                                            trailingNumber={window.sessionStorage.getItem("AssessmentsCount")}
                                        >
                                            {getLabel("LearningResourceTypes.AssessmentsandTasks")}
                                        </Cirrus.Button>
                                    </div>
                                    <div>
                                        <Cirrus.Button
                                            isSecondary={toolsSecondary}
                                            onClick={() => {
                                                setToolsSecondary(false);
                                                toolsData && toolsData.length > 0 && setAssessmentCardData(toolsData && toolsData);
                                                ((context.languageChanged) || (toolsData && toolsData.length <= 0)) ? fetchAssessmentData("tools") : fetchSortedData(toolsData && toolsData);
                                                setFilterStatus(false)
                                                setAssessmentSecondary(true);
                                                setResourcesSecondary(true);
                                            }}
                                            trailingNumber={window.sessionStorage.getItem("ToolsCount")}
                                        >
                                            {getLabel("LearningResourceTypes.Tools")}
                                        </Cirrus.Button>
                                    </div>
                                    <div>
                                        <Cirrus.Button
                                            isSecondary={resourcesSecondary}
                                            onClick={() => {
                                                setResourcesSecondary(false);
                                                resourcesData && resourcesData.length > 0 && setAssessmentCardData(resourcesData && resourcesData);
                                                ((context.languageChanged) || (resourcesData && resourcesData.length <= 0)) ? fetchAssessmentData("resources") : fetchSortedData(resourcesData && resourcesData);
                                                setFilterStatus(false)
                                                setToolsSecondary(true);
                                                setAssessmentSecondary(true);
                                            }}
                                            trailingNumber={window.sessionStorage.getItem("ResourcesCount")}
                                        >
                                            {getLabel("LearningResourceTypes.Resources")}
                                        </Cirrus.Button>
                                    </div>
                                </div>
                                <div className="search-filters">
                                    <Cirrus.Input
                                        icon="search"
                                        id="search"
                                        isRounded
                                        onBlur={() => { }}
                                        onChange={(event) => searchCard(event.target.value)}
                                        searchButtonProps={{
                                            children: fetchLRLabels("DiscoveryPage.AllLearningResourcesFilter.SearchButton"),
                                            size: "large",
                                            type: "button",
                                            onClick: () => searchHandler(),
                                            disabled: enableSearch,
                                        }}
                                        showSearchButton
                                        placeholder={fetchLRLabels(
                                            "DiscoveryPage.AllLearningResourcesFilter.KeywordSearchText"
                                        )}
                                    />
                                </div>
                                <div>
                                    <Cirrus.Button
                                        leadingIcon={isFilterOpen ? "close" : "filter_alt"}
                                        isAlt={true}
                                        isSecondary={true}
                                        onClick={() => {
                                            setIsFilterOpen(!isFilterOpen);
                                        }}
                                        className={
                                            isFilterOpen
                                                ? "filter-button filter-button_filter is-active"
                                                : "filter-button filter-button_filter"
                                        }
                                    >
                                        {fetchLRLabels(
                                            "DiscoveryPage.AllLearningResourcesFilter.FiltersButton"
                                        )}
                                    </Cirrus.Button>
                                </div>
                            </div>

                            {isFilterOpen && !isMobile && (
                                filterStatus ?
                                    <div className="filter-model">
                                        <Cirrus.ResourceSearchFiltersPopup
                                            className="filterResultResources"
                                            resourceSearchFiltersFormProps={searchFilterFormProps}
                                        />
                                    </div>
                                    :
                                    <div className="filter-model">
                                        <Cirrus.ResourceSearchFilterPopup
                                            className="filterResultResources"
                                            resourceSearchFilterFormProps={searchPropsForToolsResources}
                                        />
                                    </div>
                            )}

                            <div className="responsive mb-3">
                                <Cirrus.Select
                                    placeholder="All Areas"
                                    options={[
                                        { name: getLabel("LearningResourceTypes.AssessmentsandTasks"), value: "assessments" },
                                        { name: getLabel("LearningResourceTypes.Tools"), value: "tools" },
                                        { name: getLabel("LearningResourceTypes.Resources"), value: "resources" }
                                    ]}
                                    onChange={(e) => fetchAssessmentData(e.target.value)}
                                />
                            </div>

                            {isMobile &&
                                <div className="responsive_search mb-4">
                                    <Cirrus.Button
                                        children={"Search & Filters"}
                                        onClick={() => {
                                            setResFilterd(!resFilterd)
                                            setMobileFilter(true);
                                        }
                                        }
                                    />
                                </div>
                            }
                            <div className="assessment-card-block">
                                {chunkedData && chunkedData?.length >= 1 ? (
                                    chunkedData.map((item, idx) => (
                                        <div
                                            className={
                                                chunkedData && chunkedData?.length == 1
                                                    ? "assessment-card1"
                                                    : "assessment-card"
                                            }
                                            onClick={() => item?.key === "Tools.DnBHover" || item?.key === "Tools.SmartResumeEditor" ? ActivityAction.SaveActivity(item?.resourceId, "Tool", item?.title) : null}
                                        >

                                            <Cirrus.Card
                                                title={item && item.title && item.title}
                                                leadingIcon={item.key === "Tools.PersonalityAssessment" || item.key === "Tools.SmartResumeEditor" || item?.key === "Tools.DnBHover" || item?.key === "Tools.30SecondCommercial" || item?.key === "Tools.OnDemandVideoInterviews" ? true : false}
                                                description={
                                                    item && item.shortDescription && item.shortDescription
                                                }
                                                onCardClick={() => { cardRedirectHandler(item) }}
                                                tags={item.tags}
                                                image={{
                                                    src:
                                                        item &&
                                                        item.largeImage &&
                                                        item.largeImage.imageUrl &&
                                                        item.largeImage.imageUrl,
                                                }}
                                                saveButton={{
                                                    label: fetchLikeLabel("CommonComponent.CommonSaveButton"),
                                                    secondaryLabel: fetchLikeLabel("CommonComponent.CommonSavedButton"),
                                                    onClick: (e) => {
                                                        saveAssessmentCard(
                                                            e,
                                                            item && item.resourceId && item.resourceId,
                                                            item && item.isSaved && item.isSaved,
                                                            item.isLiked && item.isLiked,
                                                            "save",
                                                            item &&
                                                            item.learningResourceStatus &&
                                                            item.learningResourceStatus,
                                                            item.lastAnsweredPageId &&
                                                            item.lastAnsweredPageId,
                                                            item?.resourceType?.id,
                                                            item?.resourceCategoryType?.id,
                                                            "na"
                                                        );
                                                    },
                                                }}
                                                isSaved={item && item.isSaved && item.isSaved}
                                                status={{
                                                    name: "new",
                                                    label:

                                                        item && item.resourceType && item.resourceType.value
                                                            ? item.resourceType.value
                                                            : "",
                                                    value:
                                                        item &&

                                                            item.learningResourceStatus === "notstarted" &&

                                                            item &&

                                                            item.resourceType &&

                                                            item.resourceType.value !== "Tool" && item && item.key !== "Tools.PersonalityAssessment"

                                                            ? item && item.duration && item.duration

                                                            : item &&

                                                                item.learningResourceStatus === "notstarted" &&

                                                                item &&

                                                                item.resourceType &&

                                                                item.resourceType.value === "Tool"

                                                                ? item?.key === "Tools.OnDemandVideoInterviews"

                                                                    ? iviewCount && iviewCount

                                                                    : "Interactive"

                                                                : item &&

                                                                    item.resourceType &&

                                                                    item.resourceCategoryType.value === "Resources"

                                                                    ? item?.duration


                                                                    : fetchLearningStatus(

                                                                        item && item.learningResourceStatus

                                                                    ),
                                                }}
                                                likeText={
                                                    item?.likeCount == 0
                                                        ? fetchLikeLabel("CommonComponent.CommonLikeText")
                                                        : item?.likeCount == 1
                                                            ? item?.likeCount +
                                                            " " +
                                                            fetchLikeLabel("CommonComponent.CommonLikeText")
                                                            : item?.likeCount > 1
                                                                ? item?.likeCount +
                                                                " " +
                                                                fetchLikeLabel("CommonComponent.CommonLikesText")
                                                                : ""
                                                }
                                                onLikeButtonClick={(e) => {
                                                    saveAssessmentCard(
                                                        e,
                                                        item && item.resourceId && item.resourceId,
                                                        item.isSaved && item.isSaved,
                                                        item && item.isLiked && item.isLiked,
                                                        "like",
                                                        item &&
                                                        item.learningResourceStatus &&
                                                        item.learningResourceStatus,
                                                        item.lastAnsweredPageId && item.lastAnsweredPageId,
                                                        item?.resourceType?.id,
                                                        item?.resourceCategoryType?.id,
                                                        "na"
                                                    )
                                                    saveActivity2(item)
                                                }
                                                }
                                                isLiked={item && item.isLiked && item.isLiked}
                                                type="assessment"
                                                layout="horizontal"
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <Cirrus.Label>
                                        {fetchResourceLabel("CommonResourceComponent.LearningResourcesNotFound")}
                                    </Cirrus.Label>
                                )}
                            </div>
                        </Row>
                        {displayLoadMore(showLoadMore)}
                    </Container>

                    {mobileFilter &&
                        <Cirrus.Modal
                            size="mobile"
                            className="responsive-filter-modal"
                            closeButtonProps={{
                                type: "button",
                                isSecondary: false,
                                size: 'small',
                                children: 'X  Close',
                                onClick: () => {
                                    setMobileFilter(false)
                                    setResFilterd(!resFilterd)
                                }
                            }}>
                            <div className='responsive_filter-model'>
                                <div className="responsive_search-filters mb-4 m-3">
                                    <Cirrus.Input
                                        icon="search"
                                        id="search"
                                        onChange={(event) => searchCard(event.target.value)}
                                        placeholder={fetchLRLabels(
                                            "DiscoveryPage.AllLearningResourcesFilter.KeywordSearchText"
                                        )}
                                    />
                                </div>
                                <div className="m-3">
                                    {filterStatus ?
                                        <Cirrus.ResourceSearchFiltersPopup
                                            className="responsive_filterResultResources"
                                            resourceSearchFiltersFormProps={searchFilterFormProps}
                                        />
                                        :
                                        <Cirrus.ResourceSearchFilterPopup
                                            className="responsive_filterResultResources"
                                            resourceSearchFilterFormProps={searchPropsForToolsResources}
                                        />}
                                </div>
                            </div>
                        </Cirrus.Modal>
                    }

                    {showDocebo &&
                        <Container fluid>
                            <Row>

                                <Col>
                                    {/* <h2 className="mb-4">Docebo Skills</h2> */}
                                    <div className="resource-card skills docebo-skills">
                                        {doceboEvents && (
                                            <div onClick={() => ActivityAction.SaveActivity("null", "skillsPortal", doceboEvents?.Title)}>
                                                <Cirrus.DoceboCard
                                                    title={doceboEvents?.Title}
                                                    description={doceboEvents?.Description}
                                                    image={{ src: doceboEvents?.MediaData?.LargeImage?.ImageUrl }}
                                                    primaryButtonProps={{
                                                        children: fetchLRLabels(
                                                            "Events.EventsDiscoverDoceboCard.VisitSkillsPortal"
                                                        ),
                                                        size: "Large",
                                                        href: doceboURL,
                                                        isAlt: true,
                                                        leadingIcon: "open_in_new",
                                                        target: "_blank",
                                                    }}
                                                    tags={[
                                                        fetchLRLabels(
                                                            "Events.EventsDiscoverDoceboCard.500PlusResources"
                                                        ),
                                                        fetchLRLabels(
                                                            "Events.EventsDiscoverDoceboCard.ExternalResource"
                                                        ),
                                                    ]}
                                                    isWide={true}
                                                    className="clsHeading"
                                                    type="event"
                                                    layout="horizontal"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    }

                    <Container fluid className="docebo-card">

                    </Container>
                </Cirrus.OneColumnLayout>
            </div>
        </>
    );
}

export default Discover;
