//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as Cirrus from "manpowergroup-cirrus";
import "./index.css";
import Header from "../../header/simpleHeader";
import { Context } from "../../../common/context/Context";
import { OnBoardingActions } from "../OnBoardingActions";
import Spinner from "../../../common/components/spinner-loader/Spinner";
import background from "manpowergroup-cirrus/assets/images/background.png";
import { useMsal } from "@azure/msal-react";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "../../../common/helpers/AuthService";
import Footer from "../../../common/components/footer";
import { AssessmentActions } from "../../assessments/AssessmentActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../../common/utility/LogAction";
import { ActivityAction } from "../../../common/utility/ActivityActions";

function Landing() {
  const browserLang = navigator.language || navigator.userLanguage;
  const { instance, accounts, inProgress } = useMsal();
  const appInsights = useAppInsightsContext();
  const context = useContext(Context);
  const navigate = useNavigate();
  const location = useLocation();
  const [cmsContent, setCmsContent] = useState({
    labels: [],
    description: "",
    image: "",
  });
  const [screenLoader, setScreenLoader] = useState(true);
  const [forgotPasswordFlag, setForgotPasswordFlag] = useState(true);
  const [inactiveAccountMsg, setInactiveAccountMsg] = useState(false);
  const [aquireTokenErrorMsg, setAquireTokenErrorMsg] = useState(false);

  window.onhashchange = function (e) {
    const forgottenURL = e.oldURL.indexOf("#error=access_denied");
    if (forgottenURL > 0) {
      setScreenLoader(false);
      setForgotPasswordFlag(false);
    }
  };
  const navigateToForgotPassword = () => {
    instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
  };

  useEffect(() => {
    if (context.beforeDashboardLogoRedirection === false) {
      let accessToken = window.sessionStorage.getItem("AccessToken");
      if (accounts.length > 0) {
        let passwordreset = false;
        let signin = false;
        let signup = false;
        let refSigninAccount = [];
        let refSignupAccount = [];

        accounts.map((name) => {
          if (name.homeAccountId.includes("passwordreset")) {
            passwordreset = true;
          }
          if (name.homeAccountId.includes("signin")) {
            refSigninAccount.push(name);
            signin = true;
          }
          if (name.homeAccountId.includes("signup")) {
            refSignupAccount.push(name);
            signup = true;
          }
        });

        if (signup) {
          if (
            accounts.length > 0 &&
            inProgress === InteractionStatus.None &&
            accessToken === null
          ) {
            signUpHandler(refSignupAccount);
          }
        } else {
          if (passwordreset) {
            localStorage.clear();
            sessionStorage.clear();
            instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          } else {
            if (
              accounts.length > 0 &&
              inProgress === InteractionStatus.None &&
              accessToken === null
            ) {
              signInHandler(refSigninAccount);
            }
          }
        }
      }
    }
    let refSetProgramDetails = JSON.parse(
      window.sessionStorage.getItem("setProgramDetails")
    );
    if (
      refSetProgramDetails !== null &&
      refSetProgramDetails?.candidateProgramStatus === "InActive"
    ) {
      setInactiveAccountMsg(true);
    }

    let refAcquireTokenRedirectFlag = JSON.parse(
      window.sessionStorage.getItem("acquireTokenRedirectFlag")
    );
    if (refAcquireTokenRedirectFlag !== null) {
      setAquireTokenErrorMsg(true);
    }
  }, [accounts, inProgress]);

  const signInHandler = async (refAccount) => {
    const accessTokenRequest = {
      scopes: ["openid", "profile"],
      account: refAccount,
    };
    await instance
      .acquireTokenSilent(accessTokenRequest)
      .then((tokenResponse) => {
        window.sessionStorage.setItem("AccessToken", tokenResponse.idToken);

        let idTokenClaims = tokenResponse?.idTokenClaims;
        context.setIdTokenClaims(idTokenClaims);
        window.sessionStorage.setItem(
          "setIdTokenClaims",
          JSON.stringify(idTokenClaims)
        );
        window.sessionStorage.setItem(
          "LoginCandidateToken",
          JSON.stringify({
            candidateID: idTokenClaims.signupId,
            email: idTokenClaims.email,
            registrationCode: idTokenClaims.extension_RegistrationCode,
          })
        );

        apiCall(
          idTokenClaims.signupId,
          idTokenClaims.extension_RegistrationCode
        );
      });
  };

  const signUpHandler = (refAccount) => {
    const request = {
      scopes: ["openid", "profile"],
      account: refAccount,
    };
    instance
      .acquireTokenSilent(request)
      .then((tokenResponse) => {
        window.sessionStorage.setItem("AccessToken", tokenResponse.idToken);

        let idTokenClaims = accounts[0].idTokenClaims;
        context.setIdTokenClaims(idTokenClaims);
        window.sessionStorage.setItem(
          "setIdTokenClaims",
          JSON.stringify(idTokenClaims)
        );
        window.sessionStorage.setItem(
          "LoginCandidateToken",
          JSON.stringify({
            candidateID: idTokenClaims.signupId,
            email: idTokenClaims.email,
            registrationCode: idTokenClaims.extension_RegistrationCode,
          })
        );

        apiCall(
          idTokenClaims.signupId,
          idTokenClaims.extension_RegistrationCode
        );
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          let refSetProgramDetails = JSON.parse(
            window.sessionStorage.getItem("acquireTokenRedirectFlag")
          );
          if (refSetProgramDetails === null) {
            window.sessionStorage.setItem("acquireTokenRedirectFlag", true);
            instance.acquireTokenRedirect(request);
          } else {
            instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          }
        }
        console.log(error);
      });
  };

  const apiCall = (signupId, RegistrationCode) => {
    sessionStorage.removeItem("acquireTokenRedirectFlag");
    OnBoardingActions.GetFeaturesConfig().then(
      (res) => {
        if (res.status === 200) {
          window.sessionStorage.setItem(
            "featuresConfig",
            JSON.stringify(res?.data)
          );
        } else {
          console.log(res);
        }
      },
      (err) => {
        console.log(err);
      }
    );
    getTimezone();
    getOnBoardingStatus(signupId, RegistrationCode);
    OnBoardingActions.SaveCandidateLoginSession({
      modeOfLogin: isMobileDevice(),
      geoCoordinates: "10.100.100",
    }).then((res) => {
      saveActivity("null", "login", "null");
      TrackActions.EventTrack(appInsights, "SignIn", { LoginTime: new Date() });
      TrackActions.PageAction("SignIn", { LoginTime: new Date() });
    });
  };

  useEffect(() => {
    //if (inProgress !== "handleRedirect") {
      fetchCMSContent("Normal");
    //}
  }, [context.language]);

  const fetchCMSContent = async (callFlag, pageName) => {
    if (JSON.parse(window.sessionStorage.getItem("AccessToken") == undefined)) {
      TrackActions.EventTrack(appInsights, "LogOut / SessionTimeout");
      TrackActions.PageAction("LogOut / SessionTimeout");
    }
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let payload = {
      PageName: "OnboardingPages",
    };
    await OnBoardingActions.GetOnBoardingCMSContent(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          context.setCmsOnBoadingContent(res.data);
          window.sessionStorage.setItem(
            "setCmsOnBoadingContent",
            JSON.stringify(res.data)
          );
          if (callFlag === "Normal") {
            resData.Pages.filter((x) => {
              if (x.Key === "WelcomePage") {
                refCmsContent.labels =
                  x.Components[
                    "WelcomePage.OnboardingRegisterWelcomePanel"
                  ]?.Labels;
                refCmsContent.description = x.Description;
                refCmsContent.image =
                  x.Components[
                    "WelcomePage.OnboardingRegisterWelcomePanel"
                  ]?.MediaData?.LargeImage;
              }
            });
            setCmsContent(refCmsContent);

            if (inProgress !== "handleRedirect") {
              setScreenLoader(false);
            }
            let redirect = JSON.parse(
              window.sessionStorage.getItem("RedirectURL")
            );
            if (
              inProgress === "handleRedirect" &&
              redirect !== null &&
              location.pathname !== "/"
            ) {
              setScreenLoader(false);
            }
          } else {
            let redirect = JSON.parse(
              window.sessionStorage.getItem("RedirectURL")
            );
            if (redirect != null) {
              navigate(redirect);
            } else {
              navigate(pageName);
            }
            setScreenLoader(false);
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const getOnBoardingStatus = async (candidateID, registrationCode) => {
    await OnBoardingActions.GetOnBoardingStatus(candidateID).then(
      (obsResp) => {
        if (obsResp.status === 200) {
          window.sessionStorage.setItem(
            "onBoardingStatus",
            JSON.stringify(obsResp.data)
          );
          getProgramWithRedirectionHandler(
            candidateID,
            registrationCode,
            obsResp.data
          );
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const getProgramWithRedirectionHandler = async (
    candidateID,
    registrationCode,
    onBoardingResp
  ) => {
    await OnBoardingActions.CandidateProgramDetails(registrationCode).then(
      (cpdResp) => {
        if (cpdResp.status === 200) {
          context.setProgramDetails(cpdResp.data);
          window.sessionStorage.setItem(
            "setProgramDetails",
            JSON.stringify(cpdResp.data)
          );

          if (cpdResp?.data?.candidateProgramStatus === "InActive") {
            sessionStorage.clear();
            localStorage.clear();
            window.sessionStorage.setItem(
              "setProgramDetails",
              JSON.stringify(cpdResp.data)
            );
            instance.logoutRedirect({ postLogoutRedirectUri: "/" });
          } else {
            if (onBoardingResp.isRegistrationCompleted) {
              pageRedirectHandler(candidateID, cpdResp.data, "/dashboard");
            } else {
              let featuresConfig = JSON.parse(
                window.sessionStorage.getItem("featuresConfig")
              );
              if (!onBoardingResp.isConsentAndApprovalCompleted) {
                beforeProfilePageRedirectHandler(
                  candidateID,
                  cpdResp.data,
                  onBoardingResp
                );
              } else if (!onBoardingResp.isProfileCreated) {
                beforeProfilePageRedirectHandler(
                  candidateID,
                  cpdResp.data,
                  onBoardingResp
                );
              } else if (
                !onBoardingResp.isOnboardingCompleted &&
                !onBoardingResp.isRegistrationCompleted
              ) {
                if (featuresConfig?.isOnboardingQuestionsAvailable) {
                  pageRedirectHandler(
                    candidateID,
                    cpdResp.data,
                    "/welcome-on-boarding"
                  );
                } else {
                  pageRedirectHandler(candidateID, cpdResp.data, "/dashboard");
                }
              } else {
                if (featuresConfig?.isOnboardingQuestionsAvailable) {
                  pageRedirectHandler(candidateID, cpdResp.data, "/questions");
                } else {
                  pageRedirectHandler(candidateID, cpdResp.data, "/dashboard");
                }
              }
            }
          }
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const pageRedirectHandler = async (candidateID, cpdResp, pageName) => {
    await OnBoardingActions.GetcandidateProfile(candidateID).then(
      (cpResp) => {
        if (cpResp.status === 200) {
          window.sessionStorage.setItem(
            "setProfileDetails",
            JSON.stringify(cpResp.data)
          );

          if (cpResp?.data?.theme !== undefined) {
            context?.setTheme(cpResp?.data?.theme);
            window.sessionStorage.setItem("selectedtheme", cpResp?.data?.theme);
          } else {
            context?.setTheme("t-light");
            window.sessionStorage.setItem("selectedtheme", "t-light");
          }

          window.sessionStorage.setItem(
            "Language",
            JSON.stringify({
              selectedCountryFullName: cpdResp?.programCountry,
              selectedCountryShortName: cpdResp?.programCountryCode,
              selectedLanguageISOCode: cpResp?.data?.preferredLanguageCode,
            })
          );
          sessionStorage.removeItem("headerCache");
          sessionStorage.removeItem("footerCache");
          fetchCMSContent("pageRedirect", pageName);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const beforeProfilePageRedirectHandler = async (
    candidateID,
    cpdResp,
    onBoardingResp
  ) => {
    if (!onBoardingResp.isConsentAndApprovalCompleted) {
      let PolicyConsentObj = {
        candidateId: candidateID,
        isTermsAndConditionsAccepted: true,
        isPrivacyConsentAccepted: true,
      };
      await OnBoardingActions.SaveUserConsent(
        candidateID,
        PolicyConsentObj
      ).then(
        (sucResp) => {
          if (sucResp.status === 200 || sucResp.status === 204) {
            window.sessionStorage.setItem(
              "Language",
              JSON.stringify({
                selectedCountryFullName: cpdResp?.programCountry,
                selectedCountryShortName: cpdResp?.programCountryCode,
                selectedLanguageISOCode: cpdResp?.programLanguageCode,
              })
            );
            sessionStorage.removeItem("headerCache");
            sessionStorage.removeItem("footerCache");
            fetchCMSContent("pageRedirect", "/signup-welcome");
          } else {
            setScreenLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setScreenLoader(false);
        }
      );
    } else {
      window.sessionStorage.setItem(
        "Language",
        JSON.stringify({
          selectedCountryFullName: cpdResp?.programCountry,
          selectedCountryShortName: cpdResp?.programCountryCode,
          selectedLanguageISOCode: cpdResp?.programLanguageCode,
        })
      );
      sessionStorage.removeItem("headerCache");
      sessionStorage.removeItem("footerCache");
      fetchCMSContent("pageRedirect", "/signup-welcome");
    }
  };

  const getTimezone = () => {
    OnBoardingActions.MasterTimezoneData("timezone").then(
      (res) => {
        if (res.status === 200) {
          context.setMasterTimezoneDetails(res.data);
          window.sessionStorage.setItem(
            "MasterTimezoneDetails",
            JSON.stringify(res.data)
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const navigateTo = () => {
    navigate("/register-firstTimeUser");
  };

  const redirectingADB2C = () => {
    sessionStorage.removeItem("acquireTokenRedirectFlag");
    sessionStorage.removeItem("AccessToken");
    sessionStorage.removeItem("setIdTokenClaims");
    instance.loginRedirect(loginRequest);
  };

  const saveActivity = (id, type, title) => {
    let payload = {
      candidateId:
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ],
      itemId: id,
      type: type,
      value: title,
    };
    AssessmentActions.CreateActivity(payload).then(
      (res) => {
        if (res.status === 200) {
          AssessmentActions.GetCreatedActivity().then(
            (res) => {},
            (err) => {
              console.log(err);
            }
          );
        } else {
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const fetchLabel = (key) => {
    let refCmsContent = JSON.parse(JSON.stringify(cmsContent));
    let label = "";
    if (refCmsContent?.labels.length > 0) {
      refCmsContent.labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  function isMobileDevice() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    if (check) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  if (screenLoader) {
    return (
      <div>
        <Spinner screenLoader={true} spinnerTimer={screenLoader} />
      </div>
    );
  }

  return (
    <>
      {forgotPasswordFlag === true ? (
        <>
          <Cirrus.Page
            background="primary"
            backgroundShape="double"
            header={<Header mode="default" className="background-none" />}
            imageSource={cmsContent?.image?.ImageUrl}
            className="landing_page"
          >
            {inactiveAccountMsg && (
              <div className="inactiveAccountMsg">
                <Cirrus.Alert
                  ariaRole="status"
                  bodyText={fetchLabel(
                    "WelcomePage.OnboardingRegisterWelcomePanel.UserInActiveException"
                  )}
                  closeText="Close error"
                  onClose={() => {
                    setInactiveAccountMsg(false);
                    sessionStorage.removeItem("setProgramDetails");
                  }}
                  state="error"
                />
              </div>
            )}
            {aquireTokenErrorMsg && (
              <div className="inactiveAccountMsg">
                <Cirrus.Alert
                  ariaRole="status"
                  bodyText={fetchLabel(
                    "WelcomePage.OnboardingRegisterWelcomePanel.UserSuccessExceptionMessage"
                  )}
                  closeText="Close error"
                  onClose={() => {
                    setAquireTokenErrorMsg(false);
                    sessionStorage.removeItem("acquireTokenRedirectFlag");
                  }}
                  state="error"
                />
              </div>
            )}
            <Cirrus.WelcomePanel
              buttonAction={navigateTo}
              buttonText={fetchLabel(
                "WelcomePage.OnboardingRegisterWelcomePanel.GetStarted"
              )}
              copy={fetchLabel(
                "WelcomePage.OnboardingRegisterWelcomePanel.HaveAnAccount"
              )}
              copyLink={{
                children: fetchLabel(
                  "WelcomePage.OnboardingRegisterWelcomePanel.SignIn"
                ),
                onClick: redirectingADB2C,
              }}
              title={cmsContent?.description}
            />
          </Cirrus.Page>
          <Footer isLoggedIn={false} />
        </>
      ) : (
        <div>
          {navigateToForgotPassword()}
          <Spinner screenLoader={true} spinnerTimer={true} />
        </div>
      )}
    </>
  );
}

export default Landing;
