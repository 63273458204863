//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../common/context/Context";
import * as Cirrus from "manpowergroup-cirrus";
import workImage from "manpowergroup-cirrus/assets/images/peopleSitting.png";
import { saveAs } from "file-saver";
import { DashboardActions } from './dashboardActions';
import Spinner from '../../common/components/spinner-loader/Spinner';
import MultipleFilesUpload from '../../common/components/uploadFile/MultipleFilesUpload';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { ActivityAction } from "../../common/utility/ActivityActions";

function MyResume({ myResumeCMS, documentsLists, setDocumentsLists, getAllDocumentsLists, resumeBuilderData, resumeBuilderToolUrl, isResumeUploaded, saveResumeBuilderData }) {

    const context = useContext(Context);
    const navigate = useNavigate();
    const [uploadModal, setUploadModal] = useState(false);
    const [viewModal, setViewModal] = useState(false);
    const [documentName, setDocumentName] = useState();
    const [screenLoader, setScreenLoader] = useState(false);
    const [uploadModalFlag, setUploadModalFlag] = useState({
        apiFlag: "ResumeUpload",
        replaceID: "",
        uploadType: "Single",
        isResumeBuilder: true
    });
    const [noRecordsMsg, setNoRecordsMsg] = useState("");
    const [cmsMyDocuments, setCmsMyDocuments] = useState({
        title: '',
        pageLabel: [],
        sortOptions: [],
    })
    const [cmsUploadDocuments, setCmsUploadDocuments] = useState({})
    const [embedFile, setEmbedFile] = useState();
    const [embedFileType, setEmbedFileType] = useState();
    const [isPopUpVisible, setIsPopUpVisible] = useState(false);
    const [reference, setReference] = useState(null);
    const [viewModalDocID, setViewModalDocID] = useState();
    const [uploadedResumeData, setUploadedResumeData] = useState("");
    const [isGoToResumeClick, setIsGoToResumeClick] = useState(false);
    const appInsights = useAppInsightsContext();


    useEffect(() => {
        GetUploadedResumeDetails();
    }, [context.language])

    const fetchCMSContent = async () => {
        let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
        let title_label = myResumeCMS?.pageContent?.Labels.filter((x) => x.Key == "DashboardPage.DashboardMyResume.UploadMyResume")[0];
        setScreenLoader(true)
        let payload = {
            PageName: "MyDocuments"
        }
        await DashboardActions.GetCMScontent(payload).then(res => {
            if (res.status === 200) {
                res?.data?.Pages?.map((x) => {
                    let sortArray = [];
                    refCmsMyDocuments.title = x.Title;
                    x.Components?.['MyDocumentsPage.MyDocuments']?.LOVList[0]?.Values?.map((sort) => {
                        let obj = {
                            name: sort.Value,
                            value: sort.Value
                        }
                        sortArray.push(obj);
                    })
                    refCmsMyDocuments.sortOptions = sortArray;
                    refCmsMyDocuments.pageLabel = x.Components?.['MyDocumentsPage.MyDocuments']?.Labels;

                    refCmsUploadDocuments["modalTitle"] = title_label?.Phrase;
                    refCmsUploadDocuments["uploadDescription"] = myResumeCMS?.richText[0]?.Value;
                    refCmsUploadDocuments["dragDropText"] = x.Components?.['MyDocumentsPage.UploadDocuments']?.RichTexts;
                    refCmsUploadDocuments["uploadProgressDescription"] = x.Components?.['MyDocumentsPage.UploadProgressView']?.Description;
                    let labels = [
                        ...x.Components?.['MyDocumentsPage.UploadDocuments']?.Labels,
                        ...x.Components?.['Common.UploadProgressView']?.Labels
                    ]
                    refCmsUploadDocuments["labels"] = labels;
                })
                setCmsMyDocuments(refCmsMyDocuments);
                setCmsUploadDocuments(refCmsUploadDocuments);
                // getAllDocumentsLists();
                setScreenLoader(false);
            } else {
                setScreenLoader(false)
            }
        }, err => {
            console.log(err);
            setScreenLoader(false)
        });
    }

    const fetchLabel = (key) => {
        let refCmsMyDocuments = JSON.parse(JSON.stringify(cmsMyDocuments));
        let label = '';
        if (refCmsMyDocuments?.pageLabel) {
            refCmsMyDocuments?.pageLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchExitLabel = (key) => {
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
        let label = '';
        if (refCmsUploadDocuments?.labels) {
            refCmsUploadDocuments?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }


    const openUploadModal = async () => {
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
        if (Object.keys(refCmsUploadDocuments).length == 0) {
            await fetchCMSContent()
        }
        setUploadModalFlag({
            apiFlag: "ResumeUpload",
            replaceID: "",
            uploadType: "Single",
            isResumeBuilder: true
        });
        setUploadModal(true)
    }

    const uploadModalCloseHandler = () => {
        setUploadModal(false)
    }

    const viewModalCloseHandler = () => {
        setViewModal(false)
        setEmbedFile('');
        setEmbedFileType('');
    }

    const deleteHandler = async (id) => {
        let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
        await DashboardActions.DeleteDocument(id).then(res => {
            if (res.status === 200) {
                let filteredItem = refDocumentsLists.filter((fw) => {
                    return fw.id !== id
                })
                setViewModal(false)
                setDocumentsLists(filteredItem);
                if (refDocumentsLists.length === 1) {
                    setNoRecordsMsg(fetchLabel("MyDocumentsPage.MyDocuments.NoDocumentsMessage"));

                } else {
                    setNoRecordsMsg('');
                }
            }
        }, err => {
            console.log(err);
        });
    }

    const downloadHandler = async (id) => {
        let fileName = documentsLists.filter((x) => {
            if (x.id === id) {
                return x.name;
            }
        })
        await DashboardActions.DownloadDocument(id).then(response => {
            if (response && response.status === 200 && response.data) {
                let contentTypeHeader = response.headers["content-type"];
                var blob = new Blob([response.data], {
                    type: contentTypeHeader.toLowerCase(),
                    charset: "UTF-8"
                });
                saveAs(blob, fileName[0].name);
            }
        }, err => {
            console.log(err);
        });
    }

    const replaceHandler = async (id) => {
        setUploadModal(true)
        setViewModal(false)
        setUploadModalFlag({
            apiFlag: "ResumeUpload",
            replaceID: id,
            uploadType: "Single",
            isResumeBuilder: true
        });
    }

    const viewHandler = async (id) => {
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
        if (Object.keys(refCmsUploadDocuments).length == 0) {
            await fetchCMSContent()
        }
        setViewModal(true)
        setViewModalDocID(id);
        documentsLists.map((document) => {
            if (id === document.id) {
                setDocumentName(document.documentName)
            }
        })

        await DashboardActions.DownloadDocument(id).then(response => {
            if (response && response.status === 200 && response.data) {
                let contentTypeHeader = response.headers["content-type"];
                var blob = new Blob([response.data], {
                    type: contentTypeHeader.toLowerCase(),
                    charset: "UTF-8"
                });
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = function () {
                    let base64data = reader.result + "#toolbar=0&page=1&view=FitH&zoom=100";
                    setEmbedFile("data:application/pdf;base64," + base64data.replace('data:', '').replace(/^.+,/, ''));
                    setEmbedFileType("application/pdf")
                }
            }
        }, err => {
            console.log(err);
        });
    }

    const finishUploadHandler = (uploadedFiles) => {
        getAllDocumentsLists(true);
        setUploadModal(false);
    }


    const sortHandler = (event) => {
        let refDocumentsLists = JSON.parse(JSON.stringify(documentsLists));
        if (event.target.value === "A-Z") {
            refDocumentsLists.sort(function (a, b) {
                if (a.name.toUpperCase() < b.name.toUpperCase()) { return -1; }
                if (a.name.toUpperCase() > b.name.toUpperCase()) { return 1; }
                return 0;
            })
            setDocumentsLists(refDocumentsLists);
        }
        if (event.target.value === "Type") {
            refDocumentsLists.sort(function (a, b) {
                if (a.type.toUpperCase() < b.type.toUpperCase()) { return -1; }
                if (a.type.toUpperCase() > b.type.toUpperCase()) { return 1; }
                return 0;
            })
            setDocumentsLists(refDocumentsLists);
        }
        if (event.target.value === "Newest") {
            setDocumentsLists(sortByNewest(refDocumentsLists));
        }
    }

    const sortByNewest = (data) => {
        data.sort(function (a, b) {
            return new Date(b.createdTimeStamp) - new Date(a.createdTimeStamp);
        });
        return data;
    }

    const convertSize = (bytes) => {
        const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return !bytes && '0 Bytes' || (bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i];
    }

    if (screenLoader) {
        return (<div>
            <Spinner screenLoader={true} spinnerTimer={screenLoader} />
        </div>)
    }

    const fetchResumelabels = (key) => {

        let refCmsContent = myResumeCMS && myResumeCMS;
        let label = "";
        if (refCmsContent?.labels) {
            refCmsContent?.labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase;
                }
            });
        }
        return label;
    }

    const fetchSecondaryButtonProps = () => {
        return (
            {
                children: fetchResumelabels("DashboardPage.DashboardMyResume.UploadMyResume"),
                isSecondary: true,
                className: "goto-resume-btn",
                onClick: () => { openUploadModal() }
            }
        )
    }

    // Get uploaded resume details
    const GetUploadedResumeDetails = async () => {
        await DashboardActions.GetUploadResumeStatus().then(
            (res) => {
                if (res.status === 200) {
                    const resData = res.data;
                    setUploadedResumeData(resData);
                }
            },
            (err) => {
                console.log(err);
            }
        );
    };

    const goToResumeBuilder = () => {
        if (!resumeBuilderToolUrl && resumeBuilderToolUrl?.isVisited) {
            saveResumeBuilderData();
        }
        ActivityAction.SaveActivity("null", "tools", "Resume & CV Builder")
        window.open(resumeBuilderToolUrl, '_blank');
    }

    return (
        <>
            <div className="p-1 w-100 rounded-right myresume_dashboard"
            >
                {resumeBuilderData?.length > 0 &&
                    <Cirrus.FeaturedCard
                        bodyText={myResumeCMS?.description}
                        heading={myResumeCMS?.title}
                        imageProps={{
                            src: workImage
                        }}
                        isWide
                        primaryButtonProps={{
                            children: fetchResumelabels("DashboardPage.DashboardMyResume.GoToResumeBuilder"),
                            // href: resumeBuilderToolUrl?.url,
                            // target: '_blank',
                            // onClick: () => { goToResumeBuilder(); },
                            onClick: () => {goToResumeBuilder();},
                            className: "goto-resume-btn"
                        }}
                        secondaryButtonProps={!context.resPresent && fetchSecondaryButtonProps()}
                    />
                }
                {uploadedResumeData && resumeBuilderData?.length == 0 &&
                    <Cirrus.FeaturedCard
                        bodyText={myResumeCMS?.description}
                        heading={myResumeCMS?.title}
                        imageProps={{
                            src: workImage
                        }}
                        isWide
                        primaryButtonProps={{
                            children: fetchResumelabels("DashboardPage.DashboardMyResume.GoToResume"),
                            onClick: () => navigate("/my-documents", { state: { status: true } }),
                        }}
                        secondaryButtonProps={!context.resPresent && fetchSecondaryButtonProps()}
                    />
                }

                {!uploadedResumeData && resumeBuilderData?.length == 0 &&
                    <Cirrus.FeaturedCard
                        bodyText={myResumeCMS && myResumeCMS?.richText && myResumeCMS?.richText[0]?.Value}
                        heading={myResumeCMS?.title}
                        imageProps={{
                            src: workImage
                        }}
                        isWide
                        secondaryButtonProps={!context.resPresent && fetchSecondaryButtonProps()}
                    />
                }
                {
                    uploadModal && <Cirrus.Modal
                        closeButtonProps={{
                            children: fetchExitLabel("MyDocumentsPage.UploadDocuments.DocumentUploadExit"),
                            isSecondary: true,
                            onClick: uploadModalCloseHandler,
                            size: 'Small',
                            type: 'button',
                            disabled: context.uploadModalExitDisable
                        }}
                        headerProps={{
                            children: cmsUploadDocuments.modalTitle,
                            className: 'no-margin',
                            size: 'h5'
                        }}
                        isScrollable
                        role="dialog"
                        size="default"
                    >
                        <MultipleFilesUpload cmsContent={cmsUploadDocuments} finishUploadHandler={finishUploadHandler} uploadModalFlag={uploadModalFlag}
                            extension={"Common.UploadProgressView"}
                            origin={"Resume"}
                        />
                    </Cirrus.Modal>
                }
                {viewModal &&
                    <Cirrus.ViewDocumentModal
                        closeButtonProps={{
                            children: fetchExitLabel("MyDocumentsPage.UploadDocuments.DocumentUploadExit"),
                            onClick: viewModalCloseHandler,
                            size: 'Small',
                            type: 'button'
                        }}
                        headerProps={{
                            children: documentName,
                            className: 'no-margin',
                            size: 'h5'
                        }}
                        role="dialog"
                        size="default"
                        isScrollable
                    >
                        <Cirrus.IconButton
                            icon='more_vert'
                            type='button'
                            className='moreBtn'
                            isSecondary='true'
                            onClick={() => setIsPopUpVisible(!isPopUpVisible)}
                            ref={setReference}
                        />
                        {isPopUpVisible &&
                            <Cirrus.PopUp referenceElement={reference} onClose={() => { setIsPopUpVisible(false); }} className='viewPopup'>
                                <Cirrus.LinkButton children={fetchLabel("MyDocumentsPage.MyDocuments.DownloadDocument")} type='button' onClick={() => downloadHandler(viewModalDocID)} className="viewLinks" />
                                <Cirrus.LinkButton children={fetchLabel("MyDocumentsPage.MyDocuments.ReplaceDocument")} type='button' onClick={() => replaceHandler(viewModalDocID)} className="viewLinks" />
                                <Cirrus.LinkButton children={fetchLabel("MyDocumentsPage.MyDocuments.DeleteDocument")} type='button' onClick={() => deleteHandler(viewModalDocID)} className="viewLinks" />
                            </Cirrus.PopUp>
                        }
                        <embed src={embedFile} type={embedFileType} width="100%" style={{ minHeight: '400px', maxHeight: '100%' }} />
                    </Cirrus.ViewDocumentModal>
                }
            </div>
        </>
    );
}
export default MyResume;
